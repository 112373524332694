import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { Colors } from "../theme";

export const TitleText = styled(Typography)(({ theme }) => ({
  padding: "40px 0px 40px 0px",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: "3.0rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
}));

export const DescText = styled(Typography)(({ theme }) => ({
  padding: "0px 0px",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.15rem",
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    fontSize: "1rem",
    padding: "0px 0px",
  },
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const RouteButton = styled(Button)(({ theme }) => ({
  margin: "40px 20px",
  color: Colors.white,
  background: Colors.secondary,
  padding: "16px 60px",
  borderRadius: "50px",
  [theme.breakpoints.down("md")]: {
    padding: "12px 50px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 40px",
    fontSize: "0.9rem",
  },
  ":hover": {
    background: Colors.tertiary,
  },
}));
