import ReactDOM from "react-dom";
import React, { useState } from "react";
import { PageContainer } from "../../styles/PageContainer";
import {
  BodyText,
  CategoryButton,
  CategoryButtonContainer,
  CategoryFiltersContainer,
  FilterContainer,
  ProductOuterContainer,
  ProductImageContainer,
  ProductPriceContainer,
  ProductTextContainer,
  SearchContainer,
  SortFilterLabel,
  SortFilterMenuItem,
  SortFilterSelect,
  TitleText,
  ProductInnerContainer,
  ProductVehicleContainer,
  BottomPaginateContainer,
  PaginateButton,
  CategoryFilterText,
  PaginateTextField,
  ProductCategoryText,
  ProductNameText,
  SearchIconButton,
  DesktopSortFilterContainer,
  DesktopPaginateContainer,
  MobileFilterContainer,
  MobilePaginateContainer,
  MobileSortFilterContainer,
} from "../../styles/ProductsPage";
import { allProducts } from "../../data/product";
import { allCategories, allSubCategories } from "../../data/productCategory";
import { Link, useSearchParams } from "react-router-dom";
import {
  FormControl,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Pagination,
  SvgIcon,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { Colors } from "../../styles/theme";
import ErrorIcon from "@mui/icons-material/Error";

function Products() {
  let [searchParams, setSearchParams] = useSearchParams();
  const catIds = searchParams.get("cat_id");
  const q = searchParams.get("q");
  const sort = searchParams.get("sort");
  const page =
    searchParams.get("page") === null ? 1 : parseInt(searchParams.get("page"));

  var query = "";
  if (q !== null) {
    query = q.toLowerCase().split("+");
  }

  const [inputQueryText, setInputQueryText] = useState(query);
  const [filterVisible, toggleFilterVisibility] = useState(false);

  const inputHandler = (e) => {
    setInputQueryText(e.target.value);
  };

  const keyDownHandler = (e) => {
    if (e.key === "Enter") {
      modifyQueryParam();
    }
  };

  const filterToggled = (e) => {
    toggleFilterVisibility(!filterVisible);
  };

  const maxVehiclesPerProduct = 3;
  const productsPerPage = 24;

  function stringifyVehicle(vehicle) {
    let result = Object.values(vehicle).join(" ").toLowerCase();
    if (vehicle.startYear !== vehicle.endYear) {
      for (let i = vehicle.startYear + 1; i <= vehicle.endYear; i++) {
        result += " " + i;
      }
    }
    return result;
  }

  var selectedCategories = [];
  var filteredProducts = allProducts;
  //Category filter
  if (catIds !== null) {
    selectedCategories = catIds.split(" ").map((value) => parseInt(value));
    filteredProducts = filteredProducts.filter((product) =>
      selectedCategories.includes(product.category)
    );
  }
  if (query !== "") {
    filteredProducts = filteredProducts.filter((product) =>
      product.vehicles.some((vehicle) =>
        query.every(
          (searchTerm) =>
            stringifyVehicle(vehicle).includes(searchTerm) ||
            product.sku.includes(searchTerm)
        )
      )
    );
  }

  var sortBy = sort !== null ? sort : "newest";
  switch (sortBy) {
    case "newest":
      filteredProducts.sort((a, b) => b.id - a.id);
      break;
    case "plowest":
      filteredProducts.sort((a, b) => a.price - b.price);
      break;
    case "phighest":
      filteredProducts.sort((a, b) => b.price - a.price);
      break;
    case "atoz":
      filteredProducts.sort((a, b) => {
        if (a.category > b.category) {
          return 1;
        } else if (a.category > b.category) {
          return -1;
        }

        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });
      break;
    default:
  }

  function modifyCategoryParam(id) {
    const newSearchParams = new URLSearchParams(searchParams);
    if (selectedCategories.includes(id)) {
      selectedCategories = selectedCategories.filter(
        (element) => element !== id
      );
    } else {
      selectedCategories.push(id);
    }
    newSearchParams.delete("page");
    newSearchParams.delete("cat_id");
    if (selectedCategories.length > 0) {
      newSearchParams.append(
        "cat_id",
        Object.values(selectedCategories).join(" ")
      );
    }
    setSearchParams(newSearchParams);
  }

  function modifyQueryParam() {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("page");
    newSearchParams.delete("q");
    if (inputQueryText !== "") {
      newSearchParams.append("q", inputQueryText);
    }
    setSearchParams(newSearchParams);
  }

  function clearQueryParam() {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("page");
    newSearchParams.delete("q");
    ReactDOM.unstable_batchedUpdates(() => {
      setInputQueryText("");
      setSearchParams(newSearchParams);
    });
  }

  function modifySortParam(newSort) {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("page");
    newSearchParams.delete("sort");
    newSearchParams.append("sort", newSort);
    setSearchParams(newSearchParams);
  }

  function modifyPageParam(newPage) {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("page");
    if (newPage > 1) {
      newSearchParams.append("page", newPage);
    }
    setSearchParams(newSearchParams);
  }

  function renderCategoryFilters() {
    const count = Math.ceil(allCategories.length / 2);
    const splicedCategories = allCategories.slice(0, count);
    for (let i = count; i < allCategories.length; i++) {
      splicedCategories.splice(
        i+1 - (allCategories.length - i),
        0,
        allCategories[i]
      );
    }

    return (
      <CategoryFiltersContainer>
        <FormGroup>
          <Grid
            container
            rowSpacing={{ xs: 1, md: 0 }}
            justifyContent={"start"}
            columns={8}
          >
            {splicedCategories.map((category) => (
              <Grid
                item
                key={category.id}
                xs={4}
                display="flex"
                flexDirection={"row"}
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedCategories.includes(category.id)}
                      onChange={() => modifyCategoryParam(category.id)}
                      name={category.name}
                    />
                  }
                  label={
                    <CategoryFilterText variant="body2">
                      {category.name}
                    </CategoryFilterText>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </CategoryFiltersContainer>
    );
  }

  function categoryFilterString() {
    return selectedCategories.length === 0 ||
      selectedCategories.length === allCategories.length
      ? "Selected Categories: All"
      : selectedCategories.length === 1
      ? `Selected Category: ${getCategoryName(selectedCategories[0])}`
      : `Selected Categories: ${getCategoryName(selectedCategories[0])} & ${
          selectedCategories.length - 1
        } others`;
  }

  const renderSortFilter = (
    <FormControl fullWidth>
      <SortFilterLabel id="demo-simple-select-label">Sort By</SortFilterLabel>
      <SortFilterSelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={sortBy}
        label="Sort By"
        onChange={(event) => modifySortParam(event.target.value)}
      >
        <SortFilterMenuItem value="newest">Newest</SortFilterMenuItem>
        <SortFilterMenuItem value="plowest">Price Lowest</SortFilterMenuItem>
        <SortFilterMenuItem value="phighest">Price Highest</SortFilterMenuItem>
        <SortFilterMenuItem value="atoz">A to Z</SortFilterMenuItem>
      </SortFilterSelect>
    </FormControl>
  );

  const onPageChange = (event, value) => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    modifyPageParam(value);
  };

  function getCategoryName(id) {
    return allCategories.find((cat) => cat.id.toString() === id.toString())
      .name;
  }

  function renderVehicleList(vehicles, id) {
    const vehicleSet = new Set();

    if (vehicles == null || vehicles.length === 0) {
      vehicleSet.add("Universal");
    } else {
      let i = 0;
      while (
        i < vehicles.length &&
        vehicleSet.size < maxVehiclesPerProduct + 1
      ) {
        vehicleSet.add(vehicles[i].make + " " + vehicles[i].model);
        i++;
      }
    }

    var moreVehicles = false;
    const vehicleArray = Array.from(vehicleSet);
    if (vehicleSet.size > maxVehiclesPerProduct) {
      moreVehicles = true;
      vehicleArray.pop();
    }

    return (
      <ProductVehicleContainer>
        <List style={{ padding: "0px" }}>
          {vehicleArray.map((vehicle) => (
            <ListItem key={id + vehicle} style={{ padding: "0px 16px" }}>
              <ListItemIcon style={{ paddingRight: "16px", minWidth: "0px" }}>
                <DirectionsCarIcon />
              </ListItemIcon>
              <ListItemText>
                <BodyText variant="body2" color={Colors.black}>
                  {vehicle}
                </BodyText>
              </ListItemText>
            </ListItem>
          ))}
        </List>

        {moreVehicles ? (
          <BodyText
            variant="subtitle2"
            textAlign="center"
            paddingTop="8px"
            color={Colors.grey}
          >
            and more...
          </BodyText>
        ) : null}
      </ProductVehicleContainer>
    );
  }

  function renderProductGrid() {
    return filteredProducts.length > 0 ? (
      <Grid
        container
        rowSpacing={{ xs: 6, sm: 6, md: 8, lg: 8 }}
        columnSpacing={{ sm: 10, md: 16, lg: 10 }}
        justifyContent={"center"}
        columns={{ xs: 4, sm: 8, lg: 12 }}
      >
        {renderProducts}
      </Grid>
    ) : (
      <BodyText>No products found.</BodyText>
    );
  }

  const renderProducts = filteredProducts
    .slice((page - 1) * productsPerPage, page * productsPerPage)
    .map((product) => (
      <Grid
        item
        key={product.id}
        xs={4}
        display="flex"
        flexDirection={"column"}
        alignItems="center"
      >
        <ProductOuterContainer>
          <Link
            to={"/products/item?item_id=" + product.id}
            style={{ textDecoration: "none", width: "100%", height: "100%" }}
          >
            <ProductInnerContainer>
              <ProductImageContainer>
                {getProductImage(product)}
              </ProductImageContainer>
              <ProductTextContainer>
                <ProductCategoryText variant="body2" color={Colors.grey}>
                  {getCategoryName(product.category)}
                </ProductCategoryText>
                <ProductNameText variant="h6" color={Colors.black}>
                  {product.name}
                </ProductNameText>
                {renderVehicleList(product.vehicles, product.id)}
              </ProductTextContainer>
              <ProductPriceContainer>
                <BodyText variant="body1" color={Colors.black}>
                  {"RM " + product.price}
                </BodyText>
              </ProductPriceContainer>
            </ProductInnerContainer>
          </Link>
        </ProductOuterContainer>
      </Grid>
    ));

  function getProductImage(product) {
    var imagePath = "";
    if (product.imageCount > 0) {
      imagePath = `images/products/items/${product.id}`;
    } else if (product.subCategory !== 0) {
      const subCategory = allSubCategories.find(
        (cat) => cat.id.toString() === product.subCategory.toString()
      );
      if (subCategory) {
        imagePath = `images/products/categories/${subCategory.imageName}`;
      }
    } else {
      const category = allCategories.find(
        (cat) => cat.id.toString() === product.category.toString()
      );
      if (category && category.imageCount > 0) {
        imagePath = `images/products/categories/${category.imageName}`;
      }
    }

    return imagePath !== "" ? (
      <img
        alt={product.name}
        src={`${imagePath}-1-md.jpg`}
        srcSet={`
      ${imagePath}-1-sm.jpg 250w,
      ${imagePath}-1-md.jpg 500w,
      ${imagePath}-1-lg.jpg 1000w,
      `}
        sizes="140px"
        height="100%"
      />
    ) : (
      <ErrorIcon sx={{ fontSize: 60, color: Colors.black }} />
    );
  }

  return (
    <PageContainer disableGutters maxWidth={false}>
      <TitleText variant="h3">Products</TitleText>
      <SearchContainer>
        <TextField
          variant="outlined"
          fullWidth
          label="Search by part number or vehicle"
          value={inputQueryText}
          onChange={inputHandler}
          onKeyDown={keyDownHandler}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => clearQueryParam()}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
            sx: { fontSize: { xs: "0.85rem", sm: "1.1rem" }, paddingRight: "8px" },
          }}
          InputLabelProps={{
            sx: { fontSize: { xs: "0.85rem", sm: "1.1rem" } },
          }}
        />
        <SearchIconButton onClick={() => modifyQueryParam()}>
          <SearchIcon />
        </SearchIconButton>

        <DesktopSortFilterContainer>
          {renderSortFilter}
        </DesktopSortFilterContainer>
      </SearchContainer>

      <MobileFilterContainer>
        <MobilePaginateContainer>
          {" "}
          <PaginateTextField
            variant="outlined"
            label="Page"
            value={page}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PaginateButton
                    disabled={page === 1}
                    onClick={() => modifyPageParam(page - 1)}
                  >
                    <SvgIcon>
                      <NavigateBeforeIcon />
                    </SvgIcon>
                  </PaginateButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <PaginateButton
                    disabled={
                      page ===
                      Math.ceil(filteredProducts.length / productsPerPage)
                    }
                    onClick={() => modifyPageParam(page + 1)}
                  >
                    <SvgIcon>
                      <NavigateNextIcon />
                    </SvgIcon>
                  </PaginateButton>
                </InputAdornment>
              ),
              sx: { fontSize: { xs: "0.85rem", sm: "1.1rem" } },
              disabled: true,
            }}
            InputLabelProps={{
              sx: { fontSize: { xs: "0.85rem", sm: "1.1rem" } },
            }}
          />
        </MobilePaginateContainer>
        <MobileSortFilterContainer>{renderSortFilter}</MobileSortFilterContainer>
      </MobileFilterContainer>

      <FilterContainer>
        <CategoryButtonContainer>
          <CategoryButton variant="outlined" onClick={() => filterToggled()}>
            <CategoryFilterText variant="body2">
              {categoryFilterString()}
            </CategoryFilterText>
          </CategoryButton>
          {filterVisible && renderCategoryFilters()}
        </CategoryButtonContainer>

        <DesktopPaginateContainer>
          <PaginateTextField
            variant="outlined"
            label="Page"
            value={page}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PaginateButton
                    disabled={page === 1}
                    onClick={() => modifyPageParam(page - 1)}
                  >
                    <SvgIcon>
                      <NavigateBeforeIcon />
                    </SvgIcon>
                  </PaginateButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <PaginateButton
                    disabled={
                      page ===
                      Math.ceil(filteredProducts.length / productsPerPage)
                    }
                    onClick={() => modifyPageParam(page + 1)}
                  >
                    <SvgIcon>
                      <NavigateNextIcon />
                    </SvgIcon>
                  </PaginateButton>
                </InputAdornment>
              ),
              sx: { fontSize: { xs: "0.85rem", sm: "1.1rem" } },
              disabled: true,
            }}
            InputLabelProps={{
              sx: { fontSize: { xs: "0.85rem", sm: "1.1rem" } },
            }}
          />
        </DesktopPaginateContainer>
      </FilterContainer>

      {renderProductGrid()}

      <BottomPaginateContainer>
        <Pagination
          count={Math.ceil(filteredProducts.length / productsPerPage)}
          page={page}
          shape="rounded"
          showFirstButton
          showLastButton
          boundaryCount={0}
          onChange={onPageChange}
        />
        <BodyText variant="subtitle1" paddingY="20px">
          {`Showing ${Math.min(
            (page - 1) * productsPerPage + 1,
            filteredProducts.length
          )}-${Math.min(page * productsPerPage, filteredProducts.length)} of ${
            filteredProducts.length
          } items`}
        </BodyText>
      </BottomPaginateContainer>
    </PageContainer>
  );
}

export default Products;
