import React from "react";
import { PageContainer } from "../../styles/PageContainer";
import {
  ContentContainer,
  PointsContainer,
  PointsIconContainer,
  ContentText,
  TitleText,
} from "../../styles/PrivacyPage";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { disclaimers } from "../../data/site";

function Disclaimers() {
  const renderContent = disclaimers.map((disclaimer) => (
    <ContentContainer>
      <ContentText variant="body2" fontWeight={600}>
        {disclaimer.title}
      </ContentText>
      <ContentText variant="body2">{disclaimer.desc}</ContentText>
      {disclaimer.points?.map((point) => (
        <PointsContainer>
          <PointsIconContainer>
            <DoubleArrowIcon color="secondary" />
          </PointsIconContainer>
          <ContentText variant="body2">{point}</ContentText>
        </PointsContainer>
      ))}
    </ContentContainer>
  ));

  return (
    <PageContainer disableGutters maxWidth={false}>
      <TitleText variant="h1">Disclaimers</TitleText>
      {renderContent}
    </PageContainer>
  );
}

export default Disclaimers;
