import { Box, Typography, IconButton, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../theme";

export const TitleText = styled(Typography)(({ theme }) => ({
  paddingBottom: "20px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    fontSize: "2.75rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
    paddingBottom: "10px",
  },
}));

export const ContactOutletText = styled(Typography)(({ theme }) => ({
  width: "100%",
  padding: "10px 10px",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.3rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
  },
}));

//OUTLET (MY/SG)
export const ContactOutletContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "20px 40px",
  flexDirection: "column",
  borderRadius: "8px",
  boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.25)",
  backgroundImage: "linear-gradient(190deg, #f5f5f5, #f0f0f0)",
    [theme.breakpoints.down("md")]: {
    padding: "16px 20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "10px 10px",
  },
}));

export const ContactMapDetailsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

export const MapSizeContainer = styled(Box)(({ theme }) => ({
  padding: "10px 20px",
  width: "480px",
  [theme.breakpoints.down("lg")]: {
    width: "650px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "10px 0px",
    width: "320px",
  },
}));

export const MapRatioContainer = styled(Box)(() => ({
  width: "100%",
  height: "0",
  paddingBottom: "56.25%",
  position: "relative",
}));

export const AllContactDetailsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: "1",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const ContactSectionContainer = styled(Box)(() => ({
  flex: 1,
  padding: "10px 20px",
  alignItems: "start",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
}));

//CONTACT DETAILS
export const ContactDetailsContainer = styled(Box)(({ theme }) => ({
  flex: 10,
  padding: "10px 20px",
  alignItems: "start",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  [theme.breakpoints.down("md")]: {
    flex: 13,
  },
}));

export const ContactDetailContainer = styled(Box)(() => ({
  padding: "16px 0px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flex: "1",
}));

export const DetailTextIconContainer = styled(Box)(() => ({
  display: "flex",
  flex: "1",
}));

export const DetailTextContainer = styled(Box)(() => ({
  display: "flex",
  flex: "2",
  alignItems: "center",
}));

export const DetailIconContainer = styled(Box)(() => ({
  display: "flex",
  flex: "1",
  alignItems: "center",
  justifyContent: "center",
}));

export const DetailIconButton = styled(IconButton)(() => ({
  color: Colors.black,
  ":hover": {
    background: Colors.black_alpha_10,
  },
}));

export const DetailHeaderText = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-wrap",
  fontWeight: 550,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.1rem",
  },
}));

export const DetailValueText = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-wrap",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "4px 0px",
  },
}));

//OPENING HOURS
export const OpeningHoursContainer = styled(Box)(() => ({
  flex: 10,
  padding: "26px 20px",
  alignItems: "start",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
}));

export const DayHoursContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  padding: "1px 0px",
  alignSelf: "center",
}));

export const DayContainer = styled(Box)(({ theme }) => ({
  flex: "3",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const HoursContainer = styled(Box)(({ theme }) => ({
  flex: "7",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const SectionDivider = styled(Divider)(({ theme }) => ({
  width: "60%",
  margin: "80px 20%",
  borderBottomWidth: "3px",
  [theme.breakpoints.down("md")]: {
    margin: "60px 20%",
  },
}));
