import { React, useState } from "react";
import {
  EmailInput,
  FormContainer,
  SendingIndicator,
  SubscribeButton,
  SubscribeContainer,
  SubscribeHeader,
  SuccessAvatar,
  SuccessText,
} from "../../../styles/HomePage/Subscribe";
import CheckIcon from "@mui/icons-material/Check";

export default function Subscribe() {
  return (
    <SubscribeContainer>
      <SubscribeHeader variant="h2">Stay Up-To-Date</SubscribeHeader>
      <EmailForm />
    </SubscribeContainer>
  );
}

const EmailForm = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onSubmitted = async (e) => {
    e.preventDefault();
    if (email === "") {
      setErrorMessage("Email address is empty.");
    } else if (isEmail(email)) {
      setErrorMessage("");
      setStatus("sending");
      let apiURL = `/.netlify/functions/token-hider?email=${email}`;
      try {
        const response = await fetch(apiURL, {
          method: "POST",
          headers: { accept: "application/json" },
        });

        const data = await response.json();
        const statusCode = response.status;
        const statusText = data.resBody;

        if (statusCode === 200) {
          setStatus("success");
        } else {
          setStatus("");
          if (statusText === "Member Exists") {
            setErrorMessage("This email address has already been registered.");
          } else if (statusText === "Invalid Resource") {
            setErrorMessage("Email address seems to be invalid.");
          } else {
            setErrorMessage(statusText);
          }
        }
      } catch (error) {
        setErrorMessage("An unknown error occurred.");
      }
    } else {
      setErrorMessage("Invalid email address.");
    }
  };

  return (
    <FormContainer>
      {status !== "success" && (
        <EmailInput
          variant="outlined"
          label="Email Address"
          helperText={errorMessage}
          error={errorMessage !== ""}
          onChange={onEmailChange}
          value={email}
        />
      )}
      {status !== "success" && status !== "sending" && (
        <SubscribeButton onClick={onSubmitted}>Submit</SubscribeButton>
      )}

      {status === "sending" && <SendingIndicator />}

      {status === "success" && (
        <SuccessText variant="body1">Email submitted!</SuccessText>
      )}

      {status === "success" && (
        <div>
          <SuccessAvatar>
            <CheckIcon />
          </SuccessAvatar>
        </div>
      )}
    </FormContainer>
  );
};
