import React from 'react';
import {
  AppBarPagesContainer,
  AppBarMenuButton,
  AppBarLogo,
  AppBarLogoButton,
  AppBarPageButton,
  AppBarMenuButtonSpacer,
} from "../../styles/Appbar";
import { AppBar, Toolbar, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import AppDrawer from "../AppDrawer";
import { useUIContext } from "../../context/ui";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";

const navItems = ["About", "Products", "Contact"];

function HideOnScroll(props) {
  const { children } = props;

  return (
    <Slide appear={false} direction="down" in={!useScrollTrigger()}>
      {children}
    </Slide>
  );
}

export default function Appbar(props) {
  const { setDrawerOpen } = useUIContext();
  return (
    <Box sx={{ display: "flex" }}>
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar sx={{ justifyContent: "flex-start" }}>
            <AppBarMenuButton onClick={() => setDrawerOpen(true)}>
              <MenuIcon />
            </AppBarMenuButton>

            <Link to="/" style={{ textDecoration: 'none' }}>
              <AppBarLogoButton>
                <AppBarLogo src="/images/SWlogo.png" />
              </AppBarLogoButton>
            </Link>

            <AppBarPagesContainer>
              {navItems.map((item) => (
                <Link to={item.toLowerCase()} key={item.toLowerCase()} style={{ textDecoration: 'none' }}>
                  <AppBarPageButton>{item}</AppBarPageButton>
                </Link>
              ))}
            </AppBarPagesContainer>
            <AppBarMenuButtonSpacer>
              <MenuIcon />
            </AppBarMenuButtonSpacer>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <AppDrawer />
    </Box>
  );
}
