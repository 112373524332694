import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../../theme";

export const BannerSection = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
    padding: "60px 5%",
    background: Colors.light_grey,
    [theme.breakpoints.down("md")]: {
      padding: "50px 5%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "40px 10%",
    },
  }));

  export const BannerImageContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    flex: 2,
    justifyContent: "center", 
  }));

  export const BannerContent = styled(Box)(({ theme }) => ({
    display: "flex",
    flex: 3,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "50px",
    [theme.breakpoints.down('sm')]: {
      padding: "10px 0px 0px 0px",
    }
  }));

  export const BannerTitle = styled(Typography)(({ theme }) => ({
    padding: "10px 0px",
    width: "100%",
    [theme.breakpoints.down('md')]: {
      fontSize: '3.0rem',    
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',    
      textAlign: "center",
    }
  }));
  
  export const BannerDescription = styled(Typography)(({ theme }) => ({
    width: "100%",
    padding: "20px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.15rem",
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
      fontSize: "1rem",
      padding: "10px 0px",
    }
  }));
  
  export const BannerProductsButton = styled(Button) (({ theme}) => ({
    margin: "30px 0px 0px",
    color: Colors.white,
    background: Colors.secondary,
    padding: "16px 32px",
    borderRadius: "50px",
   [theme.breakpoints.down('md')]: {
    padding: "16px 28px",
  },
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0px 0px",
      fontSize: "0.9rem",
      padding: "16px 20px",
    },
    ":hover": {
      background: Colors.tertiary,
    },
  }));