import { Box, Button, TextField, Typography, CircularProgress } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import { Colors } from "../../theme";

export const SubscribeContainer = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  padding: "100px",
  [theme.breakpoints.down("md")]: {
    padding: "80px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "60px",
  },
}));

export const SubscribeHeader = styled(Typography)(({ theme }) => ({
  padding: "0px 40px",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.25rem",
  },
}));

export const FormContainer = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const EmailInput = styled(TextField)(({ theme }) => ({
  textAlign: "center",
  width: "50%",
  margin: "29px 0px 24px 0px",
  [theme.breakpoints.down("lg")]: {
    width: "65%",
  },
  [theme.breakpoints.down("md")]: {
    width: "75%",
    margin: "25px 0px 20px 0px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    margin: "21px 0px 16px 0px",
  },
  "& .MuiInputLabel-root": {
    right: 0,
    textAlign: "center",
    fontSize: "1.25rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  "& .MuiInputLabel-shrink": {
    margin: "0 auto",
    position: "absolute",
    right: "0",
    left: "0",
    top: "-3px",
    width: "150px", // Need to give it a width so the positioning will work
    background: "white", // Add a white background as below we remove the legend that had the background so text is not meshing with the border
    // display: "none" //if you want to hide it completly
  },
  "& .MuiOutlinedInput-root": {
    "& legend ": {
      display: "none",
    },
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "1.25rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  "& .MuiFormHelperText-root": {
    fontSize: "1rem",
    color: Colors.error,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
}));

export const SubscribeButton = styled(Button)(({ theme }) => ({
  color: Colors.white,
  background: Colors.secondary,
  padding: "12px 120px",
  borderRadius: "50px",
  [theme.breakpoints.down("md")]: {
    padding: "12px 100px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
    padding: "12px 70px",
  },
  ":hover": {
    background: Colors.tertiary,
  },
}));

export const SendingIndicator = styled(CircularProgress)(({ theme}) => ({
  color: Colors.secondary,
  padding: "12px 120px",
  [theme.breakpoints.down("md")]: {
    padding: "12px 100px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "12px 70px",
  },
}));

export const SuccessText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  margin: "39px 0px 34px 0px",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.3rem",
    margin: "35px 0px 30px 0px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
    margin: "31px 0px 26px 0px",
  },
}));

export const SuccessAvatar = styled(Avatar)(({ theme}) => ({
  backgroundColor: Colors.success,
}));
