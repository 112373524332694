import { styled } from "@mui/material/styles";
import { Typography, Box, IconButton, ListItemIcon } from "@mui/material";
import { Colors } from "../theme";

export const ProductContainer = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  background: Colors.light_grey,
  display: "flex",
  marginBottom: "80px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "40px",
    flexDirection: "column",
  },
}));

export const CarouselContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  padding: "20px",
  alignSelf: "center",
  [theme.breakpoints.down("md")]: {
    width: "60%",
    padding: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

export const ImageOuterContainer = styled(Box)(() => ({
  justifyContent: "center",
  width: "100%",
}));

export const ImageInnerContainer = styled(Box)(({ theme }) => ({
  padding: "20px 12%",
  [theme.breakpoints.down("md")]: {
    padding: "20px 15%",
  },
}));

export const DetailsContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column", 
  padding: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    padding: "20px",
  },
}));

export const ProductNameDescContainer = styled(Box)(({ theme }) => ({
  paddingTop: "60px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "20px",
  },
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.3rem",
  },
}));

export const SubheaderText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const SubtitleText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "0.85rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
  },
}));

export const BodyText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
}));

export const LinksContainer = styled(Box)(({ theme }) => ({
  padding: "80px 0px 10px 0px",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    padding: "30px 0px 10px 0px",
  },
}));

export const LinkButtonContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    margin: "0px 10px"
    },
}));

export const LinkIconButton = styled(IconButton)(({ theme }) => ({
  color: Colors.black,
  height: "64px",
  width: "64px",
  minWidth: "0px",
  [theme.breakpoints.down("sm")]: {
    height: "48px",
    width: "48px",
    },
}));

export const DescriptionSection = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  margin: "0px 40px 80px 40px",
  [theme.breakpoints.down("md")]: {
    margin: "0px 30px 50px 30px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "0px 0px 40px 0px",
  },
}));

export const DescItemIcon = styled(ListItemIcon)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    minWidth: "40px"
    },
}));