//All site pages
export const sitemapLinks = [
  { name: "Home", route: "/" },
  { name: "About", route: "about" },
  { name: "Products", route: "products" },
  { name: "Contact", route: "contact" },
  { name: "Privacy Policy", route: "privacy" },
  { name: "Terms & Conditions", route: "terms" },
  { name: "Disclaimers", route: "disclaimers" },
];

//Footer - Social Media links
export const socialMedias = [
  {
    id: 1,
    type: "Facebook",
    url: "https://www.facebook.com/speedworksmalaysia/",
    image: "/images/social_media/facebook_icon.png",
  },
  {
    id: 2,
    type: "Instagram",
    url: "https://www.instagram.com/speedworksmy/",
    image: "/images/social_media/instagram_icon.png",
  },
  {
    id: 3,
    type: "TikTok",
    url: "https://www.tiktok.com/@speedworksmys",
    image: "/images/social_media/tiktok_icon.png",
  },
  {
    id: 4,
    type: "YouTube",
    url: "https://www.youtube.com/@SpeedworksMalaysia",
    image: "/images/social_media/youtube_icon.png",
  },
];

export const contactMalaysia = {
  details: [
    {
      type: "Address",
      value: `Lot 2, Lorong 51A/227B,\n46100 Petaling Jaya, Selangor,\nMalaysia`,
      links: [
        { icon: "GoogleMaps", url: "https://goo.gl/maps/XRYWsb7ETDuQztMw5" },
        { icon: "Waze", url: "https://waze.com/ul/hw2838snhs" },
      ],
    },
    {
      type: "Tel",
      value: "+6012-315 6519",
      links: [
        { icon: "Phone", url: "tel:+60123156519" },
        {
          icon: "Whatsapp",
          url: "https://api.whatsapp.com/send?phone=60123156519",
        },
      ],
    },
    {
      type: "Email",
      value: "tsh5@speedworks.com.my",
      links: [{ icon: "Email", url: "mailto:tsh5@speedworks.com.my" }],
    },
  ],
  openingHours: [
    { day: "Mon", hours: "9.00am - 6.00pm" },
    { day: "Tue", hours: "9.00am - 6.00pm" },
    { day: "Wed", hours: "9.00am - 6.00pm" },
    { day: "Thu", hours: "9.00am - 6.00pm" },
    { day: "Fri", hours: "9.00am - 6.00pm" },
    { day: "Sat", hours: "Closed" },
    { day: "Sun", hours: "Closed" },
  ],
};

export const contactSingapore = {
  details: [
    {
      type: "Address",
      value: `85 Genting Lane,\nGuan Hua Warehouse Building\nSingapore 349569`,
      links: [
        { icon: "GoogleMaps", url: "https://goo.gl/maps/qqQQZwn6ZxY9F1Co7" },
        { icon: "Waze", url: "https://waze.com/ul/hw21zec8b1" },
      ],
    },
    {
      type: "Tel",
      value: "+65 6743 7288",
      links: [
        { icon: "Phone", url: "tel:+6567437288" },
        {
          icon: "Whatsapp",
          url: "https://api.whatsapp.com/send?phone=6567437288",
        },
      ],
    },
    {
      type: "Email",
      value: "speedwork_sg@yahoo.com.sg",
      links: [{ icon: "Email", url: "mailto:speedwork_sg@yahoo.com.sg" }],
    },
  ],
  openingHours: [
    { day: "Mon", hours: "10.00am - 5.30pm" },
    { day: "Tue", hours: "10.00am - 5.30pm" },
    { day: "Wed", hours: "10.00am - 5.30pm" },
    { day: "Thu", hours: "10.00am - 5.30pm" },
    { day: "Fri", hours: "10.00am - 5.30pm" },
    { day: "Sat", hours: "10.00am - 5.30pm" },
    { day: "Sun", hours: "Closed" },
  ],
};

//HomePage - FeaturedItems. Last entry will be displayed first.
export const featuredItems = [
  {
    id: 1,
    image: "/images/posts/knn_subaru_wrx_sti_gc8.jpg",
    url: "https://www.facebook.com/speedworksmalaysia/posts/pfbid034oieoBzDB8V33UBJQahfJ2bK6pxxBzBJM8avwwpGjBbNymsabpKEJR8RSbQxGQcFl",
    category: "K&N Open Pod Kit",
    model: "Subaru WRX STI GC8",
  },
  {
    id: 2,
    image: "/images/posts/knn_bmw_e60_520i.jpg",
    url: "https://www.facebook.com/speedworksmalaysia/posts/pfbid08WyTF6NKrdnYcnxGdnVaZDLsf5fuMLEbjpSK2qVzuHrNddpRnYGDaxc7uKXGwXShl",
    category: "K&N Open Pod Kit",
    model: "BMW E60 520i",
  },
  {
    id: 3,
    image: "/images/posts/knn_mazda_cx5_2.5t.jpg",
    url: "https://www.facebook.com/speedworksmalaysia/posts/pfbid0kFzxCu7CKxRzdRBhtEBjtULs5kqoK2Mke8gqDzTVT4MWoAb4sMimRGN12VdoH6eVl",
    category: "K&N Open Pod Kit",
    model: "Mazda CX5 2.5T",
  },
  {
    id: 4,
    image: "/images/posts/knn_mitsubishi_lancer.jpg",
    url: "https://www.facebook.com/speedworksmalaysia/posts/pfbid02uh2re5KEwUSFt2zmTyWBkYTUh3sn6xS7zZ8cqambNRA2BcPNxxx6EbGrt8MvtsBsl",
    category: "K&N Open Pod Kit",
    model: "Mitsubishi Lancer",
  },
  {
    id: 5,
    image: "/images/posts/knn_toyota_corolla_rz_1.2t.jpg",
    url: "https://www.facebook.com/speedworksmalaysia/posts/pfbid04phnBTaWPtGUWoFKxhX8HU93767UfRDiNaTmg2TYHBBbApxZ2V18vfNajUrH5ofyl",
    category: "K&N Open Pod Kit",
    model: "Toyota Corolla RZ 1.2T",
  },
  {
    id: 6,
    image: "/images/posts/knn_toyota_mr2_spyder.jpg",
    url: "https://www.facebook.com/speedworksmalaysia/posts/pfbid02NdHT8ThShQ9nYQBLSbA1BXphgacyd82DExc4Q9g1jWofzUq2zHRS95Fm72gg5jEkl",
    category: "K&N Open Pod Kit",
    model: "Toyota Mr2 Spyder",
  },
];

//HomePage - Videos. Embedded YT videos. Last entry will be displayed first.
export const featuredVideos = [
  {
    id: 1,
    src: "https://www.youtube.com/embed/EWAQeHLBNPE",
    title: "Honda CRZ Speedworks Performance Kit",
  },
  {
    id: 2,
    src: "https://www.youtube.com/embed/fiENm4sgA-0",
    title: "Perodua Myvi Speedworks Turbo Kit",
  },
  {
    id: 3,
    src: "https://www.youtube.com/embed/taNjdsGRlyQ",
    title: "Genuine K&N Air Filter",
  },
  {
    id: 4,
    src: "https://www.youtube.com/embed/qpCImbVyFss",
    title: "Proton Satria Neo CPS",
  },
];

//HomePage - below banner. Showcasing main product offerings.
export const categories = [
  {
    id: 1,
    name: "Speedworks Turbo Kits",
    title:
      "Complete turbo kits to significantly increase BHP & torque by about 50%.",
    description:
      "Dyno Tuned with extensive mapping of fuel and ignition cells. Includes turbocharger, intercooler, ECU, fuel injectors, exhaust system, air filter and blow-off valve.",
    icon: "/images/categories/sw_turbo_icon.png",
    imageLg: "/images/categories/sw_turbo-lg.png",
    imageMd: "/images/categories/sw_turbo-md.png",
    catId: 13,
    aboutDesc: "Precisely tuned comprehensive turbo kits delivering significant boosts in engine power and torque."
  },
  {
    id: 2,
    name: "Hot Bits Sports Suspensions",
    title:
      "Fully serviceable suspension kits with high performance and quality.",
    description:
      "Tuneable suspensions with adjustable rebound and compression dampings to suit your needs and preferences.",
    icon: "/images/categories/hb_suspension_icon.png",
    imageLg: "/images/categories/hb_suspension-lg.png",
    imageMd: "/images/categories/hb_suspension-md.png",
    catId: 12,
    aboutDesc: "Adjustable coil-over suspension kits that are manufactured in-house. Customisable according to your requirements. Shocks are mono-tubes and fully tuneable, while springs are 60mm and interchangeable. Suspension kits have a 6-months warranty and are easily serviceable." 
  },
  {
    id: 3,
    name: "K&N Air Filters",
    title:
      "Engine air filters that are washable, reusable, and engineered to last.",
    description:
      "Premium filter design to improve performance by increasing airflow while protecting your engine from premature wear.",
    icon: "/images/categories/kn_filter_icon.png",
    imageLg: "/images/categories/kn_filter-lg.png",
    imageMd: "/images/categories/kn_filter-md.png",
    catId: 8,
    aboutDesc: "Washable, re-useable air filters from the USA with a Million Mile Warranty that will significantly improve engine power compared to the standard air filters." 
  },
];

//AboutPage
export const aboutContent = {
  intro:
    "Speedworks Sdn Bhd was established in 1985 and has been operating from the same outlet in Section 51A, Petaling Jaya (PJ) ever since. As a car performance specialist, we focus on improving the power, efficiency, and ride comfort for various car brands and models.",
  premise:
    "We offer the full range of services all in one place at our outlet in PJ. A Dynamic Test System, Australia 4 Wheel Drive Friction Brake Chassis Dynamometer, SPA Shock Absorber Dynamometer, Mandrel tube benders, automatic robot welders and 8 service bays are readily available on site. Our premise also has a fully air-conditioned waiting area with free Wi-Fi services. Additionally, car repainting services are available by a separate company at our outlet. Our outlet in PJ can be accessed via the Jalan 222 exit along the Federal Highway.",
  product: "Our main products are the following:",
  performance:
    "Additionally, we offer performance kits for not only more popular but also high potential cars that we have developed and tested extensively. Our complete performance kits consist of engine improvement, suspension, and brake parts.",
  performanceList: [
    {
      title: "POWER",
      content:
        "Significant Bhp engine power and torque improvement (usually more than 20% increase), with smooth progressive increase throughout the RPM range.",
    },
    {
      title: "FUEL",
      content:
        "Practically no increase in fuel consumption and on most occasions result in fuel consumption improvements.",
    },
    {
      title: "PARTS",
      content:
        "Bolt-on parts and systems with reliability that surpasses the original equipment qualities. Parts are usually  re-useable / serviceable / re-buildable and carry life time warranty.",
    },
    {
      title: "VALUE",
      content:
        "Kits are reasonably priced and value for money.",
    },
  ],
  closing:
    "Our principle is to only offer parts that have significant and satisfying improvements. All engine bolt-on parts are dyno tested and will always produce power improvements. It should also be noted that the performance improvements will be synergistic and more significant when all parts of the complete kit are fitted together. With years of knowledge and experience, we aim to provide excellent quality products and services to meet all your requirements.",
};

//Privacy Policy
export const privacyPolicy = {
  opening:
    "At Speedworks, we take your privacy seriously. This Privacy Policy outlines the types of personal information that we collect, how we use and protect it, and your rights regarding your personal information.",
  content: [
    {
      title: "1. Information we collect",
      desc: "We may collect personal information from you when you use our website, make a purchase, subscribe to our newsletter, participate in a survey or promotion, or interact with us in any other way. The personal information we may collect includes:",
      points: [
        "Your email address",
        "Your IP address",
        "Your browser type and version",
        "Your operating system",
        "Your usage data",
      ],
    },
    {
      title: "2. How we use your information",
      desc: "We may use your personal information to:",
      points: [
        "Provide and maintain our website and services",
        "Process your orders and payments",
        "Communicate with you about our products, services, and promotions",
        "Respond to your requests and inquiries",
        "Analyze and improve our website and services",
        "Comply with legal and regulatory requirements",
      ],
    },
    {
      title: "3. How we protect your information",
      desc: "We take appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.",
    },
    {
      title: "4. Sharing your information",
      desc: "We may share your personal information with third-party service providers who help us provide our website and services, such as hosting providers, payment processors, and marketing agencies. We may also share your information with our affiliates, business partners, and other third parties for marketing purposes. We will not sell or rent your personal information to third parties for their marketing purposes without your consent.",
    },
    {
      title: "5. Changes to this Privacy Policy",
      desc: "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.",
    },
    {
      title: "6. Contact Us",
      desc: "If you have any questions about this Privacy Policy or our practices regarding your personal information, please contact us at tsh5@speedworks.com.my.",
    },
  ],
  closing:
    "By using our website, you consent to the collection, use, and sharing of your personal information as described in this Privacy Policy.",
};

//Terms and conditions
export const termsAndConditions = {
  opening:
    'Speedworks Sdn Bhd ("Speedworks") is the owner and manager of this Site. Your use of this Site constitutes your agreement to comply with all applicable laws and regulations, as well as these Terms. Speedworks may change these Terms at any time, and such changes will be effective immediately upon posting on the Site. It is recommended that you review these Terms each time you visit the site. If you do not agree to these Terms, please refrain from using this Site.',
  content: [
    {
      title: "1. Disclaimer",
      desc: 'Speedworks cannot guarantee uninterrupted or error-free access to this site, nor can it ensure specific results from the use of its content, search, or any links on the site. The site and its content are provided "as-is" and "as-available," without any express or implied warranties, including but not limited to warranties of merchantability or fitness for a particular purpose. Speedworks will not be held liable for any damages resulting from the use of this site, including but not limited to direct, indirect, incidental, punitive, and consequential damages. Speedworks assumes no responsibility for the content of any third-party websites that are linked to from this site.',
    },
    {
      title: "2. Information Disclaimer",
      desc: "Speedworks strives to maintain the accuracy of the information on this website. However, Speedworks does not warrant the accuracy of the information and assumes no liability or responsibility for any errors or omissions in the website's contents. Speedworks reserves the right to modify product(s), product description(s), availability, and prices at any time, without prior notice or obligation. We apologise for any inconvenience this may cause.",
    },
    {
      title: "3. Emails",
      desc: "While we endeavor to provide accurate, comprehensive, and up-to-date responses through email inquiries, we cannot guarantee the accuracy or reliability of any opinion, advice, or statement provided in emails. Speedworks assumes no responsibility for any errors or omissions in the content of email responses.",
    },
    {
      title: "4. References to Other Websites",
      desc: "Speedworks does not make any representations regarding the content, safety, reliability, or accuracy of any other websites or the content on such websites that are linked to from this site.",
    },
    {
      title: "5. Trademarks and Copyrights",
      desc: "All brand names mentioned on this site are registered trademarks of their respective owners. Speedworks does not assert any legal claim to trademarks, logos, or other intellectual property, except for its own. Photos included in Speedworks' photo collection may only be posted on the Speedworks website. Any use of these photos on other websites requires prior consent from Speedworks management.",
    },
    {
      title: "6. Indemnification",
      desc: "By using this site, you agree to indemnify and hold Speedworks, its subsidiaries, affiliates, officers, agents, and other partners and employees harmless from any loss, liability, claim, or demand, including reasonable attorney's fees, made by any third party due to or arising out of your use of the site.",
    },
  ],
};

//Disclaimers
export const disclaimers = [
  {
    title: "1. Your Privacy",
    desc:
      "At Speedworks, we are dedicated to safeguarding the privacy of our site visitors and customers. We do not disclose any customer information to third parties, except when it is necessary to provide a service to you, such as arranging for a product to be shipped to you, conducting security checks, or for the purposes of customer research and profiling. We will only share your information with third parties when we have your express permission to do so.",
  },
  {
    title: "2. Your Consent",
    desc:
      "We do not sell any personal information, including your name, address, email address, credit card information, or any other personal details, to any third party.",
  },
  {
    title: "3. Communications and Marketing",
    desc:
      "We may periodically send updates on our latest products, news, and special offers to our customers via email. You may choose to receive such communications from us by subscribing through our website. If you no longer wish to receive marketing emails from us, you may opt-out by clicking the unsubscribe button in the email or by getting in touch with us.",
  },
  {
    title: "4. Disclosure of Your Information",
    desc:
      "We may share your personal information with our group of companies or third parties in the following circumstances:",
    points: [
      "If Speedworks sells or buys any business or assets;",
      "If Speedworks or substantially all of its assets are acquired by a third party, in which case personal data which we hold about our customers may be one of the transferred assets; or",
      "If we are required to disclose your personal data to comply with any legal obligation, or to enforce or apply our terms of service, or to protect the rights, property, or safety of Speedworks, our customers, or others. This may include sharing information with other companies and organizations for the purposes of fraud protection and credit risk reduction.",
    ],
  },
  {
    title: "5. Third Party Sites",
    desc:
      "Our website may include links to websites of our partner networks, advertisers, and other third parties. It is important to note that these websites have their own privacy policies, and we cannot be held responsible for the content or activities of these websites. Before submitting any personal data to these websites, please review their privacy policies to ensure that you are comfortable with their practices.",
  },
  {
    title: "6. Verification of Your Information",
    desc:
      "If you would like to confirm the accuracy of the information you have provided to Speedworks, you can contact us using the email address, phone number, or physical address provided below.",
  },
  {
    title: "7. Disclaimers",
    desc:
      "Speedworks may update this Disclaimer document at any time by posting the changes on this page. We encourage you to check this page periodically to stay informed about any updates to the site. Please note that the Disclaimers outlined here do not create any contractual or legal rights for any party.",
  },
];
