import Appbar from "./components/Appbar";
import React from 'react';
import theme from "./styles/theme";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/HomePage";
import About from "./components/AboutPage";
import Products from "./components/ProductsPage";
import ProductItem from "./components/ProductItemPage";
import Contact from "./components/ContactPage";
import Privacy from "./components/PrivacyPage";
import Terms from "./components/TermsPage";
import Disclaimers from "./components/DisclaimersPage";
import Error from "./components/ErrorPage";
import { UIProvider } from "./context/ui";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
      <ScrollToTop />
        <UIProvider>
          <Appbar />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/about" element={<About />}></Route>
            <Route exact path="/products" element={<Products />}></Route>
            <Route exact path="/products/item" element={<ProductItem />}></Route>
            <Route exact path="/contact" element={<Contact />}></Route>
            <Route exact path="/privacy" element={<Privacy />}></Route>
            <Route exact path="/terms" element={<Terms />}></Route>
            <Route exact path="/disclaimers" element={<Disclaimers />}></Route>
            <Route exact path="*" element={<Error />}></Route>
          </Routes>
          <Footer />
        </UIProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
