
export const allCategories = [
    {
        id: 1,
        name: "Brakes",
        desc: [
            "Optimal braking power when pairing AXT Brake Discs with AXT Brake Pads.",
            "Highly recommended to use AXT Brake Discs with DOT 5 Brake Fluid for superior braking performance.",
            "Made in Australia."
        ],
        imageCount: 1,
        imageName: "brakes"
    },
    {
        id: 2,
        name: "Coil Springs",
        desc: [
            "Sports coil spring sets for a lowered and sporty ride.",
            "10-15% firmer than standard springs for ride comfort.",
            "Coil springs are cold wound chrome silizium springs.",
            "Can be paired with Hot Bits Sports Shock Absorbers.",
            "Installation available on request at our Speedworks workshop in PJ.",
            "Made in Germany."
        ],
        imageCount: 0,
        imageName: "coil_springs"
    },
    {
        id: 3,
        name: "Exhaust Extractor",
        desc: [
            "Extractor pipings are all mandrel-bent, resulting in maximum air flow.",
            "Dyno-tested to guarantee performance improvements.",
            "Designed to ensure maximum power gains, improved throttle response and proper fitting.",
            "Made from stainless steel pipings and mild steel flanges.",
            "Flexible joint which reduces noise from engine vibrations, thus giving the user a more comfortable and quiet ride.",
            "All joints are finished in TIG/MIG welding, to provide maximum aesthetics with no leak.",
            "Installation available on request at our Speedworks workshop in PJ.",
            "6 months warranty."
        ],
        imageCount: 0,
        imageName: "exhaust_extractor"
    },
    {
        id: 4,
        name: "Exhaust Muffler",
        desc: [
            "All joints are finished in TIG/MIG welding, to provide maximum aesthetics with no leak.",
            "Installation available on request at our Speedworks workshop in PJ.",
            "6 months warranty."
        ],
        imageCount: 0,
        imageName: "exhaust_muffler"
    },
    {
        id: 5,
        name: "Exhaust System",
        desc: [
            "All joints are finished in TIG/MIG welding, to provide maximum aesthetics with no leak.",
            "Installation available on request at our Speedworks workshop in PJ.",
            "6 months warranty."
        ],
        imageCount: 0,
        imageName: "exhaust_system"
    },
    {
        id: 6,
        name: "Exhaust Tailpipe",
        desc: [
            "All joints are finished in TIG/MIG welding, to provide maximum aesthetics with no leak.",
            "Installation available on request at our Speedworks workshop in PJ.",
            "6 months warranty."
        ],
        imageCount: 0,
        imageName: "exhaust_tailpipe"
    },
    {
        id: 7,
        name: "Intercooler Kit",
        desc: [
            "Cools engine air intake and optimises fuel combustion.",
            "Increases engine power and efficiency while reducing fuel consumption.",
            "Suitable for turbo cars.",
            "Easy to install.",
            "100% aluminium made."
        ],
        imageCount: 0,
        imageName: "intercooler_kit"
    },
    {
        id: 8,
        name: "K&N Air Filter",
        desc: [
            "Top quality engine protection & airflow.",
            "K&N engine air filters are designed to be washable, reusable, and last for the life of your vehicle.",
            "The premium filter design incorporates multiple layers of proprietary cotton gauze media and a specially-engineered oil, originally designed for the demands of the racetrack, and now custom-engineered for your vehicle.",
            "Tested, proven, and trusted for over 50 years, millions of drivers rely on K&N air filters to help protect their engines from premature wear."
        ],
        imageCount: 4,
        imageName: "k&n_air_filter"
    },
    {
        id: 9,
        name: "K&N Other",
        desc: [
            "K&N has been an industry-leader in automotive filtration and technology.",
            "K&N offers products to increase performance, protection, and longevity in various of vehicle applications."
        ],
        imageCount: 2,
        imageName: "k&n_air_filter"
    },
    {
        id: 10,
        name: "Pedal Throttle Controller",
        desc: [
            "Faster throttle response by reducing lag for quick acceleration.",
            "Instant power delivery on demand.",
            "Easy installation: Plug & Play.",
            "6 acceleration modes : Race, Semi Race, Sport, Semi Sport, Original, Econ",
            "1 Year Warranty.",
            "ISO9000 Quality Management System.",
            "CE, FCC, ROHS."
        ], 
        imageCount: 4,
        imageName: "pedal_throttle_controller"
    },
    {
        id: 11,
        name: "Silicone Hose",
        desc: [
            "High performance 3 layered silicone hose, providing high endurance and reliability.",
            "3 layer pressure bearing = 15 KG/cm², 200% stronger than OE rubber hose.",
            "Max temperature: 170°C.",
            "Size tolerance: +/- 0.5mm.",
            "Sporty red colour.",
            "Orbit clamps available and sold separately."
        ],
        imageCount: 0,
        imageName: "silicone_hose"
    },
    {
        id: 12,
        name: "Suspension Kit",
        desc: [
            "High pressure mono-tube shocks for a plush ride and improved handling.",
            "Shocks are fully serviceable and damping settings can be adjusted at our Speedworks workshop in PJ.",
            "All spare parts are available when required.",
            "Installation available on request at our Speedworks workshop in PJ.",
            "6 months warranty."
        ],
        imageCount: 0,
        imageName: "suspension_kit"
    },
    {
        id: 13,
        name: "Turbo Kit",
        desc: [
            "Speedworks Turbo Kits are tuned with 0.5 to 0.7 Bar Boost using only RON 95 Petrol without any adverse effects to all standard engine parts.",
            "Power increases are about 50% in BHP and torque.",
            "Each kit includes the following:",
            "Garrett/KKK Turbocharger - which are the most popular and reliable turbos nowadays.",
            "Turbo Exhaust Manifold - specially designed with heat shields to keep the turbo heat away.",
            "Aluminium Alloy Intercooler - front mounted, high flow to give maximum cooling efficiency.",
            "Greddy E-Manage Ultimate/SS ECU - controls the turbo fuel & ignition required and maintains all other systems as standard.",
            "4 Larger Fuel Injectors - for an accurate increase in fuel distribution during boost.",
            "Hot Bits Turbo Exhaust System - turbo to tailpipe ensures free flow with no back pressure, preventing heat build up and restricted turbo boost.",
            "K&N Open Pod Air Filter - installed with Hot Bits adaptors and heat-shields to deliver unrestricted external cool air.",
            "Stainless Steel Turbo Pipes - pipes are mandrel bent and used with heat-resistant silicone hoses.",
            "Greddy Blow-Off Valve - to deliver a sporty turbo blow-off sound.",
            "Dyno-Tuned In-House - all fuel and ignition cells are mapped and tested to ensure that the Turbo ECU Map runs smoothly without any hitches.",
            "6 Months Warranty."
    ],
        imageCount: 1,
        imageName: "turbo_kit"
    },
    {
        id: 99,
        name: "Other",
        desc: [],
        imageCount: 0,
        imageName: "other"
    },
];


export const allSubCategories = [
    {
        id: 101,
        name: "AXT Brake Pads",
        imageCount: 1,
        imageName: "axt_brake_pad"
    },
    {
        id: 102,
        name: "AXT Brake Discs",
        imageCount: 1,
        imageName: "axt_brake_disc"
    },
    {
        id: 103,
        name: "AXT Slotted Brake Discs",
        imageCount: 1,
        imageName: "axt_brake_disc_slotted"
    },
    {
        id: 104,
        name: "AXT Oversized Brake Discs",
        imageCount: 1,
        imageName: "axt_brake_disc_oversized"
    },
    {
        id: 201,
        name: "HB Coil Springs Front",
        imageCount: 1,
        imageName: "hb_coil_spring_front"
    },
    {
        id: 202,
        name: "HB Coil Springs Rear",
        imageCount: 1,
        imageName: "hb_coil_spring_rear"
    },
    {
        id: 203,
        name: "HB Coil Springs Front & Rear",
        imageCount: 1,
        imageName: "hb_coil_spring_front_rear"
    }
];