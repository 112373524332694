import React from 'react';
import { Grid } from "@mui/material";
import {
  CtgSection,
  CtgDescription,
  CtgTitle,
  CtgImageContainer,
  CtgIconContainer,
  CtgContainer,
  CtgTextContainer,
  CtgTextTitleContainer,
} from "../../../styles/HomePage/Category";
import { categories } from "../../../data/site";
import { Link } from "react-router-dom";
import Image from "mui-image";

export default function Category() {
  const renderCategories = categories.map((category) => (
    <Grid
      item
      key={category.id}
      xs={4}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      <CtgContainer>
        <Link to={`/products?cat_id=${category.catId}`} style={{ textDecoration: "none" }}>
          <CtgImageContainer>
            <img
              alt={category.name}
              src={category.imageLg}
              srcSet={`
              ${category.imageMd} 500w,
                ${category.imageLg} 1000w
              `}
              sizes="(max-width: 700px) 70vw, (max-width: 900px) 60vw, 28vw"
              height="100%"
              width="100%"
            />
          </CtgImageContainer>
          <CtgTextContainer>
            <CtgTextTitleContainer>
              <CtgIconContainer>
                <Image src={category.icon} fit="contain" duration={500} />
              </CtgIconContainer>
              <CtgTitle variant="body1">{category.title}</CtgTitle>
            </CtgTextTitleContainer>
            <CtgDescription variant="body2">
              {category.description}
            </CtgDescription>
          </CtgTextContainer>
        </Link>
      </CtgContainer>
    </Grid>
  ));

  return (
    <CtgSection>
      <Grid
        container
        spacing={{ xs: 6, sm: 10, md: 6 }}
        justifyContent={"center"}
        columns={{ xs: 4, md: 12 }}
      >
        {renderCategories}
      </Grid>
    </CtgSection>
  );
}
