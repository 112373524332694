import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PageContainer = styled(Container)(({ theme }) => ({
      margin: "64px 0px 0px 0px",
      // padding: "20px 100px 60px 100px",
      paddingTop: "20px",
      paddingBottom: "100px",
      paddingLeft: "7%",
      paddingRight: "7%",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        margin: "48px 0px 0px 0px",
        // padding: "20px 60px 60px 60px",
        paddingBottom: "60px",
      },
    [theme.breakpoints.down("sm")]: {
      // padding: "10px 40px 40px 40px",
      paddingTop: "10px",
      paddingBottom: "40px",
    },
  }));