import React from "react";
import { PageContainer } from "../../styles/PageContainer";
import {
  AllContactDetailsContainer,
  ContactDetailContainer,
  ContactDetailsContainer,
  ContactMapDetailsContainer,
  ContactOutletText,
  ContactOutletContainer,
  DayContainer,
  DayHoursContainer,
  DetailHeaderText,
  DetailIconButton,
  DetailIconContainer,
  DetailTextContainer,
  DetailTextIconContainer,
  DetailValueText,
  HoursContainer,
  MapSizeContainer,
  MapRatioContainer,
  OpeningHoursContainer,
  SectionDivider,
  TitleText,
} from "../../styles/ContactPage";
import { contactMalaysia, contactSingapore } from "../../data/site";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import PlaceIcon from "@mui/icons-material/Place";
import Image from "mui-image";
import { Icon } from "@mui/material";

function Contact() {
  const renderSpeedworksMalaysia = (
    <ContactOutletContainer>
      <ContactOutletText variant="body1">Speedworks Malaysia</ContactOutletText>
      <ContactMapDetailsContainer>
        <MapSizeContainer>
          <MapRatioContainer>
            <iframe
              title="Speedworks Malaysia Google Maps"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7967.966187533728!2d101.62992131752581!3d3.0991520000000166!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4beeb0bd6a99%3A0x217e0e7b05852498!2sSpeedworks!5e0!3m2!1sen!2sus!4v1680509466384!5m2!1sen!2sus"
              width="100%"
              height="100%"
              style={{ border: 0, position: "absolute", top: 0, left: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </MapRatioContainer>
        </MapSizeContainer>
        <AllContactDetailsContainer>
          <ContactDetailsContainer>
            {renderContactDetails(contactMalaysia.details)}
          </ContactDetailsContainer>

          <OpeningHoursContainer>
            <DetailHeaderText variant="body2">Opening Hours</DetailHeaderText>
            {renderOpeningHours(contactMalaysia.openingHours)}
          </OpeningHoursContainer>
        </AllContactDetailsContainer>
      </ContactMapDetailsContainer>
    </ContactOutletContainer>
  );

  const renderSpeedworksSingapore = (
    <ContactOutletContainer>
      <ContactOutletText variant="body1">
        Speedworks Singapore
      </ContactOutletText>
      <ContactMapDetailsContainer>
        <MapSizeContainer>
          <MapRatioContainer>
            <iframe
              title="Speedworks Singapore Google Maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15954.995589731765!2d103.86808356758392!3d1.3266168620185952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da17878175c3ab%3A0xc10a2abea646cf4f!2sSpeedworks%20Pte%20Ltd!5e0!3m2!1sen!2sus!4v1681281028348!5m2!1sen!2sus"
              width="100%"
              height="100%"
              style={{ border: 0, position: "absolute", top: 0, left: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </MapRatioContainer>
        </MapSizeContainer>
        <AllContactDetailsContainer>
          <ContactDetailsContainer>
            {renderContactDetails(contactSingapore.details)}
          </ContactDetailsContainer>

          <OpeningHoursContainer>
            <DetailHeaderText variant="body2">Opening Hours</DetailHeaderText>
            {renderOpeningHours(contactSingapore.openingHours)}
          </OpeningHoursContainer>
        </AllContactDetailsContainer>
      </ContactMapDetailsContainer>
    </ContactOutletContainer>
  );

  function renderContactDetails(details) {
    return details.map((detail) => (
      <ContactDetailContainer key={detail.type}>
        <DetailHeaderText variant="body2">{detail.type}</DetailHeaderText>
        <DetailTextIconContainer>
          <DetailTextContainer>
            <DetailValueText variant="subtitle1">
              {detail.value}
            </DetailValueText>
          </DetailTextContainer>
          <DetailIconContainer>
            {detail.links.map((link) => (
              <DetailIconButton
                key={link.icon}
                onClick={() => {
                  window.open(link.url, "_blank");
                }}
              >
                {ContactIcon(link.icon)}
              </DetailIconButton>
            ))}
          </DetailIconContainer>
        </DetailTextIconContainer>
      </ContactDetailContainer>
    ));
  }

  function renderOpeningHours(openingHours) {
    return openingHours.map((item) => (
      <DayHoursContainer key={item.day}>
        <DayContainer>
          <DetailValueText variant="subtitle1">{item.day}</DetailValueText>
        </DayContainer>
        <HoursContainer>
          <DetailValueText variant="subtitle1">{item.hours}</DetailValueText>
        </HoursContainer>
      </DayHoursContainer>
    ));
  }

  function ContactIcon(icon) {
    switch (icon) {
      case "GoogleMaps":
        return (
          <Icon>
            <Image
              src="/images/social_media/google_maps_icon.png"
              fit="contain"
              duration={0}
            />
          </Icon>
        );
      case "Waze":
        return (
          <Icon>
            <Image
              src="/images/social_media/waze_icon.png"
              fit="contain"
              duration={0}
            />
          </Icon>
        );
      case "Phone":
        return <PhoneIcon />;
      case "Whatsapp":
        return (
          <Icon>
            <Image
              src="/images/social_media/whatsapp_icon.png"
              fit="contain"
              duration={0}
            />
          </Icon>
        );
      case "Email":
        return <MailIcon />;
      default:
        return <PlaceIcon />;
    }
  }

  return (
    <PageContainer disableGutters maxWidth={false}>
      <TitleText variant="h2">Contact Us</TitleText>
      {renderSpeedworksMalaysia}
      <SectionDivider />
      {renderSpeedworksSingapore}
    </PageContainer>
  );
}

export default Contact;
