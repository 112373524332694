import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const TitleText = styled(Typography)(({ theme }) => ({
  paddingBottom: "20px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    fontSize: "2.75rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
    paddingBottom: "10px",
  },
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  whiteSpace: "pre-wrap",
  padding: "20px 40px",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    padding: "20px 30px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 20px",
  },
}));

  
  export const ContentText = styled(Typography)(({ theme }) => ({
    width: "100%",
    textAlign: "start",
    padding: "6px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0rem",
    },
  }));

  export const PointsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flex: "1",
  }));

  export const PointsIconContainer = styled(Box)(({ theme }) => ({
    padding: "0px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }));
