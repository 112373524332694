import React from "react";
import { PageContainer } from "../../styles/PageContainer";
import {
  ContentContainer,
  ContentText,
  TitleText,
} from "../../styles/PrivacyPage";
import {  termsAndConditions } from "../../data/site";

function Terms() {
  const renderContent = termsAndConditions.content.map((term) => (
    <ContentContainer>
      <ContentText variant="body2" fontWeight={600}>
        {term.title}
      </ContentText>
      <ContentText variant="body2">{term.desc}</ContentText>
    </ContentContainer>
  ));

  return (
    <PageContainer disableGutters maxWidth={false}>
      <TitleText variant="h1">Terms and Conditions</TitleText>
      <ContentContainer>
        <ContentText variant="body2">{termsAndConditions.opening}</ContentText>
      </ContentContainer>
      {renderContent}
    </PageContainer>
  );
}

export default Terms;
