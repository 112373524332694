import React from "react";
import { PageContainer } from "../../styles/PageContainer";
import {
  ContentContainer,
  PointsContainer,
  PointsIconContainer,
  ContentText,
  TitleText,
} from "../../styles/PrivacyPage";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { privacyPolicy } from "../../data/site";

function Privacy() {
  const renderContent = privacyPolicy.content.map((item) => (
    <ContentContainer>
      <ContentText variant="body2" fontWeight={600}>
        {item.title}
      </ContentText>
      <ContentText variant="body2">{item.desc}</ContentText>
      {item.points?.map((point) => (
        <PointsContainer>
          <PointsIconContainer>
            <DoubleArrowIcon color="secondary" />
          </PointsIconContainer>
          <ContentText variant="body2">{point}</ContentText>
        </PointsContainer>
      ))}
    </ContentContainer>
  ));

  return (
    <PageContainer disableGutters maxWidth={false}>
      <TitleText variant="h1">Privacy Policy</TitleText>
      <ContentContainer>
        <ContentText variant="body2">{privacyPolicy.opening}</ContentText>
      </ContentContainer>
      {renderContent}
      <ContentContainer>
        <ContentText variant="body2">{privacyPolicy.closing}</ContentText>
      </ContentContainer>
    </PageContainer>
  );
}

export default Privacy;
