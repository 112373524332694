import {
  ContentContainer,
  ContentImageContainer,
  ContentText,
  PerformanceItemContainer,
  PerformanceIconContainer,
  PerformanceImageContainer,
  PerformanceImageSection,
  ProductImageContainer,
  ProductItemContainer,
  ProductTextContainer,
  SectionDivider,
  SubcontentText,
  TitleText,
  ProductTitleText,
  PerformanceDescContainer,
  PerformanceTitleContainer,
} from "../../styles/AboutPage";
import Image from "mui-image";
import { PageContainer } from "../../styles/PageContainer";
import { aboutContent, categories } from "../../data/site";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Link } from "react-router-dom";

function About() {
  const renderProductList = categories.map((category) => (
    <Link
      key={category.id}
      to={`/products?cat_id=${category.catId}`}
      style={{ textDecoration: "none" }}
    >
      <ProductItemContainer key={category.catId}>
        <ProductImageContainer>
          <Image src={category.imageLg} fit="contain" duration={500} />
        </ProductImageContainer>
        <ProductTextContainer>
          <ProductTitleText variant="body1">{category.name}</ProductTitleText>
          <SubcontentText variant="body2">{category.aboutDesc}</SubcontentText>
        </ProductTextContainer>
      </ProductItemContainer>
    </Link>
  ));

  const renderPerformanceList = aboutContent.performanceList.map((perf) => (
    <PerformanceItemContainer key={perf.title}>
      <PerformanceTitleContainer>
        <PerformanceIconContainer>
          <DoubleArrowIcon color="secondary" />
        </PerformanceIconContainer>
        <ContentText variant="body1">{perf.title}</ContentText>
      </PerformanceTitleContainer>

      <PerformanceDescContainer>
        <SubcontentText variant="body2">{perf.content}</SubcontentText>
      </PerformanceDescContainer>
    </PerformanceItemContainer>
  ));

  return (
    <PageContainer disableGutters maxWidth={false}>
      <TitleText variant="h2">About Us</TitleText>
      <Image src="/images/about/about-1.jpg" fit="fill" />
      <ContentContainer flexDirection="row-reverse">
        <ContentImageContainer flex={10}>
          <Image src="/images/about/about-2.jpg" fit="contain" />
        </ContentImageContainer>
        <ContentContainer flex={1} />
        <ContentText flex={14} variant="body1">
          {aboutContent.intro}
        </ContentText>
      </ContentContainer>

      <ContentContainer>
        <ContentImageContainer flex={8}>
          <Image src="/images/about/about-3.jpg" fit="contain" />
        </ContentImageContainer>
        <ContentContainer flex={1} />
        <ContentText flex={16}>{aboutContent.premise}</ContentText>
      </ContentContainer>

      <SectionDivider />
      <ContentText variant="body1">{aboutContent.product}</ContentText>
      {renderProductList}
      <SectionDivider />

      <PerformanceImageSection>
        <PerformanceImageContainer flex={1}>
          <Image src="/images/about/about-4.png" fit="contain" />
        </PerformanceImageContainer>
        <PerformanceImageContainer flex={1}>
          <Image src="/images/about/about-5.jpg" fit="contain" />
        </PerformanceImageContainer>
      </PerformanceImageSection>

      <ContentText variant="body1">{aboutContent.performance}</ContentText>
      {renderPerformanceList}

      <ContentContainer>
        <ContentText variant="body1">{aboutContent.closing}</ContentText>
      </ContentContainer>
    </PageContainer>
  );
}

export default About;
