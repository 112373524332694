import React from 'react';
import {
  FeaturesSection,
  PostCategory,
  PostContainer,
  PostHeader,
  PostModel,
  VideoHeader,
  VideoInnerContainer,
  VideoOuterContainer,
} from "../../../styles/HomePage/Features";
import { featuredItems, featuredVideos } from "../../../data/site";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../styles/HomePage/Features/carousel.css";
import Image from "mui-image";

export default function Features() {
  const renderPosts = featuredItems
    .slice()
    .reverse()
    .map((post) => (
      <PostContainer key={post.id}>
        <a href={post.url} style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">
          <Image src={post.image} />
          <PostCategory variant="body2">{post.category}</PostCategory>
          <PostModel variant="body1">{post.model}</PostModel>
        </a>
      </PostContainer>
    ));

  const renderVideos = featuredVideos
    .slice()
    .reverse()
    .map((video) => (
      <VideoOuterContainer key={video.id}>
        <VideoInnerContainer>
          <iframe
            width="100%"
            height="100%"
            src={video.src}
            title={video.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </VideoInnerContainer>
      </VideoOuterContainer>
    ));

  const responsivePosts = {
    xl: {
      breakpoint: { max: 3000, min: 1536 },
      items: 3,
    },
    lg: {
      breakpoint: { max: 1536, min: 1200 },
      items: 3,
    },
    md: {
      breakpoint: { max: 1200, min: 900 },
      items: 2,
    },
    sm: {
      breakpoint: { max: 900, min: 700 },
      items: 2,
    },
    xs: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };

  const responsiveVideos = {
    xl: {
      breakpoint: { max: 3000, min: 1536 },
      items: 2,
    },
    lg: {
      breakpoint: { max: 1536, min: 1200 },
      items: 2,
    },
    md: {
      breakpoint: { max: 1200, min: 900 },
      items: 2,
    },
    sm: {
      breakpoint: { max: 900, min: 700 },
      items: 1,
    },
    xs: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <FeaturesSection>
        <PostHeader variant="h3">Featured Items</PostHeader>

        <Carousel responsive={responsivePosts} infinite={true} autoPlay={true}>
          {renderPosts}
        </Carousel>

        <VideoHeader variant="h3">Videos</VideoHeader>
        <Carousel responsive={responsiveVideos} infinite={true}>
          {renderVideos}
        </Carousel>
      </FeaturesSection>
    </div>
  );
}
