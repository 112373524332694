import React from "react";
import { PageContainer } from "../../styles/PageContainer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { allProducts } from "../../data/product";
import { allCategories, allSubCategories } from "../../data/productCategory";
import { contactMalaysia } from "../../data/site";
import {
  CarouselContainer,
  ImageOuterContainer,
  DetailsContainer,
  ProductContainer,
  ImageInnerContainer,
  DescriptionSection,
  LinksContainer,
  LinkButtonContainer,
  ProductNameDescContainer,
  LinkIconButton,
  HeaderText,
  SubheaderText,
  SubtitleText,
  BodyText,
  DescItemIcon,
} from "../../styles/ProductItemPage";
import Carousel from "react-multi-carousel";
import Image from "mui-image";
import MailIcon from "@mui/icons-material/Mail";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Colors } from "../../styles/theme";
import Error from "../ErrorPage";

function ProductItem() {
  let [searchParams] = useSearchParams();
  const itemId = parseInt(searchParams.get("item_id"));

  const product = allProducts.find(
    (product) => product.id.toString() === itemId.toString()
  );

  if (product === undefined || product === null) {
    return (
      <PageContainer disableGutters maxWidth={false}>
        <Error />
      </PageContainer>
    );
  }

  const category = allCategories.find(
    (cat) => cat.id.toString() === product.category.toString()
  );

  function renderImages(product) {
    const images = [];
    const productImageStartPath = "/images/products/items/";
    const categoryImageStartPath = "/images/products/categories/";

    //Product Images
    for (let i = 0; i < product.imageCount; i++) {
      images.push(
        <ImageOuterContainer key={i}>
          <ImageInnerContainer>
            <img
              alt={`${product.name} ${i + 1}`}
              src={`${productImageStartPath}${product.id}-${i + 1}-lg.jpg`}
              srcSet={`
              ${productImageStartPath}${product.id}-${i + 1}-sm.jpg 250w,
                ${productImageStartPath}${product.id}-${i + 1}-md.jpg 500w,
                ${productImageStartPath}${product.id}-${i + 1}-lg.jpg 1000w,
              `}
              sizes="(max-width: 700px) 48.16vw, (max-width: 900px) 36.12vw, 32.68vw"
              height="100%"
              width="100%"
            />
          </ImageInnerContainer>
        </ImageOuterContainer>
      );
    }

    //Subcategory Images
    if (product.subCategory !== 0) {
      const subCategory = allSubCategories.find(
        (cat) => cat.id.toString() === product.subCategory.toString()
      );

      for (let i = 0; i < subCategory.imageCount; i++) {
        images.push(
          <ImageOuterContainer key={i}>
            <ImageInnerContainer>
              <img
                alt={`${subCategory.name} ${i + 1}`}
                src={`${categoryImageStartPath}${subCategory.imageName}-${
                  i + 1
                }-lg.jpg`}
                srcSet={`
                ${categoryImageStartPath}${subCategory.imageName}-${
                  i + 1
                }-sm.jpg 250w,
                  ${categoryImageStartPath}${subCategory.imageName}-${
                  i + 1
                }-md.jpg 500w,
                  ${categoryImageStartPath}${subCategory.imageName}-${
                  i + 1
                }-lg.jpg 1000w,
                `}
                sizes="(max-width: 700px) 48.16vw, (max-width: 900px) 36.12vw, 32.68vw"
                height="100%"
                width="100%"
              />
            </ImageInnerContainer>
          </ImageOuterContainer>
        );
      }
    }

    //Category Images
    for (let i = 0; i < category.imageCount; i++) {
      images.push(
        <ImageOuterContainer key={i}>
          <ImageInnerContainer>
            <img
              alt={`${category.name} ${i + 1}`}
              src={`${categoryImageStartPath}${category.imageName}-${
                i + 1
              }-lg.jpg`}
              srcSet={`
              ${categoryImageStartPath}${category.imageName}-${
                i + 1
              }-sm.jpg 250w,
                ${categoryImageStartPath}${category.imageName}-${
                i + 1
              }-md.jpg 500w,
                ${categoryImageStartPath}${category.imageName}-${
                i + 1
              }-lg.jpg 1000w,
              `}
              sizes="(max-width: 700px) 48.16vw, (max-width: 900px) 36.12vw, 32.68vw"
              height="100%"
              width="100%"
            />
          </ImageInnerContainer>
        </ImageOuterContainer>
      );
    }
    return images;
  }

  const responsiveCarousel = {
    xl: {
      breakpoint: { max: 3000, min: 1536 },
      items: 1,
    },
    lg: {
      breakpoint: { max: 1536, min: 1200 },
      items: 1,
      partialVisibilityGutter: 40,
    },
    md: {
      breakpoint: { max: 1200, min: 900 },
      items: 1,
    },
    sm: {
      breakpoint: { max: 900, min: 700 },
      items: 1,
    },
    xs: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };

  function renderVehicleRows() {
    return product.vehicles.length === 0 ? (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row" colSpan={4}>
          <BodyText variant="body2" align="center">
            Universal
          </BodyText>
        </TableCell>
      </TableRow>
    ) : (
      product.vehicles.map((row, index) => (
        <TableRow
          key={index}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            <BodyText variant="body2">{row.make}</BodyText>
          </TableCell>
          <TableCell>
            <BodyText variant="body2">{row.model}</BodyText>
          </TableCell>
          <TableCell align="center">
            <BodyText variant="body2">
              {row.startYear + " - " + row.endYear}
            </BodyText>
          </TableCell>
          <TableCell align="center">
            <BodyText variant="body2">{row.engine}</BodyText>
          </TableCell>
        </TableRow>
      ))
    );
  }

  const vehicleTable = (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 0 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <BodyText variant="body2" fontWeight="bold">
                Make
              </BodyText>
            </TableCell>
            <TableCell>
              <BodyText variant="body2" fontWeight="bold">
                Model
              </BodyText>
            </TableCell>
            <TableCell align="center">
              <BodyText variant="body2" fontWeight="bold">
                Years
              </BodyText>
            </TableCell>
            <TableCell align="center">
              <BodyText variant="body2" fontWeight="bold">
                Engine
              </BodyText>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderVehicleRows()}</TableBody>
      </Table>
    </TableContainer>
  );

  const enquiryMessage = `${encodeURIComponent(
    window.location.href
  )}%0A%0AI would like to enquire about ${encodeURIComponent(
    category.name
  )} ${encodeURIComponent(product.name)}. `;

  const whatsappLink =
    contactMalaysia.details
      .find((detail) => detail.type === "Tel")
      .links.find((link) => link.icon === "Whatsapp").url +
    "&text=" +
    enquiryMessage;

  const emailLink =
    contactMalaysia.details
      .find((detail) => detail.type === "Email")
      .links.find((link) => link.icon === "Email").url +
    "?subject=Product%20Enquiry&body=" +
    enquiryMessage;

  function renderDescList() {
    return (
      <List>
        {product.desc.map((desc, index) => (
          <ListItem key={`prod${index}`}>
            <DescItemIcon>
              <DoubleArrowIcon color="secondary" />
            </DescItemIcon>
            <ListItemText>
              <SubheaderText variant="h6" color={Colors.grey}>
                {desc}
              </SubheaderText>
            </ListItemText>
          </ListItem>
        ))}
        {product.desc.length > 0 && (
          <ListItem>
            <ListItemText></ListItemText>
          </ListItem>
        )}
        {category.desc.map((desc, index) => (
          <ListItem key={`cat${index}`}>
            <DescItemIcon>
              <CheckCircleOutlineIcon />
            </DescItemIcon>
            <ListItemText>
              <SubheaderText variant="h6" color={Colors.grey}>
                {desc}
              </SubheaderText>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    );
  }

  function renderShopeeLink(shopeeLink) {
    return shopeeLink !== "" ? (
      <LinkButtonContainer>
        <LinkIconButton
          onClick={() => {
            window.open(shopeeLink, "_blank");
          }}
        >
          <Image
            src="/images/social_media/shopee_icon.png"
            fit="contain"
            height={40}
            width={40}
            duration={0}
            bgColor="transparent"
          />
        </LinkIconButton>
      </LinkButtonContainer>
    ) : null;
  }

  function renderProduct() {
    return (
      <div>
        <ProductContainer>
          <CarouselContainer>
            <Carousel
              responsive={responsiveCarousel}
              infinite={true}
              autoPlay={false}
              partialVisbile={false}
              showDots={true}
            >
              {renderImages(product)}
            </Carousel>
          </CarouselContainer>
          <DetailsContainer>
            <SubheaderText variant="h6" color={Colors.grey}>
              {category.name}
            </SubheaderText>
            <ProductNameDescContainer>
              <HeaderText variant="h5">{product.name}</HeaderText>
            </ProductNameDescContainer>

            <ProductNameDescContainer>
              <HeaderText variant="h5" fontWeight="500">
                {"RM " + product.price}
              </HeaderText>
            </ProductNameDescContainer>

            <LinksContainer>
              <LinkButtonContainer>
                <LinkIconButton
                  onClick={() => {
                    window.open(whatsappLink, "_blank");
                  }}
                >
                  <Image
                    src="/images/social_media/whatsapp_icon.png"
                    fit="contain"
                    height={40}
                    width={40}
                    duration={0}
                    bgColor="transparent"
                  />
                </LinkIconButton>
              </LinkButtonContainer>

              <LinkButtonContainer>
                <LinkIconButton
                  onClick={() => {
                    window.open(emailLink, "_blank");
                  }}
                >
                  <MailIcon sx={{ fontSize: { xs: 32, sm: 40 } }} />
                </LinkIconButton>
              </LinkButtonContainer>

              {renderShopeeLink(product.shopee)}
            </LinksContainer>

            <SubtitleText variant="subtitle2">
              Please WhatsApp or email us for more information or a direct
              purchase.
            </SubtitleText>
          </DetailsContainer>
        </ProductContainer>

        <DescriptionSection>
          <HeaderText variant="h5" paddingY="20px">
            Vehicles
          </HeaderText>
          {vehicleTable}
        </DescriptionSection>

        <DescriptionSection>
          <HeaderText variant="h5" paddingY="20px">
            Product Description
          </HeaderText>
          {renderDescList()}
        </DescriptionSection>
      </div>
    );
  }

  return (
    <PageContainer disableGutters maxWidth={false}>
      {renderProduct()}
    </PageContainer>
  );
}

export default ProductItem;
