import { styled } from "@mui/material/styles";
import { Typography, Box, IconButton, MenuItem, Select, InputLabel, Button, TextField } from "@mui/material";
import { Colors } from "../theme";

export const TitleText = styled(Typography)(({ theme }) => ({
  paddingBottom: "20px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
    paddingBottom: "16px",
  },
}));

//SearchTexField, SearchIconButton, and DesktopSortFilter
export const SearchContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
}));

export const SearchIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "8px",
  padding: "14.5px",
  border: "1px solid rgba(0, 0, 0, 0.2)",
  borderRadius: "4px",
  [theme.breakpoints.down("sm")]: {
    padding: "13.25px",
  },
  "& .MuiTouchRipple-root .MuiTouchRipple-child": {
    borderRadius: "4px"
  },
}));

export const DesktopSortFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingLeft: "20px",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const SortFilterLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: "1.1rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.85rem",
  },
}));

export const SortFilterSelect = styled(Select)(({ theme }) => ({
  fontSize: "1.1rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.85rem",
  },
}));

export const SortFilterMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "1.1rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.85rem",
  },
}));

//MobilePaginate, and MobileSortFilter
export const MobileFilterContainer = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    paddingTop: "12px"
  },
}));

export const MobilePaginateContainer = styled(Box)(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  width: "160px",
  [theme.breakpoints.down("lg")]: {
    width: "130px"
  },
  [theme.breakpoints.down("md")]: {
    width: "120px"
  },
  [theme.breakpoints.down("sm")]: {
    flex: "1"
  },
}));

export const MobileSortFilterContainer = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "8px",
  flex: "2"
}));

//CategoryFilter and DesktopPaginate
export const FilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "24px 0px 64px 0px",
  [theme.breakpoints.down("sm")]: {
    padding: "12px 0px 48px 0px",
  },
}));

export const CategoryButtonContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: "1",
}));

export const CategoryButton = styled(Button)(() => ({
  textTransform: "none",
  padding: "14.5px"
}));

export const CategoryFiltersContainer = styled(Box)(({ theme }) => ({
  paddingBottom: "64px",
  padding: "16px 20px 0px 20px",
  [theme.breakpoints.down("sm")]: {
    padding: "16px 10px 0px 10px",
  },
}));

export const CategoryFilterText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.85rem",
  },
}));

export const DesktopPaginateContainer = styled(Box)(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  width: "160px",
  marginLeft: "8px",
  [theme.breakpoints.down("lg")]: {
    width: "130px"
  },
  [theme.breakpoints.down("md")]: {
    width: "120px"
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
    width: "80px"
  },
}));

export const PaginateTextField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    padding: "0px"
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    color: "rgba(0, 0, 0)",
    WebkitTextFillColor: "rgba(0, 0, 0)",
    textAlign: "center"
  },
}));

export const PaginateButton = styled(IconButton)(({ theme }) => ({
  color: Colors.black,
  height: "auto",
  width: "auto",
  minWidth: "0px",
  borderRadius: "4px",
  [theme.breakpoints.down("sm")]: {
    width: "24px"
  },
  "& .MuiTouchRipple-root .MuiTouchRipple-child": {
    borderRadius: "4px"
  },
}));

//PRODUCTS
export const ProductSectionContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingTop: "50px",
  width: "100%",
}));

export const ProductOuterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  ":hover": {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    background: "rgba(0, 0, 0, 0.05)",
    boxShadow: "none",
  },
  borderRadius: "8px",
  boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.25)",
  background: "linear-gradient(190deg, #f8f8f8, #f0f0f0)",
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

export const ProductInnerContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
}));

export const ProductImageContainer = styled(Box)(({ theme }) => ({
  padding: "10px 30px 0px 30px",
  height: "140px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    height: "140px",
  },
}));

export const ProductTextContainer = styled(Box)(() => ({
  display: "flex",
  flex: "1",
  flexDirection: "column",
  padding: "10px 20px 0px 20px",
}));

export const ProductVehicleContainer = styled(Box)(() => ({
  height: "100%",
  justifyContent: "center",
  margin: "20px 0px",
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  background: Colors.black_alpha_7
}));

export const ProductPriceContainer = styled(Box)(() => ({
  display: "flex",
  alignSelf: "center",
  padding: "10px 0px"
}));

export const BottomPaginateContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "50px",
  width: "100%",
}));

export const BodyText = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-wrap",
}));

export const ProductCategoryText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    fontSize: "1.0rem"
  },
}));

export const ProductNameText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    fontSize: "1.2rem"
  },
}));