import { Box, Button, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../theme";

export const AppBarMenuButton = styled(IconButton)(({ theme }) => ({
  color: Colors.white,
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export const AppBarLogoButton = styled(Button)(({ theme }) => ({
  height: 50,
  [theme.breakpoints.down("md")]: {
    height: 40,
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export const AppBarLogo = styled("img")(({ src }) => ({
  src: { src },
  height: "100%",
  padding: 10,
  objectFit: "contain",
}));

export const AppBarPagesContainer = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
  alignItems: "space-evenly",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const AppBarPageButton = styled(Button)(({ page, theme }) => ({
  key: { page },
  width: 200,
  [theme.breakpoints.down("lg")]: {
    width: 160,
  },
  [theme.breakpoints.down("md")]: {
    width: 120,
  },
  color: Colors.white,
  ":hover": {
    background: Colors.white_alpha_10,
  },
}));

export const AppBarMenuButtonSpacer = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
  visibility: "hidden",
}));