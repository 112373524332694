import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../../theme";

export const FeaturesSection = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  background: Colors.light_grey,
  padding: "100px 80px",
  [theme.breakpoints.down("md")]: {
    padding: "80px 60px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "60px 40px",
  },
}));

export const PostHeader = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '2.5rem',    
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.25rem',    
    textAlign: "center",
  }
}));

export const PostContainer = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  padding: "40px 60px",
  [theme.breakpoints.down("md")]: {
    padding: "30px 60px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 60px",
  },
  background: Colors.light_grey,
}));

export const PostCategory = styled(Typography)(({ theme }) => ({
  letterSpacing: 0.2,
  color: Colors.grey,
  width: "100%",
  padding: "5px 0px",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    lineHeight: 1.15,
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    padding: "10px 0px",
  },
}));

export const PostModel = styled(Typography)(({ theme }) => ({
  letterSpacing: 0.2,
  color: Colors.black,
  width: "100%",
  padding: "0px 0px",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.3rem",
    lineHeight: 1.15,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
    textAlign: "center",
  },
}));

export const VideoSection = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  background: Colors.light_grey,
  padding: "100px 80px",
  [theme.breakpoints.down("md")]: {
    padding: "80px 80px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "60px 80px",
  },
}));

export const VideoHeader = styled(Typography)(({ theme }) => ({
  marginTop: "80px",
  [theme.breakpoints.down('md')]: {
    fontSize: '2.5rem',    
    marginTop: "60px",
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.25rem',    
    textAlign: "center",
    marginTop: "40px",
  }
}));

export const VideoOuterContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "0",
  paddingBottom: "56.25%",
  [theme.breakpoints.down("lg")]: {
    paddingBottom: "75%",
  },
  position: "relative",
}));

export const VideoInnerContainer = styled(Box)(({ theme }) => ({
  padding: "40px 60px",
  position: "absolute",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
  [theme.breakpoints.down("md")]: {
    padding: "30px 60px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 60px",
  },
}));
