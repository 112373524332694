export const allProducts = [
    {
        "id": 1,
        "sku": "33-3025",
        "name": "33-3025",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/1828000955/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mini",
                "model": "Cooper S",
                "engine": "2.0",
                "startYear": 2014,
                "endYear": 2022
            },
            {
                "make": "Mini",
                "model": "Cooper",
                "engine": "1.5",
                "startYear": 2014,
                "endYear": 2022
            },
            {
                "make": "Mini",
                "model": "Cooper John Cooper Works",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 2,
        "sku": "90-100",
        "name": "Proton Satria Neo/GEN-2 (Auto)",
        "category": 11,
        "subCategory": 0,
        "price": 90,
        "shopee": "https://shopee.com.my/product/119505399/14455885764/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria Neo",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2015
            },
            {
                "make": "Proton",
                "model": "GEN-2",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 3,
        "sku": "90-101",
        "name": "Proton Satria Neo/GEN-2 (Manual)",
        "category": 11,
        "subCategory": 0,
        "price": 90,
        "shopee": "https://shopee.com.my/product/119505399/14455885764/",
        "desc": [],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria Neo",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2015
            },
            {
                "make": "Proton",
                "model": "GEN-2",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 4,
        "sku": "HB.M663.SSP",
        "name": "S.Steel Bullet Resonator: 2.5″ x 9″",
        "category": 4,
        "subCategory": 0,
        "price": 160,
        "shopee": "https://shopee.com.my/product/119505399/1910203283/",
        "desc": [
            "Total length is 9 inches",
            "Inner diameter is 2.5 inches",
            "Pipe length from bullet resonator is 1.5 inches",
            "Resonator length is 6 inches",
            "Middle bullet sports resonators - 100 % 304 stainless steel; perforated with stainless and glass wool.",
            "Straight through direct Internal Design.",
            "Maximum gas flow, no restrictions at all."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 5,
        "sku": "HB.M1263.SSP",
        "name": "S.Steel Bullet Resonator: 2.5″ x 12″",
        "category": 4,
        "subCategory": 0,
        "price": 180,
        "shopee": "https://shopee.com.my/product/119505399/1909919180/",
        "desc": [
            "Total length is 15 inches",
            "Inner diameter is 2 inches",
            "Pipe length from bullet resonator is 1.5 inches",
            "Resonator length is 12 inches",
            "Middle bullet sports resonators.",
            "Straight through direct Internal Design.",
            "Maximum gas flow, no restrictions at all."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 6,
        "sku": "72-100",
        "name": "Lancer Evo",
        "category": 11,
        "subCategory": 0,
        "price": 60,
        "shopee": "https://shopee.com.my/product/119505399/17207782291/",
        "desc": [],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Lancer Evo I",
                "engine": "2.0T",
                "startYear": 1992,
                "endYear": 1994
            },
            {
                "make": "Mitsubishi",
                "model": "Lancer Evo II",
                "engine": "2.0T",
                "startYear": 1994,
                "endYear": 1995
            },
            {
                "make": "Mitsubishi",
                "model": "Lancer Evo III",
                "engine": "2.0T",
                "startYear": 1995,
                "endYear": 1996
            }
        ]
    },
    {
        "id": 7,
        "sku": "HB.EM 90-512-S-SS",
        "name": "Proton Satria GTi 4-2-1 Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/7184713548/",
        "desc": [
            "The 4-2-1 configuration allows better exhaust flow than standard for the engine, thus improving performance.",
            "4-2-1 improves low and mid-end torque/power curves"
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria GTi",
                "engine": "1.8",
                "startYear": 1998,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 8,
        "sku": "HB.EM 90-512A-S-SS",
        "name": "Proton Satria GTi 4-1 Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 980,
        "shopee": "https://shopee.com.my/product/119505399/7184713548/",
        "desc": [
            "The 4-1 configuration allows better exhaust flow than standard for the engine, thus improving performance.",
            "4-1 provides even more power gains for high rpm, thus suitable for manual transmission."
        ],
        "imageCount": 6,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria GTi",
                "engine": "1.8",
                "startYear": 1998,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 9,
        "sku": "SKU 567",
        "name": "Toyota Hilux Vigo",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6941337041/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Hilux",
                "engine": "Multi",
                "startYear": 2012,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 10,
        "sku": "SKU 830",
        "name": "Perodua Axia",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7611135203/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Axia",
                "engine": "1.0",
                "startYear": 2014,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 11,
        "sku": "33-5063",
        "name": "33-5063",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/4224616006/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "2.0",
                "startYear": 2017,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 12,
        "sku": "HB.EM 90-551A-S-SS",
        "name": "Proton CPS 4-1 Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 980,
        "shopee": "https://shopee.com.my/product/119505399/22134702481/",
        "desc": [
            "The 4-1 configuration allows better exhaust flow than standard for the engine, thus improving performance.",
            "4-1 provides even more power gains for high rpm, thus suitable for manual transmission."
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria Neo CPS",
                "engine": "1.6",
                "startYear": 2009,
                "endYear": 2015
            },
            {
                "make": "Proton",
                "model": "Waja CPS",
                "engine": "1.6",
                "startYear": 2008,
                "endYear": 2011
            },
            {
                "make": "Proton",
                "model": "GEN-2 CPS",
                "engine": "1.6",
                "startYear": 2008,
                "endYear": 2012
            },
            {
                "make": "Proton",
                "model": "Exora CPS",
                "engine": "1.6",
                "startYear": 2009,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 13,
        "sku": "HB.EM 90-551-S-SS",
        "name": "Proton CPS 4-2-1 Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/22134702481/",
        "desc": [
            "The 4-2-1 configuration allows better exhaust flow than standard for the engine, thus improving performance.",
            "4-2-1 improves low and mid-end torque/power curves"
        ],
        "imageCount": 6,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria Neo CPS",
                "engine": "1.6",
                "startYear": 2009,
                "endYear": 2015
            },
            {
                "make": "Proton",
                "model": "Waja CPS",
                "engine": "1.6",
                "startYear": 2008,
                "endYear": 2011
            },
            {
                "make": "Proton",
                "model": "GEN-2 CPS",
                "engine": "1.6",
                "startYear": 2008,
                "endYear": 2012
            },
            {
                "make": "Proton",
                "model": "Exora CPS",
                "engine": "1.6",
                "startYear": 2009,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 14,
        "sku": "HB.EM 90-550A-S-SS",
        "name": "Proton Non-CPS 4-1 Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 980,
        "shopee": "https://shopee.com.my/product/119505399/22134702481/",
        "desc": [
            "The 4-1 configuration allows better exhaust flow than standard for the engine, thus improving performance.",
            "4-1 provides even more power gains for high rpm, thus suitable for manual transmission."
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria Neo Non-CPS",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2015
            },
            {
                "make": "Proton",
                "model": "Waja Non-CPS",
                "engine": "Multi",
                "startYear": 2000,
                "endYear": 2011
            },
            {
                "make": "Proton",
                "model": "GEN-2 Non-CPS",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            },
            {
                "make": "Proton",
                "model": "Exora Non-CPS",
                "engine": "1.6",
                "startYear": 2009,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 15,
        "sku": "HB.EM 90-550-S-SS",
        "name": "Proton Non-CPS 4-2-1 Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/22134702481/",
        "desc": [
            "The 4-2-1 configuration allows better exhaust flow than standard for the engine, thus improving performance.",
            "4-2-1 improves low and mid-end torque/power curves"
        ],
        "imageCount": 5,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria Neo Non-CPS",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2015
            },
            {
                "make": "Proton",
                "model": "Waja Non-CPS",
                "engine": "Multi",
                "startYear": 2000,
                "endYear": 2011
            },
            {
                "make": "Proton",
                "model": "GEN-2 Non-CPS",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            },
            {
                "make": "Proton",
                "model": "Exora Non-CPS",
                "engine": "1.6",
                "startYear": 2009,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 17,
        "sku": "E-2296",
        "name": "E-2296",
        "category": 8,
        "subCategory": 0,
        "price": 430,
        "shopee": "https://shopee.com.my/product/119505399/5608896476/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Hilux Vigo",
                "engine": "2.5TD",
                "startYear": 2004,
                "endYear": 2015
            },
            {
                "make": "Toyota",
                "model": "Hilux Vigo",
                "engine": "3.0TD",
                "startYear": 2004,
                "endYear": 2015
            },
            {
                "make": "Toyota",
                "model": "Fortuner",
                "engine": "2.7",
                "startYear": 2004,
                "endYear": 2015
            },
            {
                "make": "Toyota",
                "model": "Fortuner",
                "engine": "3.0D",
                "startYear": 2004,
                "endYear": 2015
            },
            {
                "make": "Toyota",
                "model": "Innova",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 18,
        "sku": "33-5029",
        "name": "33-5029",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/4257562578/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Mustang",
                "engine": "2.3",
                "startYear": 2016,
                "endYear": 2023
            },
            {
                "make": "Ford",
                "model": "Mustang",
                "engine": "5.0",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 19,
        "sku": "",
        "name": "Proton Preve Intercooler Kit with Piping and Silicone Clamp",
        "category": 7,
        "subCategory": 0,
        "price": 1950,
        "shopee": "https://shopee.com.my/product/119505399/4543558056/",
        "desc": [],
        "imageCount": 5,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Preve",
                "engine": "1.6",
                "startYear": 2012,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 20,
        "sku": "99-5050",
        "name": "Cleaner & Oil Recharger Kit",
        "category": 9,
        "subCategory": 0,
        "price": 80,
        "shopee": "https://shopee.com.my/product/119505399/1824112366/",
        "desc": [
            "Squeeze Oil (277ml) & Spray Bottle Filter Cleaner (350ml)",
            "Original Genuine K&N Oil & Cleaner Service Kit.",
            "Requisite to be only be used to wash K&N air filter in order to qualify for the K&N Million Mile Limited Warranty.",
            "A four-step Cleaning & Maintenance Oil System designed to restore your K&N like-new.",
            "Simple to use with all Instructions on the box.",
            "Made in USA."
        ],
        "imageCount": 3,
        "vehicles": []
    },
    {
        "id": 21,
        "sku": "62-1513",
        "name": "Vent Air Filter/Breather (Flange ID: 1.375″ Height: 2.5″)",
        "category": 8,
        "subCategory": 0,
        "price": 130,
        "shopee": "https://shopee.com.my/product/119505399/7144703957/",
        "desc": [
            "The crankcase vent filter has a K&N embossed chrome-plated steel top with a black rubber base.",
            "The base is designed to clamp directly on to metal or plastic tubing where venting is needed.",
            "Constructed with a synthetic filter media it can be cleaned and reused and is covered under K&N’s One Year Limited Warranty.",
            "Flange internal diameter: 1.375 inches (35mm).",
            "Flange length: 0.625 inches (16mm).",
            "Filter height: 2.5 inches (64mm).",
            "Filter external diameter: 3 inches (76mm)."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 22,
        "sku": "62-1320",
        "name": "Vent Air Filter/Breather (Flange ID: 0.375″ Height: 1.5″)",
        "category": 8,
        "subCategory": 0,
        "price": 115,
        "shopee": "https://shopee.com.my/product/119505399/5532692496/",
        "desc": [
            "The crankcase vent filter has a K&N embossed chrome-plated steel top with a black rubber base.",
            "The base is designed to clamp directly on to metal or plastic tubing where venting is needed.",
            "Constructed with a synthetic filter media it can be cleaned and reused and is covered under K&N’s One Year Limited Warranty.",
            "Flange internal diameter: 0.375 inches (10mm).",
            "Flange length: 0.438 inches (11mm).",
            "Filter height: 1.5 inches (38mm).",
            "Filter external diameter: 2 inches (51mm)."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 23,
        "sku": "62-1340",
        "name": "Vent Air Filter/Breather (Flange ID: 0.625″ Height: 1.5″)",
        "category": 8,
        "subCategory": 0,
        "price": 115,
        "shopee": "https://shopee.com.my/product/119505399/6532692752/",
        "desc": [
            "The crankcase vent filter has a K&N embossed chrome-plated steel top with a black rubber base.",
            "The base is designed to clamp directly on to metal or plastic tubing where venting is needed.",
            "Constructed with a synthetic filter media it can be cleaned and reused and is covered under K&N’s One Year Limited Warranty.",
            "Flange internal diameter: 0.625 inches (16mm).",
            "Flange length: 0.438 inches (11mm).",
            "Filter height: 1.5 inches (38mm).",
            "Filter external diameter: 2 inches (51mm)."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 24,
        "sku": "62-1330",
        "name": "Vent Air Filter/Breather (Flange ID: 0.5″ Height: 1.5″)",
        "category": 8,
        "subCategory": 0,
        "price": 115,
        "shopee": "https://shopee.com.my/product/119505399/5732692646/",
        "desc": [
            "The crankcase vent filter has a K&N embossed chrome-plated steel top with a black rubber base.",
            "The base is designed to clamp directly on to metal or plastic tubing where venting is needed.",
            "Constructed with a synthetic filter media it can be cleaned and reused and is covered under K&N’s One Year Limited Warranty.",
            "Flange internal diameter: 0.5 inches (13mm).",
            "Flange length: 0.44 inches (11mm).",
            "Filter height: 1.5 inches (38mm).",
            "Filter external diameter: 2 inches (51mm)."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 25,
        "sku": "62-2470",
        "name": "Vent Air Filter/Breather (Flange ID: 0.313″ Height: 1.125″)",
        "category": 8,
        "subCategory": 0,
        "price": 115,
        "shopee": "https://shopee.com.my/product/119505399/5144705820/",
        "desc": [
            "The crankcase vent filter has a K&N embossed chrome-plated steel top with a black rubber base.",
            "The base is designed to clamp directly on to metal or plastic tubing where venting is needed.",
            "Constructed with a synthetic filter media it can be cleaned and reused and is covered under K&N’s One Year Limited Warranty.",
            "Flange internal diameter: 0.313 inches (8mm).",
            "Flange length: 0.438 inches (11mm).",
            "Filter height: 1.125 inches (29mm).",
            "Filter external diameter: 1.375 inches (35mm)."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 26,
        "sku": "33-3034",
        "name": "33-3034",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/7638117411/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "C 200",
                "engine": "2.0T",
                "startYear": 2014,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "C 250",
                "engine": "2.0T",
                "startYear": 2014,
                "endYear": 2018
            },
            {
                "make": "Mercedes Benz",
                "model": "C 300",
                "engine": "2.0T",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Mercedes Benz",
                "model": "E 200",
                "engine": "2.0T",
                "startYear": 2013,
                "endYear": 2017
            },
            {
                "make": "Mercedes Benz",
                "model": "E 250",
                "engine": "2.0T",
                "startYear": 2013,
                "endYear": 2017
            },
            {
                "make": "Mercedes Benz",
                "model": "E 300",
                "engine": "3.0",
                "startYear": 2016,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 27,
        "sku": "33-2922",
        "name": "33-2922",
        "category": 8,
        "subCategory": 0,
        "price": 320,
        "shopee": "https://shopee.com.my/product/119505399/4338353391/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Myvi",
                "engine": "1.3",
                "startYear": 2005,
                "endYear": 2016
            },
            {
                "make": "Perodua",
                "model": "Myvi",
                "engine": "1.5",
                "startYear": 2005,
                "endYear": 2016
            },
            {
                "make": "Perodua",
                "model": "Alza",
                "engine": "1.5",
                "startYear": 2009,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 28,
        "sku": "33-3059",
        "name": "33-3059",
        "category": 8,
        "subCategory": 0,
        "price": 335,
        "shopee": "https://shopee.com.my/product/119505399/2775146192/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Navara",
                "engine": "2.3D",
                "startYear": 2015,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 29,
        "sku": "33-2443",
        "name": "33-2443",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/8446154413/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Triton",
                "engine": "2.4D",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Mitsubishi",
                "model": "Pajero Sport",
                "engine": "2.4D",
                "startYear": 2016,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "RAV4",
                "engine": "2.5",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "Lexus",
                "model": "RX350",
                "engine": "3.5",
                "startYear": 2010,
                "endYear": 2022
            },
            {
                "make": "Lexus",
                "model": "NX200t",
                "engine": "2.0T",
                "startYear": 2014,
                "endYear": 2021
            },
            {
                "make": "Toyota",
                "model": "Harrier",
                "engine": "2.0T",
                "startYear": 2013,
                "endYear": 2020
            },
            {
                "make": "Toyota",
                "model": "GR Yaris",
                "engine": "1.6T",
                "startYear": 2020,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 30,
        "sku": "HB.WLM-64B",
        "name": "S.Steel Muffler with Silencer, Straight Tailpipe - Inlet ~2.5″",
        "category": 4,
        "subCategory": 0,
        "price": 240,
        "shopee": "https://shopee.com.my/product/119505399/1910458456/",
        "desc": [
            "Fully 304 stainless steel polished rear sports muffler.",
            "Muffler length is 13.8 Inches (350 mm).",
            "Inlet diameter is about 2.5 Inches.",
            "Body external diameter is about 5.5 Inches.",
            "Comes with 304 polished stainless tail-pipe.",
            "Straight through internal design to give maximum exhaust flow without restrictions.",
            "Very sporty low exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 31,
        "sku": "HB.WLM-66B",
        "name": "S.Steel Muffler with Silencer, Slant Tailpipe - Inlet ~2.5″",
        "category": 4,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1910439611/",
        "desc": [
            "Fully 304 stainless steel polished rear sports muffler.",
            "Muffler length is 13.8 Inches (350 mm).",
            "Inlet diameter is about 2.5 Inches.",
            "Body external diameter is about 5.5 Inches.",
            "Comes with 304 polished stainless tail-pipe.",
            "Straight through internal design to give maximum exhaust flow without restrictions.",
            "Very sporty low exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 32,
        "sku": "HB.WLM-32",
        "name": "S.Steel Muffler, Oval Tailpipe - Inlet ~2.0″",
        "category": 4,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1910260556/",
        "desc": [
            "Fully 304 stainless steel polished rear sports muffler.",
            "Muffler length is 14.6 Inches (370 mm).",
            "Inlet diameter is about 2 Inches.",
            "Body external diameter is about 8 x 5 Inches.",
            "Comes with 304 polished stainless tail-pipe.",
            "Straight through internal design to give maximum exhaust flow without restrictions.",
            "Very sporty low exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 33,
        "sku": "HB.WLM-84",
        "name": "S.Steel Muffler with Silencer, Slant Tailpipe - Inlet ~2.0″",
        "category": 4,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1910418436/",
        "desc": [
            "Fully 304 stainless steel polished rear sports muffler.",
            "Muffler length is 13.8 Inches (350 mm).",
            "Inlet diameter is about 2 Inches.",
            "Body external diameter is about 5.5 Inches.",
            "Comes with 304 polished stainless tail-pipe.",
            "Straight through internal design to give maximum exhaust flow without restrictions.",
            "Very sporty low exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 34,
        "sku": "HB.M160.2T.P",
        "name": "Aluminised Steel Muffler - Inlet 2.0″, Outlet 1.5″",
        "category": 4,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1915650668/",
        "desc": [
            "Length is 16 inches.",
            "Inlet diameter is 2 inches.",
            "Outlet diameter is 1.5 inches.",
            "Middle oval sports muffler.",
            "Straight through with reserve flow twice internal design.",
            "For maximum sound reduction with no restriction.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 35,
        "sku": "",
        "name": "Vacuum: 4mm",
        "category": 11,
        "subCategory": 0,
        "price": 6,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 36,
        "sku": "",
        "name": "Straight: 16mm",
        "category": 11,
        "subCategory": 0,
        "price": 26,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 37,
        "sku": "",
        "name": "Straight: 30mm",
        "category": 11,
        "subCategory": 0,
        "price": 28,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 38,
        "sku": "",
        "name": "Straight: 51mm",
        "category": 11,
        "subCategory": 0,
        "price": 32,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 39,
        "sku": "",
        "name": "Straight: 63.5mm",
        "category": 11,
        "subCategory": 0,
        "price": 36,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 40,
        "sku": "",
        "name": "Straight: 77mm",
        "category": 11,
        "subCategory": 0,
        "price": 38,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 41,
        "sku": "",
        "name": "Reducer: 1.5″ - 2.0″",
        "category": 11,
        "subCategory": 0,
        "price": 16,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 42,
        "sku": "",
        "name": "Reducer: 2.0″ - 2.5″",
        "category": 11,
        "subCategory": 0,
        "price": 20,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 43,
        "sku": "",
        "name": "Reducer: 2.0″ - 3.0″",
        "category": 11,
        "subCategory": 0,
        "price": 24,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 44,
        "sku": "",
        "name": "Reducer: 2.5″ - 3.0″",
        "category": 11,
        "subCategory": 0,
        "price": 28,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 45,
        "sku": "",
        "name": "Elbow: 135°, 2.5″",
        "category": 11,
        "subCategory": 0,
        "price": 38,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 46,
        "sku": "",
        "name": "Elbow: 135°, 2.0″",
        "category": 11,
        "subCategory": 0,
        "price": 34,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 47,
        "sku": "",
        "name": "Elbow: 90°, 2.5″",
        "category": 11,
        "subCategory": 0,
        "price": 28,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 48,
        "sku": "",
        "name": "Elbow: 90°, 2.0″",
        "category": 11,
        "subCategory": 0,
        "price": 25,
        "shopee": "https://shopee.com.my/product/119505399/23117710815",
        "desc": [
            "Suitable for turbo, intercooler, piping, radiator & vacuum applications.",
            "Price per 10cm",
            "Max length is 1000cm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 49,
        "sku": "33-2498",
        "name": "33-2498",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/6340057254/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "2.4",
                "startYear": 2013,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 50,
        "sku": "33-2360",
        "name": "33-2360",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/2333955611/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Vios",
                "engine": "1.5",
                "startYear": 2002,
                "endYear": 2012
            },
            {
                "make": "Toyota",
                "model": "Vios",
                "engine": "1.3",
                "startYear": 2008,
                "endYear": 2013
            },
            {
                "make": "Toyota",
                "model": "Yaris",
                "engine": "1.5",
                "startYear": 2005,
                "endYear": 2019
            },
            {
                "make": "Toyota",
                "model": "Yaris",
                "engine": "1.3",
                "startYear": 2008,
                "endYear": 2013
            },
            {
                "make": "Toyota",
                "model": "Harrier",
                "engine": "2.0",
                "startYear": 2014,
                "endYear": 2019
            },
            {
                "make": "Toyota",
                "model": "Harrier",
                "engine": "2.4",
                "startYear": 2014,
                "endYear": 2019
            },
            {
                "make": "Toyota",
                "model": "Harrier",
                "engine": "3.0",
                "startYear": 2014,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 51,
        "sku": "33-5050",
        "name": "33-5050",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/6692489619/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "HR-V",
                "engine": "1.5T",
                "startYear": 2018,
                "endYear": 2021
            },
            {
                "make": "Hyundai",
                "model": "Elantra",
                "engine": "Multi",
                "startYear": 2017,
                "endYear": 2020
            },
            {
                "make": "Kia",
                "model": "Forte",
                "engine": "2.0",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "Kia",
                "model": "Cerato",
                "engine": "Multi",
                "startYear": 2018,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 52,
        "sku": "33-5037",
        "name": "33-5037",
        "category": 8,
        "subCategory": 0,
        "price": 345,
        "shopee": "https://shopee.com.my/product/119505399/2271376827/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "HR-V",
                "engine": "1.8",
                "startYear": 2016,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 53,
        "sku": "E-2993",
        "name": "E-2993",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/5291559573/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Focus III",
                "engine": "Multi",
                "startYear": 2010,
                "endYear": 2018
            },
            {
                "make": "Ford",
                "model": "Focus ST",
                "engine": "2.0",
                "startYear": 2013,
                "endYear": 2018
            },
            {
                "make": "Ford",
                "model": "Focus RS",
                "engine": "2.3",
                "startYear": 2015,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 54,
        "sku": "33-2409",
        "name": "33-2409",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/10912304747/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Juke",
                "engine": "Multi",
                "startYear": 2010,
                "endYear": 2019
            },
            {
                "make": "Nissan",
                "model": "X-Trail",
                "engine": "2.5",
                "startYear": 2007,
                "endYear": 2013
            },
            {
                "make": "Nissan",
                "model": "Sylphy",
                "engine": "1.8",
                "startYear": 2013,
                "endYear": 2017
            },
            {
                "make": "Nissan",
                "model": "Sentra",
                "engine": "2.5",
                "startYear": 2007,
                "endYear": 2012
            },
            {
                "make": "Nissan",
                "model": "Sentra",
                "engine": "1.8",
                "startYear": 2012,
                "endYear": 2019
            },
            {
                "make": "Nissan",
                "model": "Latio",
                "engine": "1.2",
                "startYear": 2012,
                "endYear": 2015
            },
            {
                "make": "Nissan",
                "model": "March",
                "engine": "1.2",
                "startYear": 2010,
                "endYear": 2016
            },
            {
                "make": "Nissan",
                "model": "Almera",
                "engine": "1.2",
                "startYear": 2011,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 55,
        "sku": "33-2857",
        "name": "33-2857",
        "category": 8,
        "subCategory": 0,
        "price": 400,
        "shopee": "https://shopee.com.my/product/119505399/14491643201/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Touareg",
                "engine": "Multi",
                "startYear": 2002,
                "endYear": 2019
            },
            {
                "make": "Audi",
                "model": "Q7",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2015
            },
            {
                "make": "Porsche",
                "model": "Cayenne",
                "engine": "Multi",
                "startYear": 2002,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 56,
        "sku": "E-2657",
        "name": "E-2657",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/1828293168/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "X6",
                "engine": "3.0TD",
                "startYear": 2008,
                "endYear": 2010
            },
            {
                "make": "BMW",
                "model": "X5",
                "engine": "3.0TD",
                "startYear": 2000,
                "endYear": 2010
            },
            {
                "make": "BMW",
                "model": "325d",
                "engine": "3.0TD",
                "startYear": 2006,
                "endYear": 2010
            },
            {
                "make": "BMW",
                "model": "525d",
                "engine": "3.0TD",
                "startYear": 2009,
                "endYear": 2010
            },
            {
                "make": "BMW",
                "model": "530d",
                "engine": "3.0TD",
                "startYear": 1998,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 57,
        "sku": "HB.CS 50-394",
        "name": "Honda Prelude - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 160,
        "shopee": "https://shopee.com.my/product/119505399/2804344846/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Prelude",
                "engine": "Multi",
                "startYear": 1987,
                "endYear": 1991
            }
        ]
    },
    {
        "id": 58,
        "sku": "AX.BD 90-120-OS",
        "name": "Mitsubishi Lancer Oversized Front Brake Discs",
        "category": 1,
        "subCategory": 104,
        "price": 1380,
        "shopee": "https://shopee.com.my/product/119505399/4733639818/",
        "desc": [
            "Oversize cross-drilled and slotted performance brake discs for more than 30% better braking performance over standard.",
            "Highest grade cast iron to prevent warped discs.",
            "Lower max temperature generated compared to OE discs",
            "TUV tested.",
            "Larger calipers not required as brackets are provided so that standard brake calipers can be used."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Lancer",
                "engine": "2.0",
                "startYear": 2009,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 59,
        "sku": "AX.BD 90-121-OS",
        "name": "Mitsubishi Lancer Oversized Rear Brake Discs",
        "category": 1,
        "subCategory": 104,
        "price": 1025,
        "shopee": "https://shopee.com.my/product/119505399/6333638851/",
        "desc": [
            "Oversize cross-drilled and slotted performance brake discs for more than 30% better braking performance over standard.",
            "Highest grade cast iron to prevent warped discs.",
            "Lower max temperature generated compared to OE discs",
            "TUV tested.",
            "Larger calipers not required as brackets are provided so that standard brake calipers can be used."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Lancer",
                "engine": "2.0",
                "startYear": 2009,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 60,
        "sku": "",
        "name": "Mazda 2 Adjustable & Serviceable Sports Shocks",
        "category": 12,
        "subCategory": 0,
        "price": 2800,
        "shopee": "https://shopee.com.my/product/119505399/2861170205/",
        "desc": [
            "Adjustable height shock absorbers."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "2",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 61,
        "sku": "",
        "name": "Mitsubishi Lancer GT Adjustable & Serviceable Sports Shocks with DT1",
        "category": 12,
        "subCategory": 0,
        "price": 3600,
        "shopee": "https://shopee.com.my/product/119505399/2388797572/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "Hot Bits Top Mount - Pivot stainless steel bearing used for full articulation with no stress to spring and top mounts."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Lancer GT",
                "engine": "2.0",
                "startYear": 2008,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 62,
        "sku": "",
        "name": "Honda Civic FD Adjustable & Serviceable Sports Shocks with Canister",
        "category": 12,
        "subCategory": 0,
        "price": 3200,
        "shopee": "https://shopee.com.my/product/119505399/2847805892/",
        "desc": [
            "Adjustable height shock absorbers.",
            "External canister provides optimum gas volume and shock stroke.",
            "Hot Bits Top Mount - Pivot stainless steel bearing used for full articulation with no stress to spring and top mounts."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 63,
        "sku": "AX.BD 50-118-OS",
        "name": "Honda City/Jazz Oversized Rear Brake Discs",
        "category": 1,
        "subCategory": 104,
        "price": 1100,
        "shopee": "https://shopee.com.my/product/119505399/6933999502/",
        "desc": [
            "Oversize cross-drilled and slotted performance brake discs for more than 30% better braking performance over standard.",
            "Highest grade cast iron to prevent warped discs.",
            "Lower max temperature generated compared to OE discs",
            "TUV tested.",
            "Larger calipers not required as brackets are provided so that standard brake calipers can be used."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5",
                "startYear": 2014,
                "endYear": 2023
            },
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.5",
                "startYear": 2014,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 64,
        "sku": "",
        "name": "Suzuki Swift II Adjustable & Serviceable Sports Shocks with DT1 & Canister",
        "category": 12,
        "subCategory": 0,
        "price": 3600,
        "shopee": "https://shopee.com.my/product/119505399/2879096536/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "External canister provides optimum gas volume and shock stroke.",
            "Hot Bits Top Mount - Pivot stainless steel bearing used for full articulation with no stress to spring and top mounts."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Swift",
                "engine": "Multi",
                "startYear": 2010,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 65,
        "sku": "",
        "name": "Toyota Land Cruiser LC100 Adjustable & Serviceable Sports Shocks with DT1 & Canister",
        "category": 12,
        "subCategory": 0,
        "price": 2400,
        "shopee": "https://shopee.com.my/product/119505399/5708603065/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "External canister provides optimum gas volume and shock stroke."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser",
                "engine": "4.7",
                "startYear": 1999,
                "endYear": 2007
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser",
                "engine": "4.2D",
                "startYear": 1999,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 66,
        "sku": "",
        "name": "Toyota Camry Oversized Front Brake Discs (325mm)",
        "category": 1,
        "subCategory": 104,
        "price": 1025,
        "shopee": "https://shopee.com.my/product/119505399/4333641722/",
        "desc": [
            "Oversize cross-drilled and slotted performance brake discs for more than 30% better braking performance over standard.",
            "Highest grade cast iron to prevent warped discs.",
            "Lower max temperature generated compared to OE discs",
            "TUV tested.",
            "Larger calipers not required as brackets are provided so that standard brake calipers can be used."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 67,
        "sku": "",
        "name": "Toyota Camry Oversized Front Brake Discs (350mm)",
        "category": 1,
        "subCategory": 104,
        "price": 1280,
        "shopee": "https://shopee.com.my/product/119505399/7133641448/",
        "desc": [
            "Oversize cross-drilled and slotted performance brake discs for more than 30% better braking performance over standard.",
            "Highest grade cast iron to prevent warped discs.",
            "Lower max temperature generated compared to OE discs",
            "TUV tested.",
            "Larger calipers not required as brackets are provided so that standard brake calipers can be used."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 68,
        "sku": "AX.BD 50-120-OS",
        "name": "Toyota Vios Oversized Front Brake Discs",
        "category": 1,
        "subCategory": 104,
        "price": 1280,
        "shopee": "https://shopee.com.my/product/119505399/6434002180/",
        "desc": [
            "Oversize cross-drilled and slotted performance brake discs for more than 30% better braking performance over standard.",
            "Highest grade cast iron to prevent warped discs.",
            "Lower max temperature generated compared to OE discs",
            "TUV tested.",
            "Larger calipers not required as brackets are provided so that standard brake calipers can be used."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Vios",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 69,
        "sku": "",
        "name": "BMW E90 Adjustable & Serviceable Sports Shocks with DT1 & Help Springs",
        "category": 12,
        "subCategory": 0,
        "price": 3800,
        "shopee": "https://shopee.com.my/product/119505399/2847288324/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "Additional helper spring for optimum shocks stroke.",
            "Hot Bits Top Mount - Pivot stainless steel bearing used for full articulation with no stress to spring and top mounts."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "320i",
                "engine": "2.0",
                "startYear": 2005,
                "endYear": 2012
            },
            {
                "make": "BMW",
                "model": "323i",
                "engine": "2.5",
                "startYear": 2005,
                "endYear": 2010
            },
            {
                "make": "BMW",
                "model": "325i",
                "engine": "2.5",
                "startYear": 2005,
                "endYear": 2012
            },
            {
                "make": "BMW",
                "model": "320d",
                "engine": "2.0D",
                "startYear": 2007,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 70,
        "sku": "AEM 21-748C",
        "name": "Honda Jazz AEM 21-748C Cold Air Intake",
        "category": 99,
        "subCategory": 0,
        "price": 999,
        "shopee": "https://shopee.com.my/product/119505399/2769124392/",
        "desc": [
            "Increased horsepower",
            "Tuned and dyno-tested to improve acceleration and engine sound.",
            "Low restriction air filter intake.",
            "Easy installation with manual and parts provided.",
            "Air filter lasts up to 100,000 miles before cleaning is required (depending on driving conditions)."
        ],
        "imageCount": 4,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5",
                "startYear": 2014,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 71,
        "sku": "AX.BD 95-116-OS",
        "name": "Honda Civic FD Oversized Rear Brake Discs",
        "category": 1,
        "subCategory": 104,
        "price": 1280,
        "shopee": "https://shopee.com.my/product/119505399/4133823640/",
        "desc": [
            "Oversize cross-drilled and slotted performance brake discs for more than 30% better braking performance over standard.",
            "Highest grade cast iron to prevent warped discs.",
            "Lower max temperature generated compared to OE discs",
            "TUV tested.",
            "Larger calipers not required as brackets are provided so that standard brake calipers can be used."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 72,
        "sku": "",
        "name": "Daihatsu Terios Adjustable & Serviceable Sports Shocks with Canister",
        "category": 12,
        "subCategory": 0,
        "price": 2000,
        "shopee": "https://shopee.com.my/product/119505399/2847398309/",
        "desc": [
            "Adjustable height shock absorbers.",
            "External canister provides optimum gas volume and shock stroke."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Daihatsu",
                "model": "Terios"
            }
        ]
    },
    {
        "id": 73,
        "sku": "HB.ES 65-100R-SS",
        "name": "Lotus Elise Series 1 Exhaust System",
        "category": 5,
        "subCategory": 0,
        "price": 2980,
        "shopee": "https://shopee.com.my/product/119505399/22223247102/",
        "desc": [
            "Allows better exhaust air flow than standard for the engine, thus improving performance.",
            "Pipings are all mandrel-bent, resulting in maximum air flow.",
            "Dyno-tested to guarantee performance improvements.",
            "Made from stainless steel pipings and plated mild steel flanges.",
            "Stainless steel Hot Bits resonator and muffler are straight through designed, allowing for maximum air flow while ensuring a nice low-sporty exhaust note.",
            "Provides not only better power gains but throttle response as well."
        ],
        "imageCount": 4,
        "vehicles": [
            {
                "make": "Lotus",
                "model": "Elise Series 1",
                "engine": "1.8",
                "startYear": 1996,
                "endYear": 2001
            }
        ]
    },
    {
        "id": 74,
        "sku": "",
        "name": "Proton Inspira Adjustable & Serviceable Sports Shocks with DT1",
        "category": 12,
        "subCategory": 0,
        "price": 3600,
        "shopee": "https://shopee.com.my/product/119505399/2388739277/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "Hot Bits Top Mount - Pivot stainless steel bearing used for full articulation with no stress to spring and top mounts."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Inspira",
                "engine": "Multi",
                "startYear": 2010,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 75,
        "sku": "AX.BD 50-111-OS",
        "name": "Honda Accord Oversized Rear Brake Discs",
        "category": 1,
        "subCategory": 104,
        "price": 1025,
        "shopee": "https://shopee.com.my/product/119505399/6133821250/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "2.0",
                "startYear": 2002,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 76,
        "sku": "AX.BD 50-119-OS",
        "name": "Honda Accord Oversized Front Brake Discs",
        "category": 1,
        "subCategory": 104,
        "price": 1280,
        "shopee": "https://shopee.com.my/product/119505399/5634001197/",
        "desc": [
            "Oversize cross-drilled and slotted performance brake discs for more than 30% better braking performance over standard.",
            "Highest grade cast iron to prevent warped discs.",
            "Lower max temperature generated compared to OE discs",
            "TUV tested.",
            "Larger calipers not required as brackets are provided so that standard brake calipers can be used."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "2.0",
                "startYear": 2008,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 77,
        "sku": "33-3016",
        "name": "33-3016",
        "category": 8,
        "subCategory": 0,
        "price": 320,
        "shopee": "https://shopee.com.my/product/119505399/2329483932/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Aruz",
                "engine": "1.5",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "Vios",
                "engine": "1.5",
                "startYear": 2013,
                "endYear": 2023
            },
            {
                "make": "Mitsubishi",
                "model": "Attrage",
                "engine": "1.2",
                "startYear": 2013,
                "endYear": 2022
            },
            {
                "make": "Mitsubishi",
                "model": "Mirage",
                "engine": "1.2",
                "startYear": 2012,
                "endYear": 2021
            },
            {
                "make": "Perodua",
                "model": "Myvi",
                "engine": "Multi",
                "startYear": 2017,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 78,
        "sku": "33-5044",
        "name": "33-5044",
        "category": 8,
        "subCategory": 0,
        "price": 329,
        "shopee": "https://shopee.com.my/product/119505399/1828693608/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.5T",
                "startYear": 2016,
                "endYear": 2021
            },
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "1.5T",
                "startYear": 2017,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 79,
        "sku": "33-2278",
        "name": "33-2278",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/10915268455/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "6",
                "engine": "Multi",
                "startYear": 2002,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 80,
        "sku": "33-2170",
        "name": "33-2170",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/5038721112/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Altezza",
                "engine": "2.0",
                "startYear": 1999,
                "endYear": 2005
            },
            {
                "make": "Toyota",
                "model": "Altezza",
                "engine": "3.0",
                "startYear": 1999,
                "endYear": 2005
            },
            {
                "make": "Toyota",
                "model": "Airisto",
                "engine": "3.0",
                "startYear": 1997,
                "endYear": 2005
            },
            {
                "make": "Lexus",
                "model": "IS300",
                "engine": "3.0",
                "startYear": 2000,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 81,
        "sku": "HB.M1575.SSP",
        "name": "S.Steel Bullet Resonator: 3″ x 15″",
        "category": 4,
        "subCategory": 0,
        "price": 180,
        "shopee": "https://shopee.com.my/product/119505399/1915570558/",
        "desc": [
            "Bullet total length is 15 inches",
            "Inlet Diameter is 3 Inches",
            "Pipe length from bullet resonator is 1.5 inches",
            "Middle bullet sports resonators - 100% 304 stainless steel",
            "Straight through direct internal design",
            "Maximum gas flow, no restrictions at all."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 82,
        "sku": "HB.M1585.SSP",
        "name": "S.Steel Bullet Resonator: 3″ x 18″",
        "category": 4,
        "subCategory": 0,
        "price": 200,
        "shopee": "https://shopee.com.my/product/119505399/1915570558/",
        "desc": [
            "Bullet total length is 18 inches",
            "Inlet Diameter is 3 Inches",
            "Pipe length from bullet resonator is 1.5 inches",
            "Middle bullet sports resonators - 100% 304 stainless steel",
            "Straight through direct internal design",
            "Maximum gas flow, no restrictions at all."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 83,
        "sku": "33-3066",
        "name": "33-3066",
        "category": 8,
        "subCategory": 0,
        "price": 339,
        "shopee": "https://shopee.com.my/product/119505399/2275887454/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Axia",
                "engine": "1.0",
                "startYear": 2014,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 84,
        "sku": "33-3051",
        "name": "33-3051",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/1828749716/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "330i",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "BMW",
                "model": "320i",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "BMW",
                "model": "430i",
                "engine": "2.0",
                "startYear": 2016,
                "endYear": 2020
            },
            {
                "make": "BMW",
                "model": "420i",
                "engine": "2.0",
                "startYear": 2016,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 85,
        "sku": "33-2990",
        "name": "33-2990",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/7120733119/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "316i",
                "engine": "1.6",
                "startYear": 2012,
                "endYear": 2015
            },
            {
                "make": "BMW",
                "model": "316d",
                "engine": "2.0D",
                "startYear": 2012,
                "endYear": 2019
            },
            {
                "make": "BMW",
                "model": "318i",
                "engine": "1.5",
                "startYear": 2015,
                "endYear": 2019
            },
            {
                "make": "BMW",
                "model": "318d",
                "engine": "2.0D",
                "startYear": 2012,
                "endYear": 2020
            },
            {
                "make": "BMW",
                "model": "320i",
                "engine": "2.0",
                "startYear": 2012,
                "endYear": 2018
            },
            {
                "make": "BMW",
                "model": "320d",
                "engine": "2.0D",
                "startYear": 2012,
                "endYear": 2020
            },
            {
                "make": "BMW",
                "model": "328i",
                "engine": "2.0",
                "startYear": 2012,
                "endYear": 2016
            },
            {
                "make": "BMW",
                "model": "325d",
                "engine": "2.0D",
                "startYear": 2013,
                "endYear": 2019
            },
            {
                "make": "BMW",
                "model": "420i",
                "engine": "2.0",
                "startYear": 2013,
                "endYear": 2015
            },
            {
                "make": "BMW",
                "model": "420d",
                "engine": "2.0D",
                "startYear": 2013,
                "endYear": 2021
            },
            {
                "make": "BMW",
                "model": "418i",
                "engine": "1.5",
                "startYear": 2016,
                "endYear": 2020
            },
            {
                "make": "BMW",
                "model": "418d",
                "engine": "2.0D",
                "startYear": 2014,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 86,
        "sku": "AX.BD 50-118-OS",
        "name": "Honda City/Jazz Oversized Rear Brake Discs",
        "category": 1,
        "subCategory": 104,
        "price": 1025,
        "shopee": "https://shopee.com.my/product/119505399/3833920154/",
        "desc": [
            "Oversize cross-drilled and slotted performance brake discs for more than 30% better braking performance over standard.",
            "Highest grade cast iron to prevent warped discs.",
            "Lower max temperature generated compared to OE discs",
            "TUV tested.",
            "Larger calipers not required as brackets are provided so that standard brake calipers can be used."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.5",
                "startYear": 2007,
                "endYear": 2013
            },
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5",
                "startYear": 2007,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 87,
        "sku": "E-2345",
        "name": "E-2345",
        "category": 8,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/1838352382/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Isuzu",
                "model": "Invader",
                "engine": "2.8TD",
                "startYear": 1988,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 88,
        "sku": "33-3136",
        "name": "33-3136",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/8346160730/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "320i",
                "engine": "2.0",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "320d",
                "engine": "2.0D",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "330i",
                "engine": "2.0",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "330d",
                "engine": "3.0D",
                "startYear": 2020,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "340i",
                "engine": "3.0",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "340d",
                "engine": "3.0D",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "440i",
                "engine": "3.0",
                "startYear": 2021,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "Z4",
                "engine": "Multi",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "GR Supra",
                "engine": "2.0",
                "startYear": 2021,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "GR Supra",
                "engine": "3.0",
                "startYear": 2020,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 89,
        "sku": "HB.EM 20-206-SS",
        "name": "BMW E39 M52 Extractor - SS304 Flange",
        "category": 3,
        "subCategory": 0,
        "price": 2800,
        "shopee": "https://shopee.com.my/product/119505399/19976237576/",
        "desc": [
            "Hot Bits 3-1 Extractor/Headers for BMW E39 M52 Single Vanos",
            "The 3-1 configuration allows better exhaust flow than standard for the engine, thus improving performance."
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "BMW",
                "model": "520i",
                "engine": "2.0",
                "startYear": 1995,
                "endYear": 2000
            },
            {
                "make": "BMW",
                "model": "523i",
                "engine": "2.5",
                "startYear": 1995,
                "endYear": 2000
            },
            {
                "make": "BMW",
                "model": "528i",
                "engine": "2.8",
                "startYear": 1995,
                "endYear": 2001
            }
        ]
    },
    {
        "id": 90,
        "sku": "HB.EM 20-206-S-SS",
        "name": "BMW E39 M52 Extractor - Mild Steel Flange",
        "category": 3,
        "subCategory": 0,
        "price": 2600,
        "shopee": "https://shopee.com.my/product/119505399/19976237576/",
        "desc": [
            "Hot Bits 3-1 Extractor/Headers for BMW E39 M52 Single Vanos",
            "The 3-1 configuration allows better exhaust flow than standard for the engine, thus improving performance."
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "BMW",
                "model": "520i",
                "engine": "2.0",
                "startYear": 1995,
                "endYear": 2000
            },
            {
                "make": "BMW",
                "model": "523i",
                "engine": "2.5",
                "startYear": 1995,
                "endYear": 2000
            },
            {
                "make": "BMW",
                "model": "528i",
                "engine": "2.8",
                "startYear": 1995,
                "endYear": 2001
            }
        ]
    },
    {
        "id": 91,
        "sku": "",
        "name": "Mitsubishi Pajero/Triton Adjustable & Serviceable Sports Shocks with Canister",
        "category": 12,
        "subCategory": 0,
        "price": 3400,
        "shopee": "https://shopee.com.my/product/119505399/5408605064/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "External canister provides optimum gas volume and shock stroke.",
            "Remote canisters allow you to install at multiple points for ease of adjusting and aesthetics."
        ],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Pajero Sport",
                "engine": "2.5TD",
                "startYear": 2009,
                "endYear": 2015
            },
            {
                "make": "Mitsubishi",
                "model": "Triton",
                "engine": "2.5TD",
                "startYear": 2005,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 92,
        "sku": "HB.EM 85-302-S",
        "name": "Perodua Myvi III 4-2-1 Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 580,
        "shopee": "https://shopee.com.my/product/119505399/9256849009/",
        "desc": [
            "The 4-2-1 configuration allows better exhaust flow than standard for the engine, thus improving performance.",
            "4-2-1 improves low and mid-end torque/power curves"
        ],
        "imageCount": 4,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Myvi",
                "engine": "Multi",
                "startYear": 2017,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 93,
        "sku": "",
        "name": "Suzuki Swift Sport Adjustable & Serviceable Sports Shocks",
        "category": 12,
        "subCategory": 0,
        "price": 2200,
        "shopee": "https://shopee.com.my/product/119505399/4208597289/",
        "desc": [
            "Adjustable height shock absorbers."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Swift Sport",
                "engine": "1.6",
                "startYear": 2013,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 94,
        "sku": "HB.EM 10-139-S",
        "name": "Alfa 147 1.7 Boxer Engine Chrome Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/20602082905/",
        "desc": [
            "Complete chrome unit for maximum air flow."
        ],
        "imageCount": 4,
        "vehicles": [
            {
                "make": "Alfa Romeo",
                "model": "147",
                "engine": "Multi",
                "startYear": 2000,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 95,
        "sku": "HB.EM 10-144-S",
        "name": "Alfa 155 Twin Spark 8V 4-2-1 Chrome Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 680,
        "shopee": "https://shopee.com.my/product/119505399/13489021494/",
        "desc": [
            "Complete chrome unit for maximum air flow."
        ],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Alfa Romeo",
                "model": "155",
                "engine": "1.8",
                "startYear": 1992,
                "endYear": 1998
            },
            {
                "make": "Alfa Romeo",
                "model": "155",
                "engine": "2.0",
                "startYear": 1992,
                "endYear": 1998
            }
        ]
    },
    {
        "id": 97,
        "sku": "",
        "name": "Suzuki Swift III Adjustable & Serviceable Sports Shocks with Canister & DT1",
        "category": 12,
        "subCategory": 0,
        "price": 3200,
        "shopee": "https://shopee.com.my/product/119505399/7206976099/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Swift",
                "engine": "Multi",
                "startYear": 2012,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 98,
        "sku": "",
        "name": "Toyota Yaris II Adjustable & Serviceable Sports Shocks with Canister & DT1",
        "category": 12,
        "subCategory": 0,
        "price": 3200,
        "shopee": "https://shopee.com.my/product/119505399/6108602021/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "External canister provides optimum gas volume and shock stroke.",
            "Comes with coil over springs."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Yaris",
                "engine": "1.5",
                "startYear": 2005,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 99,
        "sku": "HB.EM 20-381-C",
        "name": "BMW E30/E36 4-2-1 Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/21371613381/",
        "desc": [
            "The 4-2-1 configuration allows better exhaust flow than standard for the engine, thus improving performance.",
            "4-2-1 improves low and mid-end torque/power curves"
        ],
        "imageCount": 4,
        "vehicles": [
            {
                "make": "BMW",
                "model": "316i",
                "engine": "1.6",
                "startYear": 1987,
                "endYear": 1994
            },
            {
                "make": "BMW",
                "model": "318i",
                "engine": "1.8",
                "startYear": 1987,
                "endYear": 1994
            }
        ]
    },
    {
        "id": 100,
        "sku": "",
        "name": "Perodua Myvi I Adjustable & Serviceable Sports Shocks with Canister",
        "category": 12,
        "subCategory": 0,
        "price": 2200,
        "shopee": "https://shopee.com.my/product/119505399/5606970031/",
        "desc": [
            "Adjustable height shock absorbers.",
            "External canister provides optimum gas volume and shock stroke.",
            "Springs are cold wound for reliability."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Myvi",
                "engine": "1.0",
                "startYear": 2005,
                "endYear": 2011
            },
            {
                "make": "Perodua",
                "model": "Myvi",
                "engine": "1.3",
                "startYear": 2005,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 101,
        "sku": "",
        "name": "Honda Civic ES Adjustable & Serviceable Sports Shocks with DT",
        "category": 12,
        "subCategory": 0,
        "price": 2800,
        "shopee": "https://shopee.com.my/product/119505399/2770745800/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Oversized 58mm shock housing.",
            "Springs are cold wound for reliability."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.7",
                "startYear": 2001,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 103,
        "sku": "",
        "name": "Toyota LC 100 Adjustable & Serviceable Sports Shocks with Canister & DT1",
        "category": 12,
        "subCategory": 0,
        "price": 3200,
        "shopee": "https://shopee.com.my/product/119505399/6007012483/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "External canister provides optimum gas volume and shock stroke."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser",
                "engine": "4.2TD",
                "startYear": 1998,
                "endYear": 2007
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser",
                "engine": "4.7",
                "startYear": 1998,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 104,
        "sku": "",
        "name": "Suzuki Swift III Adjustable & Serviceable Sports Shocks",
        "category": 12,
        "subCategory": 0,
        "price": 2200,
        "shopee": "https://shopee.com.my/product/119505399/4708167061/",
        "desc": [
            "Adjustable height shock absorbers."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Swift",
                "engine": "Multi",
                "startYear": 2012,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 105,
        "sku": "",
        "name": "Proton Exora Adjustable & Serviceable Sports Shocks with DT1",
        "category": 12,
        "subCategory": 0,
        "price": 2400,
        "shopee": "https://shopee.com.my/product/119505399/7708166182/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Exora",
                "engine": "1.6",
                "startYear": 2012,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 106,
        "sku": "",
        "name": "Toyota LC 200 Adjustable & Serviceable Sports Shocks with Canister & DT1",
        "category": 12,
        "subCategory": 0,
        "price": 3200,
        "shopee": "https://shopee.com.my/product/119505399/5207348693/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "External canister provides optimum gas volume and shock stroke.",
            "Springs are cold wound for reliability."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser",
                "engine": "4.5TD",
                "startYear": 2007,
                "endYear": 2017
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser",
                "engine": "4.6",
                "startYear": 2012,
                "endYear": 2019
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser",
                "engine": "4.7",
                "startYear": 2007,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 107,
        "sku": "RSI-54-309",
        "name": "Isuzu D-Max Adjustable & Serviceable Sports Shocks 4WD Front Inverted Coil-Over",
        "category": 12,
        "subCategory": 0,
        "price": 6800,
        "shopee": "https://shopee.com.my/product/119505399/1922855855/",
        "desc": [
            "Isuzu D-Max 2 - 2.5/3.0 4WD (2012 onwards) Hot Bits Sports Suspensions/Absorber 4WD Front Inverted Coil-Over Set",
            "Inverted Front Shocks with adjustable coil-spring & Alloy Top Mounts.",
            "All Hot Bits Shocks are mono-tube gas shocks with 60 mm pistons and are fully serviceable and tuneable.",
            "External canister provide optimum gas volume and shock stroke.",
            "Coil spring are cold wound chrome silizium springs.",
            "1-way damping adjustable shocks come with fine 40 clicks compression and rebound adjustment."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Isuzu",
                "model": "D-Max",
                "engine": "Multi",
                "startYear": 2012,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 108,
        "sku": "",
        "name": "Mazda 6 4-2-1 Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 950,
        "shopee": "https://shopee.com.my/product/119505399/17825941616/",
        "desc": [
            "The 4-2-1 configuration allows better exhaust flow than standard for the engine, thus improving performance.",
            "4-2-1 improves low and mid-end torque/power curves"
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "6",
                "engine": "2.5",
                "startYear": 2008,
                "endYear": 2012
            },
            {
                "make": "Mazda",
                "model": "6",
                "engine": "2.0",
                "startYear": 2008,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 109,
        "sku": "",
        "name": "Honda Jazz/City/Freed Adjustable & Serviceable Sports Shocks",
        "category": 12,
        "subCategory": 0,
        "price": 2200,
        "shopee": "https://shopee.com.my/product/119505399/5507374919/",
        "desc": [
            "Adjustable height shock absorbers."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5",
                "startYear": 2007,
                "endYear": 2013
            },
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.5",
                "startYear": 2008,
                "endYear": 2015
            },
            {
                "make": "Honda",
                "model": "Freed",
                "engine": "1.5",
                "startYear": 2010,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 110,
        "sku": "",
        "name": "Toyota Harrier / Lexus RX 300/350 (4WD) Adjustable & Serviceable Sports Shocks with Canister and DT1",
        "category": 12,
        "subCategory": 0,
        "price": 2600,
        "shopee": "https://shopee.com.my/product/119505399/4507371042/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "External canister provides optimum gas volume and shock stroke."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Lexus",
                "model": "RX 300",
                "engine": "2.0",
                "startYear": 2003,
                "endYear": 2008
            },
            {
                "make": "Lexus",
                "model": "RX 350",
                "engine": "3.5",
                "startYear": 2003,
                "endYear": 2008
            },
            {
                "make": "Toyota",
                "model": "Harrier",
                "engine": "Multi",
                "startYear": 2003,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 111,
        "sku": "",
        "name": "Toyota Vios II/Yaris II Adjustable & Serviceable Sports Shocks with Canister & DT1",
        "category": 12,
        "subCategory": 0,
        "price": 3200,
        "shopee": "https://shopee.com.my/product/119505399/3119843015/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "External canister provides optimum gas volume and shock stroke."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Vios",
                "engine": "1.5",
                "startYear": 2007,
                "endYear": 2012
            },
            {
                "make": "Toyota",
                "model": "Yaris",
                "engine": "1.5",
                "startYear": 2006,
                "endYear": 2011
            },
            {
                "make": "Toyota",
                "model": "Vios",
                "engine": "1.5",
                "startYear": 2007,
                "endYear": 2012
            },
            {
                "make": "Toyota",
                "model": "Yaris",
                "engine": "1.5",
                "startYear": 2006,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 112,
        "sku": "",
        "name": "Honda CR-V Adjustable & Serviceable Sports Shocks",
        "category": 12,
        "subCategory": 0,
        "price": 3200,
        "shopee": "https://shopee.com.my/product/119505399/3707477219/",
        "desc": [
            "Adjustable height shock absorbers."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "2.0",
                "startYear": 2007,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 114,
        "sku": "",
        "name": "Suzuki Swift I Adjustable & Serviceable Sports Shocks",
        "category": 12,
        "subCategory": 0,
        "price": 2800,
        "shopee": "https://shopee.com.my/product/119505399/6806974024/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Springs are cold wound for reliability.",
            "Standard replacement - fit OE springs or with Hot Bits sports lowered springs.",
            "Sports & Heavy Duty damping settings."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Swift",
                "engine": "1.5",
                "startYear": 2005,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 115,
        "sku": "",
        "name": "Toyota Vios I Adjustable & Serviceable Sports Shocks with DT1",
        "category": 12,
        "subCategory": 0,
        "price": 2800,
        "shopee": "https://shopee.com.my/product/119505399/6608599101/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Vios",
                "engine": "1.5",
                "startYear": 2004,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 116,
        "sku": "",
        "name": "Toyota Harrier / Lexus RX 300/350 (2WD) Adjustable & Serviceable Sports Shocks with DT1",
        "category": 12,
        "subCategory": 0,
        "price": 2600,
        "shopee": "https://shopee.com.my/product/119505399/4707369322/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "Springs are cold wound for reliability."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Lexus",
                "model": "RX 300",
                "engine": "2.0",
                "startYear": 2003,
                "endYear": 2008
            },
            {
                "make": "Lexus",
                "model": "RX 350",
                "engine": "3.5",
                "startYear": 2003,
                "endYear": 2008
            },
            {
                "make": "Toyota",
                "model": "Harrier",
                "engine": "Multi",
                "startYear": 2003,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 117,
        "sku": "RSI-95-322",
        "name": "Toyota Hilux Revo Adjustable & Serviceable Sports Shocks 4WD Front Inverted Coil-Over",
        "category": 12,
        "subCategory": 0,
        "price": 6800,
        "shopee": "https://shopee.com.my/product/119505399/2384130087/",
        "desc": [
            "Toyota Hilux Revo 2.5TD/2.8TD (2015 onwards) 4WD Hot Bits Fully Adjustable Sports Suspensions/Absorber 4WD Front Inverted Coil-Over Set.",
            "Inverted Front Shocks with adjustable coil-spring & Alloy Top Mounts.",
            "All Hot Bits Shocks are mono-tube gas shocks with 60 mm pistons and are fully serviceable and tuneable.",
            "External canister provide optimum gas volume and shock stroke.",
            "Coil spring are cold wound chrome silizium springs.",
            "1-way damping adjustable shocks come with fine 40 clicks compression and rebound adjustment."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Hilux Revo",
                "engine": "2.5TD",
                "startYear": 2016,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "Hilux Revo",
                "engine": "2.8TD",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 118,
        "sku": "",
        "name": "Perodua Myvi II Adjustable & Serviceable Sports Shocks with Canister",
        "category": 12,
        "subCategory": 0,
        "price": 2200,
        "shopee": "https://shopee.com.my/product/119505399/4406968381/",
        "desc": [
            "Adjustable height shock absorbers.",
            "External canister provides optimum gas volume and shock stroke.",
            "Springs are cold wound for reliability."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Myvi",
                "engine": "Multi",
                "startYear": 2012,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 119,
        "sku": "HB.EM 90-920-S-SS",
        "name": "Mitsubishi Lancer / Proton Inspira 4-2-1 Extractor",
        "category": 3,
        "subCategory": 0,
        "price": 950,
        "shopee": "https://shopee.com.my/product/119505399/16125891115/",
        "desc": [
            "The 4-2-1 configuration allows better exhaust flow than standard for the engine, thus improving performance.",
            "4-2-1 improves low and mid-end torque/power curves"
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Inspira",
                "engine": "Multi",
                "startYear": 2010,
                "endYear": 2015
            },
            {
                "make": "Mitsubishi",
                "model": "Lancer",
                "engine": "Multi",
                "startYear": 2008,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 120,
        "sku": "",
        "name": "Proton Preve Adjustable & Serviceable Sports Shocks",
        "category": 12,
        "subCategory": 0,
        "price": 1200,
        "shopee": "https://shopee.com.my/product/119505399/4687285600/",
        "desc": [
            "Front only",
            "External canisters for extended and larger internal gas chamber.",
            "Adjustable height shock absorbers."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Preve",
                "engine": "1.6",
                "startYear": 2012,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 121,
        "sku": "",
        "name": "Toyota Vios II/Yaris II Adjustable & Serviceable Sports Shocks with Canister & DT1",
        "category": 12,
        "subCategory": 0,
        "price": 3200,
        "shopee": "https://shopee.com.my/product/119505399/3119843015/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Softness & hardness adjustable. 1-way damping with 40 fine clicks of compression and rebound adjustments.",
            "External canister provides optimum gas volume and shock stroke."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Vios",
                "engine": "1.5",
                "startYear": 2007,
                "endYear": 2012
            },
            {
                "make": "Toyota",
                "model": "Yaris",
                "engine": "1.5",
                "startYear": 2006,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 123,
        "sku": "33-2387",
        "name": "33-2387",
        "category": 8,
        "subCategory": 0,
        "price": 460,
        "shopee": "https://shopee.com.my/product/119505399/2330793303/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser",
                "engine": "4.5TD",
                "startYear": 2007,
                "endYear": 2017
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser",
                "engine": "4.6",
                "startYear": 2012,
                "endYear": 2019
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser",
                "engine": "4.7",
                "startYear": 2007,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 124,
        "sku": "RU-3020",
        "name": "RU-3020 Universal (Flange ID: 5″ Height: 9″)",
        "category": 8,
        "subCategory": 0,
        "price": 620,
        "shopee": "https://shopee.com.my/product/119505399/17987309696/",
        "desc": [
            "Round shape air filter.",
            "Flange internal diameter: 5 inches (127mm).",
            "Flange length: 1 inch (25mm).",
            "Filter height: 9 inches (229mm).",
            "Filter external diameter: 6.5 inches (165mm)."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 125,
        "sku": "99-0533",
        "name": "8 Ounce Squeeze Bottle Air Filter Oil",
        "category": 9,
        "subCategory": 0,
        "price": 55,
        "shopee": "https://shopee.com.my/product/119505399/4308576138/",
        "desc": [
            "K&N Air Filter Oil is the only oil specially formulated to work in combination with the cotton fabric in K&N air filter elements providing a superior air filtration system. ",
            "When used as directed, it quickly penetrates the filter pleats, where it remains suspended in the cotton fabric.",
            "Saturation is maintained until the element is cleaned with K&N Air Filter Cleaner."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 126,
        "sku": "99-0606",
        "name": "12 Ounce Pump Spray Air Filter Cleaner",
        "category": 9,
        "subCategory": 0,
        "price": 60,
        "shopee": "https://shopee.com.my/product/119505399/14947316612/",
        "desc": [
            "K&N Air Filter Cleaner is the only cleaner recommended to clean K&N cotton air filter elements.",
            "Brings your K&N oiled cotton air filter to like new condition.",
            "K&N Power Kleen works to dissolve the dirt build up and old filter oil, and can be washed away with water.",
            "The use of other cleaning solutions can void the air filter's warranty.",
            "It is so good at cleaning greasy dirt and grime that it can also be used as an industrial strength degreaser.",
            "Complete cleaning instructions are on bottle."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 127,
        "sku": "33-2031-2",
        "name": "33-2031-2",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/3740259493/",
        "desc": [
            "For 350Z models requiring 2 pieces, please contact us as it is a different part number."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "350Z",
                "engine": "3.5",
                "startYear": 2003,
                "endYear": 2006
            },
            {
                "make": "Nissan",
                "model": "Murano",
                "engine": "Multi",
                "startYear": 2003,
                "endYear": 2023
            },
            {
                "make": "Infiniti",
                "model": "QX60",
                "engine": "3.5",
                "startYear": 2014,
                "endYear": 2021
            },
            {
                "make": "Nissan",
                "model": "Pathfinder",
                "engine": "3.5",
                "startYear": 2013,
                "endYear": 2020
            },
            {
                "make": "Nissan",
                "model": "Altima",
                "engine": "3.5",
                "startYear": 2005,
                "endYear": 2018
            },
            {
                "make": "Nissan",
                "model": "Elgrand",
                "engine": "3.5",
                "startYear": 2000,
                "endYear": 2017
            },
            {
                "make": "Nissan",
                "model": "Elgrand",
                "engine": "2.5",
                "startYear": 2005,
                "endYear": 2017
            },
            {
                "make": "Nissan",
                "model": "Teana",
                "engine": "2.5",
                "startYear": 2003,
                "endYear": 2013
            },
            {
                "make": "Nissan",
                "model": "Teana",
                "engine": "3.5",
                "startYear": 2004,
                "endYear": 2012
            },
            {
                "make": "Nissan",
                "model": "X-Trail",
                "engine": "2.5",
                "startYear": 2003,
                "endYear": 2014
            },
            {
                "make": "Nissan",
                "model": "Navara",
                "engine": "3.0D",
                "startYear": 2004,
                "endYear": 2005
            },
            {
                "make": "Nissan",
                "model": "Navara",
                "engine": "2.4",
                "startYear": 1992,
                "endYear": 2005
            },
            {
                "make": "Nissan",
                "model": "Navara",
                "engine": "3",
                "startYear": 1992,
                "endYear": 2004
            },
            {
                "make": "Nissan",
                "model": "Cefiro",
                "engine": "2.5",
                "startYear": 1992,
                "endYear": 2002
            },
            {
                "make": "Nissan",
                "model": "Cefiro",
                "engine": "2",
                "startYear": 1988,
                "endYear": 2002
            },
            {
                "make": "Nissan",
                "model": "Maxima",
                "engine": "Multi",
                "startYear": 1982,
                "endYear": 2023
            },
            {
                "make": "Nissan",
                "model": "Serena",
                "engine": "Multi",
                "startYear": 1992,
                "endYear": 2001
            },
            {
                "make": "Suzuki",
                "model": "Swift Sport",
                "engine": "1.6",
                "startYear": 2012,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 128,
        "sku": "33-2304",
        "name": "33-2304",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/6107350304/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Subaru",
                "model": "WRX",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2021
            },
            {
                "make": "Subaru",
                "model": "WRX STI",
                "engine": "2.5",
                "startYear": 2012,
                "endYear": 2018
            },
            {
                "make": "Subaru",
                "model": "XV",
                "engine": "2.0",
                "startYear": 2012,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 129,
        "sku": "",
        "name": "Suzuki Swift",
        "category": 11,
        "subCategory": 0,
        "price": 60,
        "shopee": "https://shopee.com.my/product/119505399/18172743007/",
        "desc": [],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Swift",
                "engine": "1.3",
                "startYear": 2004,
                "endYear": 2010
            },
            {
                "make": "Suzuki",
                "model": "Swift",
                "engine": "1.5",
                "startYear": 2004,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 130,
        "sku": "",
        "name": "Subaru WRX STI GC8",
        "category": 11,
        "subCategory": 0,
        "price": 90,
        "shopee": "https://shopee.com.my/product/119505399/23722692296/",
        "desc": [],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Subaru",
                "model": "Impreza WRX",
                "engine": "Multi",
                "startYear": 1992,
                "endYear": 2000
            }
        ]
    },
    {
        "id": 131,
        "sku": "HB.ES 25-100D-SS",
        "name": "Subaru WRX STI GC8 Downpipe",
        "category": 5,
        "subCategory": 0,
        "price": 1150,
        "shopee": "https://shopee.com.my/product/119505399/21372664232/",
        "desc": [
            "This downpipe unit allows better exhaust air flow than standard for the engine, thus improving performance.",
            "The pipings on this downpipe are all mandrel-bent with 2.5 inch pipings, resulting in maximum air flow.",
            "Dyno-tested to guarantee performance improvements.",
            "Hot Bits Downpipe will not only provide better power gains but throttle response as well.",
            "This downpipe is made from stainless steel ss304 pipings and mild steel flanges.",
            "Brackets included for original heatshield to bolt on back."
        ],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Subaru",
                "model": "Impreza WRX",
                "engine": "Multi",
                "startYear": 1992,
                "endYear": 2000
            }
        ]
    },
    {
        "id": 132,
        "sku": "33-3015",
        "name": "33-3015",
        "category": 8,
        "subCategory": 0,
        "price": 349,
        "shopee": "https://shopee.com.my/product/119505399/7152333465/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Lancer",
                "engine": "2.0",
                "startYear": 2013,
                "endYear": 2019
            },
            {
                "make": "Mitsubishi",
                "model": "ASX",
                "engine": "Multi",
                "startYear": 2012,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 133,
        "sku": "AX.BD 95-122",
        "name": "Toyota Mark X Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 800,
        "shopee": "https://shopee.com.my/product/119505399/4803365870/",
        "desc": [
            "AXT High Quality Toyota Mark X 2.5 V6 (05~09) Slotted Front Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Mark X",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 134,
        "sku": "",
        "name": "Gasket: 55mm(ID), 90mm(L)",
        "category": 99,
        "subCategory": 0,
        "price": 5,
        "shopee": "https://shopee.com.my/product/119505399/22721651514/",
        "desc": [
            "Universal Exhaust Gasket.",
            "Fits Toyota/Honda/Proton/Perodua and other cars.",
            "All gaskets are ready stock.",
            "1.8mm thickness (allow tolerance)."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 135,
        "sku": "",
        "name": "Gasket: 65mm(ID), 80mm(L)",
        "category": 99,
        "subCategory": 0,
        "price": 5,
        "shopee": "https://shopee.com.my/product/119505399/22721651514/",
        "desc": [
            "Universal Exhaust Gasket.",
            "Fits Toyota/Honda/Proton/Perodua and other cars.",
            "All gaskets are ready stock.",
            "1.8mm thickness (allow tolerance)."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 136,
        "sku": "",
        "name": "Gasket: 50mm(ID), 80mm(L)",
        "category": 99,
        "subCategory": 0,
        "price": 5,
        "shopee": "https://shopee.com.my/product/119505399/22721651514/",
        "desc": [
            "Universal Exhaust Gasket.",
            "Fits Toyota/Honda/Proton/Perodua and other cars.",
            "All gaskets are ready stock.",
            "1.8mm thickness (allow tolerance)."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 137,
        "sku": "",
        "name": "Gasket: 55mm(ID), 97mm(L)",
        "category": 99,
        "subCategory": 0,
        "price": 6,
        "shopee": "https://shopee.com.my/product/119505399/22721651514/",
        "desc": [
            "Universal Exhaust Gasket.",
            "Fits Toyota/Honda/Proton/Perodua and other cars.",
            "All gaskets are ready stock.",
            "1.8mm thickness (allow tolerance)."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 138,
        "sku": "",
        "name": "Gasket: 65mm(ID), 100mm(L)",
        "category": 99,
        "subCategory": 0,
        "price": 6,
        "shopee": "https://shopee.com.my/product/119505399/22721651514/",
        "desc": [
            "Universal Exhaust Gasket.",
            "Fits Toyota/Honda/Proton/Perodua and other cars.",
            "All gaskets are ready stock.",
            "1.8mm thickness (allow tolerance)."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 139,
        "sku": "33-2480",
        "name": "33-2480",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/6413377191/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "3 (Skyactiv)",
                "startYear": 2012,
                "endYear": 2013
            },
            {
                "make": "Mazda",
                "model": "3",
                "engine": "2.0",
                "startYear": 2014,
                "endYear": 2018
            },
            {
                "make": "Mazda",
                "model": "6",
                "engine": "2.5",
                "startYear": 2011,
                "endYear": 2021
            },
            {
                "make": "Mazda",
                "model": "6",
                "engine": "2.0",
                "startYear": 2013,
                "endYear": 2020
            },
            {
                "make": "Mazda",
                "model": "CX-5",
                "engine": "2.5",
                "startYear": 2013,
                "endYear": 2023
            },
            {
                "make": "Mazda",
                "model": "CX-5",
                "engine": "2.0",
                "startYear": 2012,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 140,
        "sku": "33-3078",
        "name": "33-3078",
        "category": 8,
        "subCategory": 0,
        "price": 490,
        "shopee": "https://shopee.com.my/product/119505399/15582547428/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Porsche",
                "model": "718 Cayman",
                "engine": "Multi",
                "startYear": 2016,
                "endYear": 2022
            },
            {
                "make": "Porsche",
                "model": "718 Boxster",
                "engine": "Multi",
                "startYear": 2016,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 141,
        "sku": "HB.M950.SSP",
        "name": "S.Steel Bullet Resonator: 2″ x 12″",
        "category": 4,
        "subCategory": 0,
        "price": 160,
        "shopee": "https://shopee.com.my/product/119505399/1910188310/",
        "desc": [
            "Total length is 12 inches",
            "Inner diameter is 2 inches",
            "Pipe length from bullet resonator is 1.5 inches",
            "Resonator length is 9 inches",
            "Middle bullet sports resonators - 100 % 304 stainless steel; perforated with stainless and glass wool.",
            "Straight through direct Internal Design.",
            "Maximum gas flow, no restrictions at all."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 142,
        "sku": "HB.M1550.SSP",
        "name": "S.Steel Bullet Resonator: 2″ x 15″",
        "category": 4,
        "subCategory": 0,
        "price": 180,
        "shopee": "https://shopee.com.my/product/119505399/1910608582/",
        "desc": [
            "Total length is 15 inches",
            "Inner diameter is 2 inches",
            "Pipe length from bullet resonator is 1.5 inches",
            "Resonator length is 12 inches",
            "Middle bullet sports resonators - 100 % 304 stainless steel; perforated with stainless and glass wool.",
            "Straight through direct Internal Design.",
            "Maximum gas flow, no restrictions at all."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 143,
        "sku": "33-3103",
        "name": "33-3103",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/3107861209/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Proton",
                "model": "X70",
                "engine": "Multi",
                "startYear": 2018,
                "endYear": 2023
            },
            {
                "make": "Proton",
                "model": "X50",
                "engine": "1.5",
                "startYear": 2020,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 144,
        "sku": "89-11839",
        "name": "K&N Square Tail Banner",
        "category": 9,
        "subCategory": 0,
        "price": 80,
        "shopee": "https://shopee.com.my/product/119505399/21671461054/",
        "desc": [
            "K&N Nylon Square Tail Banner.",
            "Size : 183 x 107 cm / 72 in x 42 in.",
            "Material : 100% Polyester."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 145,
        "sku": "99-0621",
        "name": "K&N High Performance Power Kleen Air Filter Cleaner",
        "category": 9,
        "subCategory": 0,
        "price": 135,
        "shopee": "https://shopee.com.my/product/119505399/5808575605/",
        "desc": [
            "32 ounce pump spray bottle of Power Kleen Air Filter Cleaner.",
            "The only cleaner recommended to clean K&N cotton air filter elements.",
            "Loosens and removes built up dirt and grime from your K&N oiled cotton air filter so it can be washed clean with water.",
            "Re-oil air filter after clearning and drying with K&N air filter oil (sold separately)."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 146,
        "sku": "33-3005",
        "name": "33-3005",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/7713381316/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Golf",
                "engine": "2.0",
                "startYear": 2013,
                "endYear": 2023
            },
            {
                "make": "Volkswagen",
                "model": "Passat",
                "engine": "2.0D",
                "startYear": 2015,
                "endYear": 2023
            },
            {
                "make": "Volkswagen",
                "model": "Passat",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2021
            },
            {
                "make": "Audi",
                "model": "TT",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2023
            },
            {
                "make": "Audi",
                "model": "TTS",
                "engine": "2.0",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "Volkswagen",
                "model": "Polo",
                "engine": "2.0",
                "startYear": 2017,
                "endYear": 2023
            },
            {
                "make": "Volkswagen",
                "model": "Arteon",
                "engine": "2.0D",
                "startYear": 2017,
                "endYear": 2023
            },
            {
                "make": "Volkswagen",
                "model": "Arteon",
                "engine": "2.0",
                "startYear": 2017,
                "endYear": 2022
            },
            {
                "make": "Audi",
                "model": "A1",
                "engine": "2.0",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "Audi",
                "model": "A3",
                "engine": "Multi",
                "startYear": 2013,
                "endYear": 2023
            },
            {
                "make": "Audi",
                "model": "Q2",
                "engine": "Multi",
                "startYear": 2016,
                "endYear": 2023
            },
            {
                "make": "Audi",
                "model": "Q3",
                "engine": "Multi",
                "startYear": 2019,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 147,
        "sku": "33-2277",
        "name": "33-2277",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/7649315031/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Persona Campro",
                "engine": "1.6",
                "startYear": 2007,
                "endYear": 2015
            },
            {
                "make": "Proton",
                "model": "Saga",
                "engine": "1.6",
                "startYear": 2008,
                "endYear": 2016
            },
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "3.0",
                "startYear": 2003,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 148,
        "sku": "33-2936",
        "name": "33-2936",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1835945125/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Peugeot",
                "model": "208",
                "engine": "1.6",
                "startYear": 2012,
                "endYear": 2018
            },
            {
                "make": "Peugeot",
                "model": "308",
                "engine": "1.6",
                "startYear": 2007,
                "endYear": 2022
            },
            {
                "make": "Peugeot",
                "model": "408",
                "engine": "1.6",
                "startYear": 2012,
                "endYear": 2017
            },
            {
                "make": "Peugeot",
                "model": "508",
                "engine": "1.6T",
                "startYear": 2010,
                "endYear": 2022
            },
            {
                "make": "Peugeot",
                "model": "5008",
                "engine": "1.6T",
                "startYear": 2009,
                "endYear": 2022
            },
            {
                "make": "Mini",
                "model": "Cooper Countryman",
                "engine": "1.6T",
                "startYear": 2015,
                "endYear": 2016
            },
            {
                "make": "Mini",
                "model": "Cooper",
                "engine": "1.6T",
                "startYear": 2015,
                "endYear": 2015
            },
            {
                "make": "Mini",
                "model": "Cooper S",
                "engine": "1.6T",
                "startYear": 2007,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 149,
        "sku": "33-3002",
        "name": "33-3002",
        "category": 8,
        "subCategory": 0,
        "price": 375,
        "shopee": "https://shopee.com.my/product/119505399/3839622366/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Isuzu",
                "model": "D-Max",
                "engine": "3.0D",
                "startYear": 2012,
                "endYear": 2021
            },
            {
                "make": "Isuzu",
                "model": "D-Max",
                "engine": "2.5D",
                "startYear": 2012,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 150,
        "sku": "33-2468",
        "name": "33-2468",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/7239773538/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.8",
                "startYear": 2012,
                "endYear": 2018
            },
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "2.0",
                "startYear": 2012,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 151,
        "sku": "33-2974",
        "name": "33-2974",
        "category": 8,
        "subCategory": 0,
        "price": 319,
        "shopee": "https://shopee.com.my/product/119505399/7140157294/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Swift",
                "engine": "1.4",
                "startYear": 2011,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 152,
        "sku": "SKU 830",
        "name": "Perodua Alza",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4932071318/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Alza",
                "engine": "1.5",
                "startYear": 2022,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 153,
        "sku": "RC-3250",
        "name": "RC-3250 Universal (Flange ID: 3.125″ Height: 6″)",
        "category": 8,
        "subCategory": 0,
        "price": 320,
        "shopee": "https://shopee.com.my/product/119505399/2895586817/",
        "desc": [
            "Round tapered shape air filter.",
            "Flange internal diameter: 3.125 inches (79mm).",
            "Flange length: 0.75 inch (19mm)",
            "Filter height: 6 inches (152mm).",
            "Top external diameter: 3.5 inches (89mm).",
            "Bottom external diameter: 5 inches (127mm)."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 154,
        "sku": "RC-2600",
        "name": "RC-2600 Universal (Flange ID: 2.875″ Height: 5″)",
        "category": 8,
        "subCategory": 0,
        "price": 270,
        "shopee": "https://shopee.com.my/product/119505399/6638721105/",
        "desc": [
            "Round tapered shape air filter.",
            "Flange internal diameter: 2.875 inches (73mm).",
            "Flange length: 0.625 inch (16mm)",
            "Filter height: 5 inches (127mm).",
            "Top external diameter: 3 inches (76mm).",
            "Bottom external diameter: 4 inches (102mm)."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 155,
        "sku": "33-2300",
        "name": "33-2300",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/5951916434/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Subaru",
                "model": "BRZ",
                "engine": "2.0 (Auto)",
                "startYear": 2012,
                "endYear": 2020
            },
            {
                "make": "Subaru",
                "model": "BRZ",
                "engine": "2.0 (Manual)",
                "startYear": 2012,
                "endYear": 2016
            },
            {
                "make": "Toyota",
                "model": "86",
                "engine": "2.0 (Auto)",
                "startYear": 2012,
                "endYear": 2020
            },
            {
                "make": "Toyota",
                "model": "86",
                "engine": "2.0 (Manual)",
                "startYear": 2012,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 156,
        "sku": "33-2196",
        "name": "33-2196",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/7033818195/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Subaru",
                "model": "BRZ",
                "engine": "2.0 (Manual)",
                "startYear": 2017,
                "endYear": 2020
            },
            {
                "make": "Toyota",
                "model": "86",
                "engine": "2.0 (Manual)",
                "startYear": 2017,
                "endYear": 2020
            },
            {
                "make": "Kia",
                "model": "Rio",
                "engine": "1.3",
                "startYear": 2000,
                "endYear": 2005
            },
            {
                "make": "Kia",
                "model": "Rio",
                "engine": "1.4",
                "startYear": 2005,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 157,
        "sku": "E-0661",
        "name": "E-0661",
        "category": 8,
        "subCategory": 0,
        "price": 389,
        "shopee": "https://shopee.com.my/product/119505399/6156893849/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "CLA45",
                "engine": "2.0",
                "startYear": 2014,
                "endYear": 2019
            },
            {
                "make": "Mercedes Benz",
                "model": "GLA45",
                "engine": "2.0",
                "startYear": 2014,
                "endYear": 2019
            },
            {
                "make": "Mercedes Benz",
                "model": "A45",
                "engine": "2.0",
                "startYear": 2014,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 158,
        "sku": "RU-2960",
        "name": "RU-2960 Universal (Flange ID: 6″ Height: 5″)",
        "category": 8,
        "subCategory": 0,
        "price": 580,
        "shopee": "https://shopee.com.my/product/119505399/2894525650/",
        "desc": [
            "Round tapered shape air filter.",
            "Flange internal diameter: 6 inches (152mm).",
            "Flange offset: 1 inch (25mm)",
            "Flange length: 1 inch (25mm)",
            "Filter height: 5 inches (127mm).",
            "Top external diameter: 5 inches (127mm).",
            "Bottom external diameter: 7.5 inches (191mm)."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 160,
        "sku": "33-2954",
        "name": "33-2954",
        "category": 8,
        "subCategory": 0,
        "price": 330,
        "shopee": "https://shopee.com.my/product/119505399/1835307934/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Bezza",
                "engine": "Multi",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 162,
        "sku": "33-2036",
        "name": "33-2036",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/5840063439/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Ativa",
                "engine": "1.0T",
                "startYear": 2021,
                "endYear": 2023
            },
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.3",
                "startYear": 1997,
                "endYear": 2001
            }
        ]
    },
    {
        "id": 163,
        "sku": "33-3030",
        "name": "33-3030",
        "category": 8,
        "subCategory": 0,
        "price": 315,
        "shopee": "https://shopee.com.my/product/119505399/2271337650/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.5",
                "startYear": 2014,
                "endYear": 2018
            },
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5",
                "startYear": 2014,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 164,
        "sku": "33-5027",
        "name": "33-5027",
        "category": 8,
        "subCategory": 0,
        "price": 315,
        "shopee": "https://shopee.com.my/product/119505399/2271337650/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.5",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5",
                "startYear": 2019,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 165,
        "sku": "33-2381",
        "name": "33-2381",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/2330823658/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "2.5",
                "startYear": 2012,
                "endYear": 2017
            },
            {
                "make": "Toyota",
                "model": "RAV4",
                "engine": "2.5",
                "startYear": 2013,
                "endYear": 2018
            },
            {
                "make": "Lexus",
                "model": "ES300h",
                "engine": "2.5",
                "startYear": 2013,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 166,
        "sku": "",
        "name": "Nissan X-Trail",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2732748562/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "X-Trail",
                "engine": "Multi",
                "startYear": 2012,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 167,
        "sku": "",
        "name": "Honda City",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2872198562/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.5",
                "startYear": 2006,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 168,
        "sku": "",
        "name": "Suzuki Swift",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2872244701/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Swift",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 169,
        "sku": "",
        "name": "Honda CR-Z",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2717729731/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-Z",
                "engine": "1.5",
                "startYear": 2012,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 170,
        "sku": "",
        "name": "Nissan Almera",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4232942713/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Almera",
                "engine": "Multi",
                "startYear": 2012,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 171,
        "sku": "33-5072",
        "name": "33-5072",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/5163239983/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "1.5T",
                "startYear": 2018,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 172,
        "sku": "33-2124",
        "name": "33-2124",
        "category": 8,
        "subCategory": 0,
        "price": 340,
        "shopee": "https://shopee.com.my/product/119505399/6739788343/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "2.0",
                "startYear": 1998,
                "endYear": 2003
            },
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "2.3",
                "startYear": 1998,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 173,
        "sku": "33-5120",
        "name": "33-5120",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/1828693608/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.5T",
                "startYear": 2022,
                "endYear": 2023
            },
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "1.5T",
                "startYear": 2023,
                "endYear": 2023
            },
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "1.5T",
                "startYear": 2023,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 174,
        "sku": "33-3080",
        "name": "33-3080",
        "category": 8,
        "subCategory": 0,
        "price": 370,
        "shopee": "https://shopee.com.my/product/119505399/20990037163/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Corolla Cross",
                "engine": "Multi",
                "startYear": 2022,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "Corolla",
                "engine": "1.2T",
                "startYear": 2018,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "Corolla",
                "engine": "2.0",
                "startYear": 2020,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "Corolla",
                "engine": "1.8",
                "startYear": 2020,
                "endYear": 2022
            },
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "2.5",
                "startYear": 2018,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 175,
        "sku": "33-3079",
        "name": "33-3079",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1827891323/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "520i",
                "engine": "2.0",
                "startYear": 2017,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "520d",
                "engine": "2.0D",
                "startYear": 2016,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "530i",
                "engine": "2.0",
                "startYear": 2016,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "530d",
                "engine": "3.0D",
                "startYear": 2016,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "630i",
                "engine": "2.0",
                "startYear": 2017,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "X3",
                "engine": "2.0",
                "startYear": 2018,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "X4",
                "engine": "2.0",
                "startYear": 2018,
                "endYear": 2022
            },
            {
                "make": "BMW",
                "model": "X5",
                "engine": "3.0",
                "startYear": 2019,
                "endYear": 2022
            },
            {
                "make": "BMW",
                "model": "X6",
                "engine": "3.0",
                "startYear": 2020,
                "endYear": 2022
            },
            {
                "make": "BMW",
                "model": "X7",
                "engine": "3.0",
                "startYear": 2020,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 176,
        "sku": "",
        "name": "Perodua Myvi III Open Pod Kit",
        "category": 8,
        "subCategory": 0,
        "price": 1199,
        "shopee": "https://shopee.com.my/product/119505399/7291349851/",
        "desc": [
            "Open pod kit includes vent filter and heat shield."
        ],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Myvi",
                "engine": "Multi",
                "startYear": 2017,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 177,
        "sku": "SKU 830",
        "name": "Perodua Myvi III",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2777296024/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Myvi",
                "engine": "Multi",
                "startYear": 2017,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 179,
        "sku": "33-2920",
        "name": "33-2920",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/4813381615/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Polo",
                "engine": "1.6",
                "startYear": 2006,
                "endYear": 2018
            },
            {
                "make": "Volkswagen",
                "model": "Polo",
                "engine": "1.4",
                "startYear": 2006,
                "endYear": 2009
            },
            {
                "make": "Volkswagen",
                "model": "Polo (Non-TSi)",
                "engine": "1.4",
                "startYear": 2010,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 180,
        "sku": "33-2309",
        "name": "33-2309",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/8051293829/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Odyssey",
                "engine": "2.4",
                "startYear": 2000,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 181,
        "sku": "33-2945",
        "name": "33-2945",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/4334470111/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Audi",
                "model": "A4",
                "engine": "1.8",
                "startYear": 2007,
                "endYear": 2016
            },
            {
                "make": "Audi",
                "model": "A4",
                "engine": "2.0",
                "startYear": 2008,
                "endYear": 2016
            },
            {
                "make": "Audi",
                "model": "A5",
                "engine": "1.8",
                "startYear": 2007,
                "endYear": 2016
            },
            {
                "make": "Audi",
                "model": "A5",
                "engine": "2.0",
                "startYear": 2008,
                "endYear": 2016
            },
            {
                "make": "Audi",
                "model": "Q5",
                "engine": "2.0",
                "startYear": 2008,
                "endYear": 2017
            },
            {
                "make": "Audi",
                "model": "TT",
                "engine": "1.8",
                "startYear": 2006,
                "endYear": 2014
            },
            {
                "make": "Audi",
                "model": "TT",
                "engine": "2.0",
                "startYear": 2006,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 182,
        "sku": "E-1987",
        "name": "E-1987",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/4528918565/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Audi",
                "model": "S4",
                "engine": "3.0",
                "startYear": 2010,
                "endYear": 2016
            },
            {
                "make": "Audi",
                "model": "S5",
                "engine": "3.0",
                "startYear": 2010,
                "endYear": 2017
            },
            {
                "make": "Audi",
                "model": "Q5",
                "engine": "3.0",
                "startYear": 2008,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 183,
        "sku": "33-5064",
        "name": "33-5064",
        "category": 8,
        "subCategory": 0,
        "price": 329,
        "shopee": "https://shopee.com.my/product/119505399/5208064133/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Subaru",
                "model": "XV",
                "engine": "Multi",
                "startYear": 2017,
                "endYear": 2022
            },
            {
                "make": "Subaru",
                "model": "Impreza",
                "engine": "2.0",
                "startYear": 2017,
                "endYear": 2023
            },
            {
                "make": "Subaru",
                "model": "Forester",
                "engine": "2.5",
                "startYear": 2019,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 184,
        "sku": "E-0660",
        "name": "E-0660",
        "category": 8,
        "subCategory": 0,
        "price": 550,
        "shopee": "https://shopee.com.my/product/119505399/4592491223/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Porsche",
                "model": "Macan",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Porsche",
                "model": "Macan",
                "engine": "2.9",
                "startYear": 2018,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 185,
        "sku": "33-2392",
        "name": "33-2392",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/7340185955/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Lancer",
                "engine": "Multi",
                "startYear": 2008,
                "endYear": 2017
            },
            {
                "make": "Proton",
                "model": "Inspira",
                "engine": "Multi",
                "startYear": 2010,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 186,
        "sku": "33-2342",
        "name": "33-2342",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/2266423138/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.8",
                "startYear": 2006,
                "endYear": 2011
            },
            {
                "make": "Honda",
                "model": "Stream",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 187,
        "sku": "33-2452",
        "name": "33-2452",
        "category": 8,
        "subCategory": 0,
        "price": 370,
        "shopee": "https://shopee.com.my/product/119505399/11901996108/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Vellfire",
                "engine": "2.5",
                "startYear": 2015,
                "endYear": 2018
            },
            {
                "make": "Toyota",
                "model": "Vellfire",
                "engine": "3.5",
                "startYear": 2015,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 188,
        "sku": "33-2963",
        "name": "33-2963",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/2913719125/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "520i",
                "engine": "2.0",
                "startYear": 2010,
                "endYear": 2017
            },
            {
                "make": "BMW",
                "model": "523i",
                "engine": "2.0",
                "startYear": 2010,
                "endYear": 2017
            },
            {
                "make": "BMW",
                "model": "528i",
                "engine": "2.0",
                "startYear": 2010,
                "endYear": 2017
            },
            {
                "make": "BMW",
                "model": "Z4",
                "engine": "2.0",
                "startYear": 2012,
                "endYear": 2017
            },
            {
                "make": "BMW",
                "model": "X1",
                "engine": "2.0",
                "startYear": 2012,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 189,
        "sku": "33-5040",
        "name": "33-5040",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/11092600638/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "MX-5",
                "engine": "2.0",
                "startYear": 2017,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 190,
        "sku": "E-2997",
        "name": "E-2997",
        "category": 8,
        "subCategory": 0,
        "price": 430,
        "shopee": "https://shopee.com.my/product/119505399/4613382496/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Polo (TSi)",
                "engine": "1.4",
                "startYear": 2010,
                "endYear": 2014
            },
            {
                "make": "Audi",
                "model": "A1",
                "engine": "Multi",
                "startYear": 2010,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 191,
        "sku": "E-0662",
        "name": "E-0662",
        "category": 8,
        "subCategory": 0,
        "price": 425,
        "shopee": "https://shopee.com.my/product/119505399/2333998782/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Ranger",
                "engine": "2.2TD",
                "startYear": 2011,
                "endYear": 2019
            },
            {
                "make": "Ford",
                "model": "Ranger",
                "engine": "3.2D",
                "startYear": 2011,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 192,
        "sku": "33-3064",
        "name": "33-3064",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/8719510197/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Santa Fe",
                "engine": "2.2TD",
                "startYear": 2018,
                "endYear": 2019
            },
            {
                "make": "Kia",
                "model": "Sorento",
                "engine": "2.2TD",
                "startYear": 2015,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 193,
        "sku": "33-2448",
        "name": "33-2448",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/5455968157/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Kia",
                "model": "Sorento",
                "engine": "2.4",
                "startYear": 2009,
                "endYear": 2013
            },
            {
                "make": "Kia",
                "model": "Sorento",
                "engine": "3.5",
                "startYear": 2010,
                "endYear": 2015
            },
            {
                "make": "Hyundai",
                "model": "Santa Fe",
                "engine": "2.4",
                "startYear": 2010,
                "endYear": 2012
            },
            {
                "make": "Hyundai",
                "model": "Santa Fe",
                "engine": "3.5",
                "startYear": 2010,
                "endYear": 2012
            },
            {
                "make": "Hyundai",
                "model": "Sonata",
                "engine": "2.0",
                "startYear": 2011,
                "endYear": 2014
            },
            {
                "make": "Hyundai",
                "model": "Sonata",
                "engine": "2.4",
                "startYear": 2011,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 194,
        "sku": "33-2493",
        "name": "33-2493",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/8719510197/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Kia",
                "model": "Sorento",
                "engine": "2.4",
                "startYear": 2014,
                "endYear": 2015
            },
            {
                "make": "Kia",
                "model": "Sorento",
                "engine": "3.3",
                "startYear": 2014,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 195,
        "sku": "33-2865",
        "name": "33-2865",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/3445240116/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Audi",
                "model": "Q3",
                "engine": "2.0T",
                "startYear": 2011,
                "endYear": 2018
            },
            {
                "make": "Audi",
                "model": "A3",
                "engine": "2.0",
                "startYear": 2003,
                "endYear": 2012
            },
            {
                "make": "Volkswagen",
                "model": "Passat",
                "engine": "1.8",
                "startYear": 2010,
                "endYear": 2014
            },
            {
                "make": "Volkswagen",
                "model": "Passat",
                "engine": "2.0",
                "startYear": 2009,
                "endYear": 2014
            },
            {
                "make": "Volkswagen",
                "model": "Passat",
                "engine": "2.0D",
                "startYear": 2006,
                "endYear": 2014
            },
            {
                "make": "Volkswagen",
                "model": "Beetle",
                "engine": "Multi",
                "startYear": 2011,
                "endYear": 2014
            },
            {
                "make": "Volkswagen",
                "model": "Scirocco",
                "engine": "1.4",
                "startYear": 2008,
                "endYear": 2014
            },
            {
                "make": "Volkswagen",
                "model": "Scirocco",
                "engine": "2.0",
                "startYear": 2008,
                "endYear": 2019
            },
            {
                "make": "Volkswagen",
                "model": "Tiguan",
                "engine": "2.0",
                "startYear": 2007,
                "endYear": 2017
            },
            {
                "make": "Volkswagen",
                "model": "Tiguan",
                "engine": "1.4",
                "startYear": 2007,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 196,
        "sku": "HB.EM 60-406-S-SS",
        "name": "Ford Raptor/Ranger Downpipe Exhaust",
        "category": 5,
        "subCategory": 0,
        "price": 1100,
        "shopee": "https://shopee.com.my/product/119505399/6665663457/",
        "desc": [
            "Hot Bits downpipe will not only provide better power gains but throttle response as well.",
            "Stainless steel unit with 3-inch diameter for maximum air flow.",
            "Zinc plated flanges.",
            "Flexible which reduces noise from engine vibrations, thus giving the user a more comfortable and quiet ride."
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Ranger Raptor",
                "engine": "2.0TD",
                "startYear": 2019,
                "endYear": 2021
            },
            {
                "make": "Ford",
                "model": "Ranger",
                "engine": "Multi",
                "startYear": 2011,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 197,
        "sku": "HB.ES 60-600M",
        "name": "Ford Raptor Mid & Rear Exhaust System",
        "category": 5,
        "subCategory": 0,
        "price": 1900,
        "shopee": "https://shopee.com.my/product/119505399/6665663457/",
        "desc": [
            "Hot Bits mid and rear system will provide a further increase in power.",
            "Stainless steel system with 2.5-inch diameter with performance bullet(s) ensures a high-flow exhaust system that has little to no restrictions.",
            "Creating a free flow system that boosts both response and power gains for the 2.0 Biturbo engine.",
            "Sound sample : https://drive.google.com/file/d/1aauXsfXAMPosaaHEExX44AnaMPSNyuZw/view?usp=sharing"
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Ranger Raptor",
                "engine": "2.0TD",
                "startYear": 2019,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 198,
        "sku": "HB.ES 60-600R",
        "name": "Ford Ranger Rear Exhaust System",
        "category": 5,
        "subCategory": 0,
        "price": 1400,
        "shopee": "https://shopee.com.my/product/119505399/6665663457/",
        "desc": [
            "Hot Bits rear system will provide a further increase in power.",
            "Stainless steel system with 2.5-inch diameter with performance bullet(s) ensures a high-flow exhaust system that has little to no restrictions.",
            "Creating a free flow system that boosts both response and power gains for the 2.0 Biturbo engine.",
            "Sound sample : https://drive.google.com/file/d/1aauXsfXAMPosaaHEExX44AnaMPSNyuZw/view?usp=sharing"
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Ranger",
                "engine": "Multi",
                "startYear": 2011,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 199,
        "sku": "",
        "name": "Ford Rapter/Ranger Biturbo DTE Chip",
        "category": 99,
        "subCategory": 0,
        "price": 3900,
        "shopee": "https://shopee.com.my/product/119505399/22907326346/",
        "desc": [
            "PowerControl X from DTE optimizes all signals from the engine management and matches the control parameters in the best possible way.",
            "This gives your Ford Ranger Raptor noticeably more performance and extra power during acceleration.",
            "Ford pick-up gains an additional +27 hp and +65 Nm, and improved acceleration of 1.0 second.",
            "Maximum efficiency with maximum driving pleasure.",
            "PowerControl X from DTE can be installed in the engine compartment in just a few steps and is ready for immediate use.",
            "Conveniently control the efficiency of your Ford Ranger with your smartphone with the help of the PowerControl X add-on control unit.",
            "Through the DTE Systems app, you retain control over the various tuning programmes at all times and can align them to your current driving style.",
            "Vehicle performance data can also be accessed directly via the smartphone app."
        ],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Ranger Raptor",
                "engine": "2.0TD",
                "startYear": 2019,
                "endYear": 2021
            },
            {
                "make": "Ford",
                "model": "Ranger",
                "engine": "Multi",
                "startYear": 2011,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 200,
        "sku": "90-103",
        "name": "Proton Satria GTi",
        "category": 11,
        "subCategory": 0,
        "price": 60,
        "shopee": "https://shopee.com.my/product/119505399/17709621602/",
        "desc": [],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria GTi",
                "engine": "1.8",
                "startYear": 1998,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 201,
        "sku": "",
        "name": "Proton Satria Neo Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/6133619870/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria Neo",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 202,
        "sku": "SKU 715",
        "name": "Ford Rapter/Ranger",
        "category": 10,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/2872217337/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Ranger Raptor",
                "engine": "2.0TD",
                "startYear": 2019,
                "endYear": 2021
            },
            {
                "make": "Ford",
                "model": "Ranger",
                "engine": "Multi",
                "startYear": 2011,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 203,
        "sku": "33-3086",
        "name": "33-3086",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/3108092518/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Ranger",
                "engine": "2.0TD",
                "startYear": 2018,
                "endYear": 2020
            },
            {
                "make": "Ford",
                "model": "Ranger",
                "engine": "2.3",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "Ford",
                "model": "Ranger Raptor",
                "engine": "2.0TD",
                "startYear": 2019,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 204,
        "sku": "33-3004",
        "name": "33-3004",
        "category": 8,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/3213544112/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Golf",
                "engine": "1.4",
                "startYear": 2012,
                "endYear": 2023
            },
            {
                "make": "Volkswagen",
                "model": "Jetta",
                "engine": "1.4",
                "startYear": 2013,
                "endYear": 2018
            },
            {
                "make": "Volkswagen",
                "model": "Tiguan",
                "engine": "1.4",
                "startYear": 2021,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 206,
        "sku": "33-2293",
        "name": "33-2293",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/5913377476/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "3 (Non Skyactiv)",
                "engine": "2.0",
                "startYear": 2012,
                "endYear": 2013
            },
            {
                "make": "Mazda",
                "model": "3",
                "engine": "2.0",
                "startYear": 2003,
                "endYear": 2011
            },
            {
                "make": "Mazda",
                "model": "3",
                "engine": "2.3",
                "startYear": 2004,
                "endYear": 2010
            },
            {
                "make": "Mazda",
                "model": "5",
                "engine": "2.5",
                "startYear": 2012,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 207,
        "sku": "33-3045",
        "name": "33-3045",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/2330042969/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Fortuner",
                "engine": "2.7",
                "startYear": 2016,
                "endYear": 2018
            },
            {
                "make": "Toyota",
                "model": "Fortuner",
                "engine": "2.8D",
                "startYear": 2015,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "Fortuner",
                "engine": "2.4D",
                "startYear": 2015,
                "endYear": 2019
            },
            {
                "make": "Toyota",
                "model": "Hilux",
                "engine": "2.8D",
                "startYear": 2015,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "Hilux",
                "engine": "2.4D",
                "startYear": 2015,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 208,
        "sku": "",
        "name": "Nissan Navara NP300",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2732702588/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Navara",
                "engine": "Multi",
                "startYear": 2014,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 209,
        "sku": "E-2443",
        "name": "E-2443",
        "category": 8,
        "subCategory": 0,
        "price": 479,
        "shopee": "https://shopee.com.my/product/119505399/2330226162/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 70",
                "engine": "4.5D",
                "startYear": 1992,
                "endYear": 2001
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 80",
                "engine": "4.5",
                "startYear": 1990,
                "endYear": 1997
            }
        ]
    },
    {
        "id": 210,
        "sku": "33-3142",
        "name": "33-3142",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/7655204270/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "C200",
                "engine": "2.0T",
                "startYear": 2018,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "C250",
                "engine": "2.0T",
                "startYear": 2018,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "C300",
                "engine": "2.0T",
                "startYear": 2018,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 211,
        "sku": "33-2459",
        "name": "33-2459",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/6340023994/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-Z",
                "engine": "1.5",
                "startYear": 2010,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 212,
        "sku": "99-5000",
        "name": "Aerosol Cleaner and Oil Recharger Kit",
        "category": 9,
        "subCategory": 0,
        "price": 120,
        "shopee": "https://shopee.com.my/product/119505399/13790636530/",
        "desc": [
            "Includes a 6.5-ounce aerosol can of oil and a 12-ounce spray bottle of POWER KLEEN®.",
            "POWER KLEEN® industrial strength degreaser helps dissolve dirt, old filter oil, and other filter-clogging particulates, allowing them to be rinsed away easily with water.",
            "When used as directed, the included air filter oil remains suspended in the pleats of the cotton filter material, allowing for exceptional contaminant capture.",
            "View the complete cleaning instructions included in your kit."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 213,
        "sku": "33-5045",
        "name": "33-5045",
        "category": 8,
        "subCategory": 0,
        "price": 320,
        "shopee": "https://shopee.com.my/product/119505399/1828663264/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.8",
                "startYear": 2016,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 214,
        "sku": "33-2985",
        "name": "33-2985",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/7155039817/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "S400",
                "engine": "3.5",
                "startYear": 2013,
                "endYear": 2017
            },
            {
                "make": "Mercedes Benz",
                "model": "E350",
                "engine": "3.5",
                "startYear": 2011,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 215,
        "sku": "33-2408",
        "name": "33-2408",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/4452828857/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Hummer",
                "model": "H3",
                "engine": "3.7",
                "startYear": 2008,
                "endYear": 2010
            },
            {
                "make": "Hummer",
                "model": "H3",
                "engine": "3.5",
                "startYear": 2008,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 216,
        "sku": "E-0773",
        "name": "E-0773",
        "category": 8,
        "subCategory": 0,
        "price": 480,
        "shopee": "https://shopee.com.my/product/119505399/14250819301/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Hummer",
                "model": "H3",
                "engine": "3.7",
                "startYear": 2007,
                "endYear": 2007
            },
            {
                "make": "Hummer",
                "model": "H3",
                "engine": "3.5",
                "startYear": 2005,
                "endYear": 2007
            },
            {
                "make": "Chevrolet",
                "model": "Colorado",
                "engine": "2.9",
                "startYear": 2007,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 217,
        "sku": "",
        "name": "Honda City V",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2721415493/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.5",
                "startYear": 2015,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 218,
        "sku": "",
        "name": "Honda City VI",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2721415493/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.5",
                "startYear": 2008,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 219,
        "sku": "AXT 9001P",
        "name": "Proton Perdana Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 45,
        "shopee": "https://shopee.com.my/product/119505399/2763257981/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Perdana",
                "engine": "2.0",
                "startYear": 1998,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 220,
        "sku": "AXT 9009P",
        "name": "Proton Perdana Performance Rear Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 35,
        "shopee": "https://shopee.com.my/product/119505399/2763257981/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Perdana",
                "engine": "2.0",
                "startYear": 1998,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 221,
        "sku": "33-2378",
        "name": "33-2378",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/3380431073/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Santa Fe",
                "engine": "2.2TD",
                "startYear": 2005,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 222,
        "sku": "AX.WG-01",
        "name": "AXT Turbo External Turbo Wastegate",
        "category": 99,
        "subCategory": 0,
        "price": 680,
        "shopee": "https://shopee.com.my/product/119505399/3713712155/",
        "desc": [
            "Hole Size is 30mm.",
            "Height: 180mm, Length: 86mm, Weight: 65mm.",
            "Required for Big Turbo & High Boost to enhanced the turbo boost instantly.",
            "Turbo side material is high grade cast iron to withstand extreme high temperature.",
            "Adjustment side is High Grade Alloy.",
            "Fully Adjustable for control the exhaust airflow release."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 223,
        "sku": "33-2965",
        "name": "33-2965",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/4305220750/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "C200",
                "engine": "1.8T",
                "startYear": 2009,
                "endYear": 2014
            },
            {
                "make": "Mercedes Benz",
                "model": "C250",
                "engine": "1.8T",
                "startYear": 2009,
                "endYear": 2013
            },
            {
                "make": "Mercedes Benz",
                "model": "E200",
                "engine": "1.8T",
                "startYear": 2009,
                "endYear": 2013
            },
            {
                "make": "Mercedes Benz",
                "model": "E250",
                "engine": "1.8T",
                "startYear": 2009,
                "endYear": 2013
            },
            {
                "make": "Mercedes Benz",
                "model": "SLK200",
                "engine": "1.8T",
                "startYear": 2011,
                "endYear": 2015
            },
            {
                "make": "Mercedes Benz",
                "model": "SLK250",
                "engine": "1.8T",
                "startYear": 2011,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 225,
        "sku": "",
        "name": "S.Steel Bullet Resonator: 2″ x 9″",
        "category": 4,
        "subCategory": 0,
        "price": 120,
        "shopee": "https://shopee.com.my/product/119505399/6019523853/",
        "desc": [
            "Total length is 9 inches",
            "Inner diameter is 2 inches",
            "Pipe length from bullet resonator is 1.5 inches",
            "Resonator length is 6 inches",
            "Middle bullet sports resonators - 100 % 304 stainless steel.",
            "Straight through direct Internal Design.",
            "Maximum gas flow, no restrictions at all."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 226,
        "sku": "33-2539",
        "name": "33-2539",
        "category": 8,
        "subCategory": 0,
        "price": 329,
        "shopee": "https://shopee.com.my/product/119505399/4038533880/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Waja",
                "engine": "Multi",
                "startYear": 2000,
                "endYear": 2011
            },
            {
                "make": "Proton",
                "model": "Satria Neo",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2015
            },
            {
                "make": "Proton",
                "model": "GEN-2",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            },
            {
                "make": "Proton",
                "model": "Exora",
                "engine": "1.6",
                "startYear": 2009,
                "endYear": 2023
            },
            {
                "make": "Peugeot",
                "model": "405",
                "engine": "Multi",
                "startYear": 1987,
                "endYear": 1996
            },
            {
                "make": "Peugeot",
                "model": "406",
                "engine": "Multi",
                "startYear": 1995,
                "endYear": 2004
            }
        ]
    },
    {
        "id": 227,
        "sku": "E-1983",
        "name": "E-1983",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/6508064667/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Audi",
                "model": "A6",
                "engine": "2.0T",
                "startYear": 2011,
                "endYear": 2014
            },
            {
                "make": "Audi",
                "model": "A6",
                "engine": "3.0",
                "startYear": 2012,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 228,
        "sku": "E-2987",
        "name": "E-2987",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/6508064667/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Audi",
                "model": "A6",
                "engine": "2.0T",
                "startYear": 2014,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 229,
        "sku": "HB.M800P",
        "name": "Aluminised Steel Oval Muffler - Centre 1.75″",
        "category": 4,
        "subCategory": 0,
        "price": 280,
        "shopee": "https://shopee.com.my/product/119505399/1910149291/",
        "desc": [
            "Length is 14 inches.",
            "Inlet diameter is 1.75 inches.",
            "Rear oval sports muffler.",
            "Straight through direct internal design.",
            "Maximum gas flow, no restrictions at all.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 230,
        "sku": "33-2438",
        "name": "33-2438",
        "category": 8,
        "subCategory": 0,
        "price": 480,
        "shopee": "https://shopee.com.my/product/119505399/16977689875/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "FJ Cruiser",
                "engine": "4.0",
                "startYear": 2010,
                "endYear": 2014
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser Prado",
                "engine": "4.0",
                "startYear": 2010,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 231,
        "sku": "E-2021",
        "name": "E-2021",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/6838577919/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "318i",
                "engine": "2.0",
                "startYear": 2005,
                "endYear": 2012
            },
            {
                "make": "BMW",
                "model": "320i",
                "engine": "2.0",
                "startYear": 2005,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 232,
        "sku": "SKU 567",
        "name": "Toyota Fortuner",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6158494671/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Fortuner",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 233,
        "sku": "33-2294",
        "name": "33-2294",
        "category": 8,
        "subCategory": 0,
        "price": 419,
        "shopee": "https://shopee.com.my/product/119505399/6138539566/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "520i",
                "engine": "2.0",
                "startYear": 2009,
                "endYear": 2010
            },
            {
                "make": "BMW",
                "model": "540i",
                "engine": "4.0",
                "startYear": 2005,
                "endYear": 2010
            },
            {
                "make": "BMW",
                "model": "545i",
                "engine": "4.4",
                "startYear": 2003,
                "endYear": 2005
            },
            {
                "make": "BMW",
                "model": "550i",
                "engine": "4.8",
                "startYear": 2005,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 234,
        "sku": "33-2345",
        "name": "33-2345",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/6454137124/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Lexus",
                "model": "IS 250",
                "engine": "2.5",
                "startYear": 2005,
                "endYear": 2013
            },
            {
                "make": "Lexus",
                "model": "IS 350",
                "engine": "3.5",
                "startYear": 2005,
                "endYear": 2013
            },
            {
                "make": "Toyota",
                "model": "Mark X",
                "engine": "2.5",
                "startYear": 2004,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 235,
        "sku": "33-2767",
        "name": "33-2767",
        "category": 8,
        "subCategory": 0,
        "price": 460,
        "shopee": "https://shopee.com.my/product/119505399/19811519887/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "SLK200",
                "engine": "2.0",
                "startYear": 1996,
                "endYear": 2004
            },
            {
                "make": "Mercedes Benz",
                "model": "SLK230",
                "engine": "2.3",
                "startYear": 1996,
                "endYear": 2004
            }
        ]
    },
    {
        "id": 236,
        "sku": "80-100",
        "name": "Nissan Silvia 1.8",
        "category": 11,
        "subCategory": 0,
        "price": 90,
        "shopee": "https://shopee.com.my/product/119505399/17407839526/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Silvia",
                "engine": "1.8T",
                "startYear": 1988,
                "endYear": 1994
            }
        ]
    },
    {
        "id": 237,
        "sku": "80-101",
        "name": "Nissan Silvia 2.0",
        "category": 11,
        "subCategory": 0,
        "price": 90,
        "shopee": "https://shopee.com.my/product/119505399/17407839526/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Silvia",
                "engine": "2.0T",
                "startYear": 1988,
                "endYear": 1994
            }
        ]
    },
    {
        "id": 238,
        "sku": "90-101",
        "name": "Proton GEN-2 (Manual)",
        "category": 11,
        "subCategory": 0,
        "price": 90,
        "shopee": "https://shopee.com.my/product/119505399/15155884114/",
        "desc": [],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Proton",
                "model": "GEN-2",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 239,
        "sku": "33-2384",
        "name": "33-2384",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/20210025396/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Audi",
                "model": "TT RS",
                "engine": "2.5",
                "startYear": 2010,
                "endYear": 2014
            },
            {
                "make": "Audi",
                "model": "TT",
                "engine": "2.5",
                "startYear": 2009,
                "endYear": 2014
            },
            {
                "make": "Audi",
                "model": "Q3",
                "engine": "2.5",
                "startYear": 2013,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 240,
        "sku": "33-2070",
        "name": "33-2070",
        "category": 8,
        "subCategory": 0,
        "price": 389,
        "shopee": "https://shopee.com.my/product/119505399/1835350548/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "Z4",
                "engine": "2.2",
                "startYear": 2003,
                "endYear": 2006
            },
            {
                "make": "BMW",
                "model": "Z4",
                "engine": "2.5",
                "startYear": 2003,
                "endYear": 2006
            },
            {
                "make": "BMW",
                "model": "X3",
                "engine": "2.5",
                "startYear": 2004,
                "endYear": 2006
            },
            {
                "make": "BMW",
                "model": "Z3",
                "engine": "Multi",
                "startYear": 1997,
                "endYear": 2003
            },
            {
                "make": "BMW",
                "model": "520i",
                "engine": "Multi",
                "startYear": 1996,
                "endYear": 2004
            },
            {
                "make": "BMW",
                "model": "523i",
                "engine": "2.5",
                "startYear": 1995,
                "endYear": 2000
            },
            {
                "make": "BMW",
                "model": "525i",
                "engine": "2.5",
                "startYear": 2000,
                "endYear": 2004
            },
            {
                "make": "BMW",
                "model": "530i",
                "engine": "3.0",
                "startYear": 2000,
                "endYear": 2004
            },
            {
                "make": "BMW",
                "model": "528i",
                "engine": "2.8",
                "startYear": 1995,
                "endYear": 2000
            },
            {
                "make": "BMW",
                "model": "323i",
                "engine": "2.5",
                "startYear": 1995,
                "endYear": 1999
            },
            {
                "make": "BMW",
                "model": "320i",
                "engine": "2.0",
                "startYear": 1990,
                "endYear": 1999
            },
            {
                "make": "BMW",
                "model": "325i",
                "engine": "2.5",
                "startYear": 1991,
                "endYear": 1999
            },
            {
                "make": "BMW",
                "model": "328i",
                "engine": "2.8",
                "startYear": 1995,
                "endYear": 1999
            },
            {
                "make": "BMW",
                "model": "M3",
                "engine": "3.0",
                "startYear": 1992,
                "endYear": 2000
            },
            {
                "make": "BMW",
                "model": "728i",
                "engine": "2.8",
                "startYear": 1994,
                "endYear": 2001
            }
        ]
    },
    {
        "id": 241,
        "sku": "33-2873",
        "name": "33-2873",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/11423631664/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volvo",
                "model": "S40",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            },
            {
                "make": "Volvo",
                "model": "S60",
                "engine": "2.5",
                "startYear": 2012,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 242,
        "sku": "33-2964",
        "name": "33-2964",
        "category": 8,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/11013117155/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Chevrolet",
                "model": "Cruze",
                "engine": "1.8",
                "startYear": 2009,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 243,
        "sku": "AX.BD 90-102",
        "name": "Proton Wira Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 280,
        "shopee": "https://shopee.com.my/product/119505399/2764855126/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Wira",
                "engine": "Multi",
                "startYear": 1993,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 244,
        "sku": "AXT 9001P",
        "name": "Proton Wira Performance Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 40,
        "shopee": "https://shopee.com.my/product/119505399/2763362818/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Wira",
                "engine": "Multi",
                "startYear": 1993,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 245,
        "sku": "33-3007",
        "name": "33-3007",
        "category": 8,
        "subCategory": 0,
        "price": 400,
        "shopee": "https://shopee.com.my/product/119505399/16172480363/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Renault",
                "model": "Clio",
                "engine": "Multi",
                "startYear": 2013,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 246,
        "sku": "33-2270",
        "name": "33-2270",
        "category": 8,
        "subCategory": 0,
        "price": 400,
        "shopee": "https://shopee.com.my/product/119505399/21908038015/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mini",
                "model": "Cooper S",
                "engine": "1.6",
                "startYear": 2002,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 247,
        "sku": "HA-7591",
        "name": "HA-7591",
        "category": 8,
        "subCategory": 0,
        "price": 240,
        "shopee": "https://shopee.com.my/product/119505399/2391489480/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CB750 Nighthawk",
                "engine": "750",
                "startYear": 1991,
                "endYear": 2003
            },
            {
                "make": "Honda",
                "model": "CBX750",
                "engine": "750",
                "startYear": 1984,
                "endYear": 1986
            }
        ]
    },
    {
        "id": 249,
        "sku": "SU-7005",
        "name": "SU-7005",
        "category": 8,
        "subCategory": 0,
        "price": 240,
        "shopee": "https://shopee.com.my/product/119505399/2391537669/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "LTA450X Kingquad 4x4",
                "engine": "450",
                "startYear": 2007,
                "endYear": 2007
            },
            {
                "make": "Suzuki",
                "model": "LTA450X Kingquad 4x4 Camo",
                "engine": "450",
                "startYear": 2007,
                "endYear": 2007
            },
            {
                "make": "Suzuki",
                "model": "LTA450X Kingquad AXI",
                "engine": "450",
                "startYear": 2008,
                "endYear": 2009
            },
            {
                "make": "Suzuki",
                "model": "LTA450X Kingquad AXI Camo",
                "engine": "450",
                "startYear": 2008,
                "endYear": 2009
            },
            {
                "make": "Suzuki",
                "model": "LTA500XP Kingquad AXI PS 500",
                "engine": "500",
                "startYear": 2011,
                "endYear": 2012
            },
            {
                "make": "Suzuki",
                "model": "LTA500XP Kingquad AXI PS 500 Camo 500",
                "engine": "500",
                "startYear": 2012,
                "endYear": 2012
            },
            {
                "make": "Suzuki",
                "model": "LTA700X Kingquad 4x4",
                "engine": "700",
                "startYear": 2005,
                "endYear": 2007
            },
            {
                "make": "Suzuki",
                "model": "LTA700X Kingquad 4x4 Camo",
                "engine": "700",
                "startYear": 2006,
                "endYear": 2007
            },
            {
                "make": "Suzuki",
                "model": "LTA750XP Kingquad AXI 4x4",
                "engine": "750",
                "startYear": 2008,
                "endYear": 2012
            },
            {
                "make": "Suzuki",
                "model": "LTA750XP Kingquad AXI 4x4 Camo",
                "engine": "750",
                "startYear": 2008,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 250,
        "sku": "HA-6089",
        "name": "HA-6089",
        "category": 8,
        "subCategory": 0,
        "price": 240,
        "shopee": "https://shopee.com.my/product/119505399/2390298851/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CB600 Hornet",
                "engine": "600",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Honda",
                "model": "599",
                "engine": "599",
                "startYear": 2004,
                "endYear": 2006
            },
            {
                "make": "Honda",
                "model": "CBF500",
                "engine": "500",
                "startYear": 2004,
                "endYear": 2006
            },
            {
                "make": "Honda",
                "model": "CBF600F",
                "engine": "600",
                "startYear": 2005,
                "endYear": 2005
            },
            {
                "make": "Honda",
                "model": "CBF600S",
                "engine": "599",
                "startYear": 2004,
                "endYear": 2006
            },
            {
                "make": "Honda",
                "model": "CBF600",
                "engine": "599",
                "startYear": 2004,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 251,
        "sku": "33-2428",
        "name": "33-2428",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/6938575010/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "X6",
                "engine": "3.0",
                "startYear": 2008,
                "endYear": 2019
            },
            {
                "make": "BMW",
                "model": "X6",
                "engine": "3.0TD",
                "startYear": 2008,
                "endYear": 2019
            },
            {
                "make": "BMW",
                "model": "X5",
                "engine": "3.0",
                "startYear": 2011,
                "endYear": 2018
            },
            {
                "make": "BMW",
                "model": "X5",
                "engine": "3.0TD",
                "startYear": 2011,
                "endYear": 2018
            },
            {
                "make": "BMW",
                "model": "X4",
                "engine": "3.0",
                "startYear": 2015,
                "endYear": 2018
            },
            {
                "make": "BMW",
                "model": "X3",
                "engine": "3.0",
                "startYear": 2012,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 252,
        "sku": "33-2260",
        "name": "33-2260",
        "category": 8,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/2330124449/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Harrier",
                "engine": "Multi",
                "startYear": 2003,
                "endYear": 2005
            },
            {
                "make": "Toyota",
                "model": "Alphard",
                "engine": "3.0",
                "startYear": 2002,
                "endYear": 2008
            },
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "2.4",
                "startYear": 2001,
                "endYear": 2006
            },
            {
                "make": "Lexus",
                "model": "RX300",
                "engine": "3.0",
                "startYear": 2003,
                "endYear": 2006
            },
            {
                "make": "Lexus",
                "model": "ES300",
                "engine": "3.0",
                "startYear": 2002,
                "endYear": 2003
            },
            {
                "make": "Toyota",
                "model": "Estima",
                "engine": "3.0",
                "startYear": 2000,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 253,
        "sku": "33-2370",
        "name": "33-2370",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/2283588854/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "2.0",
                "startYear": 2006,
                "endYear": 2014
            },
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "2.4",
                "startYear": 2007,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 254,
        "sku": "33-2052",
        "name": "33-2052",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/7431336943/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Caldina",
                "engine": "2.0",
                "startYear": 2002,
                "endYear": 2007
            },
            {
                "make": "Toyota",
                "model": "Harrier",
                "engine": "2.2",
                "startYear": 1998,
                "endYear": 2000
            },
            {
                "make": "Toyota",
                "model": "Harrier",
                "engine": "2.4",
                "startYear": 2000,
                "endYear": 2002
            },
            {
                "make": "Toyota",
                "model": "Harrier",
                "engine": "3.0",
                "startYear": 1997,
                "endYear": 2002
            },
            {
                "make": "Toyota",
                "model": "Celica",
                "engine": "Multi",
                "startYear": 1994,
                "endYear": 1999
            },
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "2.2",
                "startYear": 1991,
                "endYear": 1996
            },
            {
                "make": "Lexus",
                "model": "ES300",
                "engine": "3.0",
                "startYear": 1993,
                "endYear": 1996
            },
            {
                "make": "Toyota",
                "model": "Mark II",
                "engine": "2.2",
                "startYear": 1997,
                "endYear": 2001
            }
        ]
    },
    {
        "id": 255,
        "sku": "",
        "name": "Proton X70 Open Pod Kit",
        "category": 8,
        "subCategory": 0,
        "price": 1200,
        "shopee": "https://shopee.com.my/product/119505399/9346155163/",
        "desc": [
            "Open pod kit includes heat shield."
        ],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Proton",
                "model": "X70",
                "engine": "Multi",
                "startYear": 2018,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 256,
        "sku": "",
        "name": "BMW E30/32/34/36/39 HIOB Powerchip",
        "category": 99,
        "subCategory": 0,
        "price": 1400,
        "shopee": "https://shopee.com.my/product/119505399/16468833493/",
        "desc": [
            "HIOB Performance Chip for BMW E30/32/34/36/39 series.",
            "Performance guaranteed. This powerchip is capable up improvements up to 14% in power gains.",
            "developed and engineered in Austria.",
            "Installation is simple if done properly. Certain tools required to open ECU unit.",
            "Requires only a plug-and-play approach.",
            "Warranty one to one exchange is 3 months.",
            "Installation also available at our workshop in PJ.",
            "Contact and message us your car model to confirm part number."
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "BMW",
                "model": "E30",
                "engine": "Multi",
                "startYear": 1982,
                "endYear": 1994
            },
            {
                "make": "BMW",
                "model": "E32",
                "engine": "Multi",
                "startYear": 1987,
                "endYear": 1994
            },
            {
                "make": "BMW",
                "model": "E34",
                "engine": "Multi",
                "startYear": 1987,
                "endYear": 1996
            },
            {
                "make": "BMW",
                "model": "E36",
                "engine": "Multi",
                "startYear": 1990,
                "endYear": 2000
            },
            {
                "make": "BMW",
                "model": "E39",
                "engine": "Multi",
                "startYear": 1996,
                "endYear": 2003
            }
        ]
    },
    {
        "id": 257,
        "sku": "33-2335",
        "name": "33-2335",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/6587997016/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "MX-5",
                "engine": "2.0",
                "startYear": 2006,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 258,
        "sku": "E-0663",
        "name": "E-0663",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/15954048087/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Infinity",
                "model": "Q50",
                "engine": "2.0",
                "startYear": 2014,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 259,
        "sku": "33-2422",
        "name": "33-2422",
        "category": 8,
        "subCategory": 0,
        "price": 340,
        "shopee": "https://shopee.com.my/product/119505399/2271630244/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.5",
                "startYear": 2008,
                "endYear": 2013
            },
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5",
                "startYear": 2008,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 260,
        "sku": "E-2865",
        "name": "E-2865",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/5938122227/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "190E",
                "engine": "Multi",
                "startYear": 1982,
                "endYear": 1988
            },
            {
                "make": "Mercedes Benz",
                "model": "230E",
                "engine": "2.3",
                "startYear": 1980,
                "endYear": 1988
            }
        ]
    },
    {
        "id": 261,
        "sku": "HB.CS 90-399",
        "name": "Proton Wira/Putra/Satria Neo - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/2774832599/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Wira",
                "engine": "Multi",
                "startYear": 1993,
                "endYear": 2009
            },
            {
                "make": "Proton",
                "model": "Putra",
                "engine": "1.8",
                "startYear": 1996,
                "endYear": 2004
            },
            {
                "make": "Proton",
                "model": "Satria Neo",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 262,
        "sku": "33-2980",
        "name": "33-2980",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/7171839258/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Navara",
                "engine": "2.5TD",
                "startYear": 2005,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 263,
        "sku": "E-2444",
        "name": "E-2444",
        "category": 8,
        "subCategory": 0,
        "price": 479,
        "shopee": "https://shopee.com.my/product/119505399/2330187372/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.2D",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.5",
                "startYear": 1998,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 264,
        "sku": "33-2672",
        "name": "33-2672",
        "category": 8,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/2333420534/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Corolla",
                "engine": "1.6",
                "startYear": 1992,
                "endYear": 1999
            },
            {
                "make": "Toyota",
                "model": "Corolla",
                "engine": "1.5",
                "startYear": 1992,
                "endYear": 1999
            }
        ]
    },
    {
        "id": 265,
        "sku": "33-2211",
        "name": "33-2211",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/2330842011/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Vios",
                "engine": "1.5",
                "startYear": 2003,
                "endYear": 2007
            },
            {
                "make": "Toyota",
                "model": "Yaris",
                "engine": "1.5",
                "startYear": 2005,
                "endYear": 2007
            },
            {
                "make": "Toyota",
                "model": "IST",
                "engine": "1.5",
                "startYear": 2002,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 266,
        "sku": "33-2941",
        "name": "33-2941",
        "category": 8,
        "subCategory": 0,
        "price": 400,
        "shopee": "https://shopee.com.my/product/119505399/5034468205/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Peugeot",
                "model": "208",
                "engine": "1.6",
                "startYear": 2012,
                "endYear": 2015
            },
            {
                "make": "Peugeot",
                "model": "308",
                "engine": "1.6",
                "startYear": 2007,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 267,
        "sku": "E-2014",
        "name": "E-2014",
        "category": 8,
        "subCategory": 0,
        "price": 430,
        "shopee": "https://shopee.com.my/product/119505399/3785648094/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Beetle",
                "engine": "1.2",
                "startYear": 2011,
                "endYear": 2014
            },
            {
                "make": "Volkswagen",
                "model": "Tiguan",
                "engine": "1.4",
                "startYear": 2010,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 268,
        "sku": "33-2951",
        "name": "33-2951",
        "category": 8,
        "subCategory": 0,
        "price": 398,
        "shopee": "https://shopee.com.my/product/119505399/6451246864/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Triton",
                "engine": "2.5TD",
                "startYear": 2008,
                "endYear": 2015
            },
            {
                "make": "Mitsubishi",
                "model": "Pajero Sport",
                "engine": "2.5TD",
                "startYear": 2009,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 269,
        "sku": "33-2355",
        "name": "33-2355",
        "category": 8,
        "subCategory": 0,
        "price": 330,
        "shopee": "https://shopee.com.my/product/119505399/2330086273/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Vellfire",
                "engine": "2.4",
                "startYear": 2008,
                "endYear": 2014
            },
            {
                "make": "Toyota",
                "model": "Vellfire",
                "engine": "3.5",
                "startYear": 2008,
                "endYear": 2014
            },
            {
                "make": "Toyota",
                "model": "RAV4",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 270,
        "sku": "33-2499",
        "name": "33-2499",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/14373236739/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Renault",
                "model": "Koleos",
                "engine": "2.5",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 272,
        "sku": "33-2451",
        "name": "33-2451",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/9019320629/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Kia",
                "model": "Cerato",
                "engine": "2.0",
                "startYear": 2014,
                "endYear": 2017
            },
            {
                "make": "Kia",
                "model": "Forte",
                "engine": "2.0",
                "startYear": 2014,
                "endYear": 2018
            },
            {
                "make": "Hyundai",
                "model": "i30",
                "engine": "Multi",
                "startYear": 2012,
                "endYear": 2017
            },
            {
                "make": "Hyundai",
                "model": "i40",
                "engine": "Multi",
                "startYear": 2011,
                "endYear": 2019
            },
            {
                "make": "Hyundai",
                "model": "Elantra",
                "engine": "1.6",
                "startYear": 2010,
                "endYear": 2015
            },
            {
                "make": "Hyundai",
                "model": "Elantra",
                "engine": "2.0",
                "startYear": 2014,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 273,
        "sku": "33-2942",
        "name": "33-2942",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/4438574273/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "320d",
                "engine": "2.0TD",
                "startYear": 2007,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 274,
        "sku": "HB.M1549.L",
        "name": "Aluminised Steel Bullet Resonator: 2″ x 15″",
        "category": 4,
        "subCategory": 0,
        "price": 150,
        "shopee": "https://shopee.com.my/product/119505399/1909842005/",
        "desc": [
            "Total length is 15 inches",
            "Inner diameter is 2 inches",
            "Pipe length from bullet resonator is 1.5 inches",
            "Resonator length is 12 inches",
            "Louvered tubes.",
            "Middle bullet sports resonators.",
            "Straight through direct Internal Design.",
            "Maximum gas flow, no restrictions at all."
        ],
        "imageCount": 3,
        "vehicles": []
    },
    {
        "id": 275,
        "sku": "HB.M1543L",
        "name": "Aluminised Steel Bullet Resonator: 1.75″ x 15″",
        "category": 4,
        "subCategory": 0,
        "price": 150,
        "shopee": "https://shopee.com.my/product/119505399/8698951689/",
        "desc": [
            "Total length is 15 inches",
            "Inner diameter is 1.75 inches",
            "Pipe length from bullet resonator is 1.5 inches",
            "Resonator length is 12 inches",
            "Louvered tubes.",
            "Middle bullet sports resonators.",
            "Straight through direct Internal Design.",
            "Maximum gas flow, no restrictions at all."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 276,
        "sku": "HB.M150.2T.P",
        "name": "Aluminised Steel Muffler - 2″ x 1.5″ (Rear Twin Tailpipe)",
        "category": 4,
        "subCategory": 0,
        "price": 280,
        "shopee": "https://shopee.com.my/product/119505399/2146184955/",
        "desc": [
            "Length is 16 inches.",
            "Inlet diameter is 1.75 inches.",
            "Outlet diameter is 1.5 inches.",
            "Middle oval sports muffler.",
            "Straight through with reserve flow twice internal design.",
            "Maximum gas flow, no restrictions at all.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 277,
        "sku": "HB.M150.P",
        "name": "Aluminised Steel Muffler - Centre 1.75″",
        "category": 4,
        "subCategory": 0,
        "price": 280,
        "shopee": "https://shopee.com.my/product/119505399/1907031417/",
        "desc": [
            "Length is 19 inches.",
            "Inlet diameter is 2 inches.",
            "Pipe length is 2 inches on each side.",
            "Rear oval sports muffler.",
            "Straight through with reserve flow twice internal design.",
            "Maximum gas flow, no restrictions at all.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 278,
        "sku": "HB.M160.P",
        "name": "Aluminised Steel Muffler - Centre 2″",
        "category": 4,
        "subCategory": 0,
        "price": 280,
        "shopee": "https://shopee.com.my/product/119505399/1910010909/",
        "desc": [
            "Length is 16 inches.",
            "Inlet diameter is 2 inches.",
            "Rear oval sports muffler.",
            "Straight through with reserve flow twice internal design.",
            "Maximum gas flow, no restrictions at all.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 279,
        "sku": "HB.M1068.L",
        "name": "Aluminised Steel Muffler - Off Set 2″",
        "category": 4,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1904212479/",
        "desc": [
            "Length is 18 inches.",
            "Inlet diameter is 2 inches.",
            "Rear oval sports muffler.",
            "Straight through with reserve flow twice internal design.",
            "Maximum gas flow, no restrictions at all.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 280,
        "sku": "HB.M536P",
        "name": "Aluminised Steel Muffler - Centre 1.75″",
        "category": 4,
        "subCategory": 0,
        "price": 180,
        "shopee": "https://shopee.com.my/product/119505399/1910101822/",
        "desc": [
            "Length is 14 inches.",
            "Inlet diameter is 1.75 inches.",
            "Middle oval sports muffler.",
            "Straight through with reserve flow twice internal design.",
            "Maximum gas flow, no restrictions at all.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 281,
        "sku": "",
        "name": "BME E90",
        "category": 10,
        "subCategory": 0,
        "price": 419,
        "shopee": "https://shopee.com.my/product/119505399/6208890289/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "BMW",
                "model": "320i",
                "engine": "2.0",
                "startYear": 2005,
                "endYear": 2012
            },
            {
                "make": "BMW",
                "model": "325i",
                "engine": "2.5",
                "startYear": 2005,
                "endYear": 2012
            },
            {
                "make": "BMW",
                "model": "330i",
                "engine": "3.0",
                "startYear": 2004,
                "endYear": 2011
            },
            {
                "make": "BMW",
                "model": "335i",
                "engine": "3.0",
                "startYear": 2006,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 282,
        "sku": "33-2252",
        "name": "33-2252",
        "category": 8,
        "subCategory": 0,
        "price": 330,
        "shopee": "https://shopee.com.my/product/119505399/2288157601/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Wish",
                "engine": "1.8",
                "startYear": 2003,
                "endYear": 2008
            },
            {
                "make": "Toyota",
                "model": "Wish",
                "engine": "2.0",
                "startYear": 2003,
                "endYear": 2008
            },
            {
                "make": "Toyota",
                "model": "Corolla Altis",
                "engine": "Multi",
                "startYear": 2008,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 283,
        "sku": "AX.BD 95-122",
        "name": "Toyota Camry Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 800,
        "shopee": "https://shopee.com.my/product/119505399/17124706118/",
        "desc": [
            "AXT High Quality Toyota Camry 2007 & Later Slotted Front Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 284,
        "sku": "AX.BD 95-123",
        "name": "Toyota Mark X Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 720,
        "shopee": "https://shopee.com.my/product/119505399/4703367518/",
        "desc": [
            "AXT High Quality Toyota Mark X 2.5 V6 (05~09) Slotted Rear Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Mark X",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 285,
        "sku": "33-2292",
        "name": "33-2292",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/6938538941/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "525i",
                "engine": "3.0",
                "startYear": 2006,
                "endYear": 2010
            },
            {
                "make": "BMW",
                "model": "525i",
                "engine": "2.5",
                "startYear": 2003,
                "endYear": 2008
            },
            {
                "make": "BMW",
                "model": "530i",
                "engine": "3.0",
                "startYear": 2003,
                "endYear": 2010
            },
            {
                "make": "BMW",
                "model": "630i",
                "engine": "3.0",
                "startYear": 2004,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 286,
        "sku": "RU-3600",
        "name": "RU-3600 Universal (Flange ID: 4″ Height: 5″)",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/2895500008/",
        "desc": [
            "Round tapered shape air filter.",
            "Flange internal diameter: 4 inches (102mm).",
            "Flange length: 0.625 inch (16mm)",
            "Filter height: 5 inches (127mm).",
            "Top external diameter: 4.5 inches (114mm).",
            "Bottom external diameter: 5.375 inches (137mm)."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 287,
        "sku": "33-5096",
        "name": "33-5096",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/12209564013/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "3",
                "engine": "2.0",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "Mazda",
                "model": "CX-30",
                "engine": "2.0",
                "startYear": 2019,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 288,
        "sku": "",
        "name": "Mercedes Benz 200E/230E Mid Section Supersprint Muffler Exhaust System",
        "category": 5,
        "subCategory": 0,
        "price": 1200,
        "shopee": "https://shopee.com.my/product/119505399/10454848258/",
        "desc": [
            "Rear oval sports muffler.",
            "Straight through direct internal design.",
            "Maximum gas flow, no restrictions at all.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "200E",
                "engine": "2.0",
                "startYear": 1985,
                "endYear": 1994
            },
            {
                "make": "Mercedes Benz",
                "model": "230E",
                "engine": "2.3",
                "startYear": 1985,
                "endYear": 1992
            }
        ]
    },
    {
        "id": 289,
        "sku": "",
        "name": "Mercedes Benz 260E/300E Mid Section Supersprint Muffler Exhaust System",
        "category": 5,
        "subCategory": 0,
        "price": 1200,
        "shopee": "https://shopee.com.my/product/119505399/10154857776/",
        "desc": [
            "Rear oval sports muffler.",
            "Straight through direct internal design.",
            "Maximum gas flow, no restrictions at all.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "260E",
                "engine": "2.6",
                "startYear": 1985,
                "endYear": 1993
            },
            {
                "make": "Mercedes Benz",
                "model": "300E",
                "engine": "3.0",
                "startYear": 1985,
                "endYear": 1995
            }
        ]
    },
    {
        "id": 291,
        "sku": "33-2162",
        "name": "33-2162",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/20381161251/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Jimny",
                "engine": "1.3",
                "startYear": 2003,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 292,
        "sku": "33-3151",
        "name": "33-3151",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/20381161251/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Jimny",
                "engine": "1.5",
                "startYear": 2018,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 293,
        "sku": "33-5057",
        "name": "33-5057",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1827608136/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Kia",
                "model": "Optima",
                "engine": "2.0T",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Kia",
                "model": "Optima",
                "engine": "1.6T",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Hyundai",
                "model": "Sonata",
                "engine": "2.0T",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Hyundai",
                "model": "Sonata",
                "engine": "1.6T",
                "startYear": 2015,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 294,
        "sku": "33-5026",
        "name": "33-5026",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1827608136/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Kia",
                "model": "Optima",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Kia",
                "model": "Optima",
                "engine": "2.4",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Hyundai",
                "model": "Sonata",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Hyundai",
                "model": "Sonata",
                "engine": "2.4",
                "startYear": 2015,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 295,
        "sku": "HB.TP SS44",
        "name": "S.Steel Car Exhaust Tips Tailpipe - 160mm(L), 86mm(ID), 110mm(OD)",
        "category": 6,
        "subCategory": 0,
        "price": 160,
        "shopee": "https://shopee.com.my/product/119505399/1921182400/",
        "desc": [
            "To weld onto actual exhaust to become part of the full exhaust system.",
            "All 304 polished stainless steel.",
            "Anti-corrosive and more durable.",
            "Length 160 mm.",
            "Inlet Diameter 86 mm.",
            "Outlet Diameter 110 mm."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 296,
        "sku": "",
        "name": "Honda Civic/CR-V 1.5T Open Pod Kit",
        "category": 8,
        "subCategory": 0,
        "price": 1200,
        "shopee": "https://shopee.com.my/product/119505399/6564350562/",
        "desc": [
            "Open pod kit includes heat shield."
        ],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.5T",
                "startYear": 2016,
                "endYear": 2021
            },
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "1.5T",
                "startYear": 2017,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 297,
        "sku": "",
        "name": "Chrome Car Exhaust Tips Tailpipe - 280mm(L), 55mm(ID), 60mm(OD)",
        "category": 6,
        "subCategory": 0,
        "price": 50,
        "shopee": "https://shopee.com.my/product/119505399/9970625022/",
        "desc": [
            "To weld onto actual exhaust to become part of the full exhaust system.",
            "Anti-corrosive and more durable.",
            "Length 280 mm.",
            "Inlet Diameter 55 mm.",
            "Outlet Diameter 60 mm."
        ],
        "imageCount": 3,
        "vehicles": []
    },
    {
        "id": 298,
        "sku": "HB.TP SS42",
        "name": "S.Steel Car Exhaust Tips Tailpipe - 180mm(L), 60mm(ID), 93mm(OD)",
        "category": 6,
        "subCategory": 0,
        "price": 140,
        "shopee": "https://shopee.com.my/product/119505399/7733650396/",
        "desc": [
            "To weld onto actual exhaust to become part of the full exhaust system.",
            "All 304 polished stainless steel.",
            "Anti-corrosive and more durable.",
            "Length 180 mm.",
            "Inlet Diameter 60 mm.",
            "Outlet Diameter 93 mm."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 299,
        "sku": "HB.TP SS43",
        "name": "S.Steel Car Exhaust Tips Tailpipe - 220mm(L), 73mm(ID), 160mm(OD)",
        "category": 6,
        "subCategory": 0,
        "price": 180,
        "shopee": "https://shopee.com.my/product/119505399/5733649102/",
        "desc": [
            "To weld onto actual exhaust to become part of the full exhaust system.",
            "All 304 polished stainless steel.",
            "Anti-corrosive and more durable.",
            "Length 220 mm.",
            "Inlet Diameter 73 mm.",
            "Outlet Diameter 160 mm."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 300,
        "sku": "HB.M867L",
        "name": "Aluminised Steel Muffler - Off Set 1.75″, Length 14″",
        "category": 4,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1910166224/",
        "desc": [
            "Length is 14 inches.",
            "Inlet diameter is 1.75 inches.",
            "Pipe Position is Off Set.",
            "Rear oval sports muffler.",
            "Straight through with reverse flow twice internal design.",
            "For maximum sound reduction with no restriction.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 301,
        "sku": "HB.M1069.L",
        "name": "Aluminised Steel Muffler - Off Set 2.5″, Length 18″",
        "category": 4,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/6351247209/",
        "desc": [
            "Length is 18 inches.",
            "Inlet diameter is 2.5 inches.",
            "Pipe Position is Off Set.",
            "Rear oval sports muffler.",
            "Straight through with reverse flow twice internal design.",
            "For maximum sound reduction with no restriction.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 3,
        "vehicles": []
    },
    {
        "id": 302,
        "sku": "HB.M534P",
        "name": "Aluminised Steel Oval S Muffler - Inlet 1.75″, Outlet 1.5″",
        "category": 4,
        "subCategory": 0,
        "price": 180,
        "shopee": "https://shopee.com.my/product/119505399/1910054098/",
        "desc": [
            "Length is 14 inches.",
            "Inlet diameter is 1.75 inches.",
            "Middle oval sports muffler.",
            "Straight through with reverse flow twice internal design.",
            "For maximum sound reduction with no restriction.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 303,
        "sku": "HB.M1069.L",
        "name": "Aluminised Steel Muffler - Off Set 2.5″, Length 14″",
        "category": 4,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1904240728/",
        "desc": [
            "Length is 14 inches.",
            "Inlet diameter is 2.5 inches.",
            "Rear oval sports muffler.",
            "Straight through with reverse flow twice internal design.",
            "For maximum sound reduction with no restriction.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 304,
        "sku": "HB.TP SS43",
        "name": "S.Steel Car Exhaust Tips Tailpipe - 210mm(L), 73mm(ID), 100mm(OD)",
        "category": 6,
        "subCategory": 0,
        "price": 120,
        "shopee": "https://shopee.com.my/product/119505399/1921164420/",
        "desc": [
            "To weld onto actual exhaust to become part of the full exhaust system.",
            "All 304 polished stainless steel.",
            "Anti-corrosive and more durable.",
            "Length 210 mm.",
            "Inlet Diameter 73 mm.",
            "Outlet Diameter 100 mm."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 305,
        "sku": "HB.TP SS42",
        "name": "S.Steel Car Exhaust Tips Tailpipe - 180mm(L), 60mm(ID), 93mm(OD)",
        "category": 6,
        "subCategory": 0,
        "price": 120,
        "shopee": "https://shopee.com.my/product/119505399/1921136112/",
        "desc": [
            "To weld onto actual exhaust to become part of the full exhaust system.",
            "All 304 polished stainless steel.",
            "Anti-corrosive and more durable.",
            "Length 180 mm.",
            "Inlet Diameter 93 mm.",
            "Outlet Diameter 93 mm."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 306,
        "sku": "HB.M170.P",
        "name": "Aluminised Steel Muffler - Centre 1.75″, Length 22″",
        "category": 4,
        "subCategory": 0,
        "price": 320,
        "shopee": "https://shopee.com.my/product/119505399/1921096777/",
        "desc": [
            "Length is 22 inches.",
            "Inlet diameter is 1.75 inches.",
            "Rear oval sports muffler.",
            "Straight through with reverse flow twice internal design.",
            "For maximum sound reduction with no restriction.",
            "Double Layer Aluminium Steel (same as OE muffler skin design) for low solid exhaust note."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 307,
        "sku": "33-2009",
        "name": "33-2009",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/4186676046/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Telstar TX5 EFI",
                "engine": "Multi",
                "startYear": 1986,
                "endYear": 1990
            },
            {
                "make": "Mazda",
                "model": "626 EFI",
                "engine": "Multi",
                "startYear": 1987,
                "endYear": 1991
            }
        ]
    },
    {
        "id": 308,
        "sku": "33-2010",
        "name": "33-2010",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/9545824051/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Skyline GT-R",
                "engine": "2.6T",
                "startYear": 1989,
                "endYear": 1994
            },
            {
                "make": "Nissan",
                "model": "Skyline GT-R",
                "engine": "Multi",
                "startYear": 1994,
                "endYear": 1999
            }
        ]
    },
    {
        "id": 309,
        "sku": "33-3024",
        "name": "33-3024",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/3713189892/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "CX-5",
                "engine": "2.5T",
                "startYear": 2019,
                "endYear": 2023
            },
            {
                "make": "Mazda",
                "model": "CX-9",
                "engine": "2.5",
                "startYear": 2016,
                "endYear": 2023
            },
            {
                "make": "Mazda",
                "model": "6",
                "engine": "2.5T",
                "startYear": 2018,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 310,
        "sku": "AX.BD 95-111",
        "name": "Toyota Land Cruiser 100 Rear Brake Discs",
        "category": 1,
        "subCategory": 102,
        "price": 800,
        "shopee": "https://shopee.com.my/product/119505399/4804518252/",
        "desc": [
            "Made from the highest grade cast iron to give vibration free and long mileage use."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.2D",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.5",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.7",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Lexus",
                "model": "LX 470",
                "engine": "4.7",
                "startYear": 1998,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 311,
        "sku": "",
        "name": "Toyota Altis Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 550,
        "shopee": "https://shopee.com.my/product/119505399/3833732997/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Corolla Altis",
                "engine": "1.6",
                "startYear": 2008,
                "endYear": 2012
            },
            {
                "make": "Toyota",
                "model": "Corolla Altis",
                "engine": "1.8",
                "startYear": 2008,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 312,
        "sku": "AX.BD 50-113",
        "name": "Honda CR-V II Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 630,
        "shopee": "https://shopee.com.my/product/119505399/2765745466/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "Multi",
                "startYear": 2002,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 313,
        "sku": "AX.BD 50-117",
        "name": "Honda Jazz Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/2765712936/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5",
                "startYear": 2014,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 314,
        "sku": "AX.BD 50-115",
        "name": "Honda Civic FD Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 600,
        "shopee": "https://shopee.com.my/product/119505399/2765681844/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 315,
        "sku": "AX.BD 55-101",
        "name": "Hyundai Elantra Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/2765434914/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Elantra",
                "engine": "Multi",
                "startYear": 2001,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 316,
        "sku": "AX.BD 59-100",
        "name": "Land Rover Discovery I/Defender 110 Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/2765383628/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Land Rover",
                "model": "Discovery",
                "engine": "Multi",
                "startYear": 1989,
                "endYear": 1998
            },
            {
                "make": "Land Rover",
                "model": "Defender",
                "engine": "Multi",
                "startYear": 1983,
                "endYear": 1990
            }
        ]
    },
    {
        "id": 317,
        "sku": "AX.BD 85-100",
        "name": "Perodua Kancil Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 400,
        "shopee": "https://shopee.com.my/product/119505399/2764813345/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Kancil",
                "engine": "Multi",
                "startYear": 1994,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 318,
        "sku": "AX.BD 90-109",
        "name": "Proton Perdana Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/2764696698/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Perdana",
                "engine": "2.0",
                "startYear": 1995,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 319,
        "sku": "AX.BD 90-109",
        "name": "Mitsubishi Galant Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 350,
        "shopee": "https://shopee.com.my/product/119505399/2764661542/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Galant",
                "engine": "2.0",
                "startYear": 1993,
                "endYear": 1996
            }
        ]
    },
    {
        "id": 320,
        "sku": "AX.BD 90-117",
        "name": "Proton Wira Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/2764555106/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Wira",
                "engine": "1.5",
                "startYear": 1993,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 321,
        "sku": "AX.BD 90-111",
        "name": "Proton Waja Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 310,
        "shopee": "https://shopee.com.my/product/119505399/2763925152/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Waja",
                "engine": "Multi",
                "startYear": 2000,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 322,
        "sku": "AX.BD 90-113",
        "name": "Proton GEN-2 Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 350,
        "shopee": "https://shopee.com.my/product/119505399/2763827075/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "GEN-2",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 323,
        "sku": "AX.BD 90-120",
        "name": "Mitsubishi Lancer CY Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/2763731646/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Lancer",
                "engine": "Multi",
                "startYear": 2008,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 324,
        "sku": "AX.BD 90-104",
        "name": "Proton Sage/Iswara Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/2763647654/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Saga",
                "engine": "Multi",
                "startYear": 1985,
                "endYear": 1992
            },
            {
                "make": "Proton",
                "model": "Iswara",
                "engine": "Multi",
                "startYear": 1992,
                "endYear": 2003
            }
        ]
    },
    {
        "id": 325,
        "sku": "AX.BD 95-110",
        "name": "Toyota Land Cruiser 100/Lexus 470 Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/2763615727/",
        "desc": [
            "AXT High Quality Land Cruiser 100/Lexus 470 Slotted Front Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.2D",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.5",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.7",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Lexus",
                "model": "LX 470",
                "engine": "4.7",
                "startYear": 1998,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 326,
        "sku": "AX.BD 95-103",
        "name": "Toyota Land Cruiser 100/Lexus 470 Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/2763595966/",
        "desc": [
            "AXT High Quality Land Cruiser 100/Lexus 470 Slotted Rear Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.2D",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.5",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Lexus",
                "model": "LX 470",
                "engine": "4.7",
                "startYear": 1998,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 327,
        "sku": "AX.BD 95-115",
        "name": "Toyota Land Cruiser Prado Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/2763560479/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser Prado",
                "engine": "Multi",
                "startYear": 1996,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 328,
        "sku": "AX.BD 50-109",
        "name": "Honda Civic ES Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/2765902070/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "Multi",
                "startYear": 2001,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 329,
        "sku": "AX.BD 50-105",
        "name": "Honda City Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 499,
        "shopee": "https://shopee.com.my/product/119505399/2765868979/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.3",
                "startYear": 1997,
                "endYear": 2001
            }
        ]
    },
    {
        "id": 330,
        "sku": "AX.BD 50-111",
        "name": "Honda Accord CL/CM Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/2765780683/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "Multi",
                "startYear": 2003,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 331,
        "sku": "",
        "name": "Toyota Wish Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 550,
        "shopee": "https://shopee.com.my/product/119505399/3534682363/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Wish",
                "engine": "1.8",
                "startYear": 2003,
                "endYear": 2008
            },
            {
                "make": "Toyota",
                "model": "Wish",
                "engine": "2.0",
                "startYear": 2003,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 332,
        "sku": "AXT BD 85-102",
        "name": "Perodua Myvi III Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 310,
        "shopee": "https://shopee.com.my/product/119505399/3838512000/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Myvi",
                "engine": "Multi",
                "startYear": 2017,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 333,
        "sku": "",
        "name": "Toyota Vios/Altis Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/4433624393/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Vios",
                "engine": "1.5",
                "startYear": 2003,
                "endYear": 2007
            },
            {
                "make": "Toyota",
                "model": "Altis",
                "engine": "Multi",
                "startYear": 2001,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 334,
        "sku": "AX.BD 95-114",
        "name": "Toyota Land Cruiser Prado Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/2032779776/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser Prado",
                "engine": "Multi",
                "startYear": 1996,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 335,
        "sku": "AX.BD 95-104",
        "name": "Toyota Hilux LN107/103 Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/2043606649/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Hilux",
                "engine": "2.8D",
                "startYear": 1998,
                "endYear": 2004
            },
            {
                "make": "Toyota",
                "model": "Hilux",
                "engine": "3.0D",
                "startYear": 1998,
                "endYear": 2004
            }
        ]
    },
    {
        "id": 336,
        "sku": "",
        "name": "Toyota Land Cruiser 200 Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/4934587546/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 200",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 337,
        "sku": "",
        "name": "Toyota Land Cruiser 200 Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/5134588605/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 200",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 338,
        "sku": "",
        "name": "Mitsubishi Lancer CY Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 550,
        "shopee": "https://shopee.com.my/product/119505399/5034586176/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Lancer",
                "engine": "Multi",
                "startYear": 2008,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 339,
        "sku": "",
        "name": "Volvo S40 Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 720,
        "shopee": "https://shopee.com.my/product/119505399/6334589326/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Volvo",
                "model": "S40",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 340,
        "sku": "AX.BD 50-110",
        "name": "Honda Accord CL/CM Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 550,
        "shopee": "https://shopee.com.my/product/119505399/6733617642/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "Multi",
                "startYear": 2003,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 341,
        "sku": "AX.BD 95-102",
        "name": "Toyota Land Cruiser 80 Slotted Front Brake Discs (16″ Rims)",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/6604514156/",
        "desc": [
            "AXT High Quality Land Cruiser 80 Slotted Front Brake Discs Set of 2 pcs.",
            "For 16 inch rims.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 80",
                "engine": "Multi",
                "startYear": 1990,
                "endYear": 1997
            }
        ]
    },
    {
        "id": 342,
        "sku": "AX.BD 95-103",
        "name": "Toyota Land Cruiser 80 Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 900,
        "shopee": "https://shopee.com.my/product/119505399/7104511903/",
        "desc": [
            "AXT High Quality Land Cruiser 80 Slotted Front Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 80",
                "engine": "Multi",
                "startYear": 1990,
                "endYear": 1997
            }
        ]
    },
    {
        "id": 343,
        "sku": "AX.BD 95-110",
        "name": "Toyota Land Cruiser 100 Front Brake Discs",
        "category": 1,
        "subCategory": 102,
        "price": 800,
        "shopee": "https://shopee.com.my/product/119505399/7504513846/",
        "desc": [
            "Made from the highest grade cast iron to give vibration free and long mileage use."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.2D",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.5",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 100",
                "engine": "4.7",
                "startYear": 1998,
                "endYear": 2006
            },
            {
                "make": "Lexus",
                "model": "LX 470",
                "engine": "4.7",
                "startYear": 1998,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 344,
        "sku": "AX.BD 90-112",
        "name": "Proton GEN-2 Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/2032779776/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "GEN-2",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 345,
        "sku": "",
        "name": "Toyota Land Cruiser 200 Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/7034588083/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 200",
                "engine": "4.5TD",
                "startYear": 2008,
                "endYear": 2021
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 200",
                "engine": "4.6",
                "startYear": 2008,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 346,
        "sku": "",
        "name": "Toyota Land Cruiser 200 Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/7434588648/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 200",
                "engine": "4.5TD",
                "startYear": 2008,
                "endYear": 2021
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 200",
                "engine": "4.6",
                "startYear": 2008,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 347,
        "sku": "",
        "name": "Toyota Wish Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 550,
        "shopee": "https://shopee.com.my/product/119505399/7434584972/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Wish",
                "engine": "1.8",
                "startYear": 2003,
                "endYear": 2008
            },
            {
                "make": "Toyota",
                "model": "Wish",
                "engine": "2.0",
                "startYear": 2003,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 348,
        "sku": "AX.BD 50-118",
        "name": "Honda Jazz Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 550,
        "shopee": "https://shopee.com.my/product/119505399/7334003791/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5",
                "startYear": 2014,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 349,
        "sku": "AX.BD 95-101",
        "name": "Toyota Land Cruiser 80 Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 880,
        "shopee": "https://shopee.com.my/product/119505399/7804513140/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 80",
                "engine": "Multi",
                "startYear": 1990,
                "endYear": 1997
            },
            {
                "make": "Toyota",
                "model": "Land Cruiser 80",
                "engine": "Multi",
                "startYear": 1990,
                "endYear": 1997
            }
        ]
    },
    {
        "id": 350,
        "sku": "AX.BD 95-113",
        "name": "Proton Satria Neo Slotted Rear Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 550,
        "shopee": "https://shopee.com.my/product/119505399/2763964376/",
        "desc": [
            "AXT High Quality REAR Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria Neo",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 351,
        "sku": "AXT 90-103",
        "name": "Proton Waja Slotted Front Brake Discs",
        "category": 1,
        "subCategory": 103,
        "price": 580,
        "shopee": "https://shopee.com.my/product/119505399/12161951090/",
        "desc": [
            "AXT High Quality FRONT Brake Discs Set of 2 pcs.",
            "Made from the highest grade cast iron to give vibration free and long mileage use.",
            "Slotted for better ventilation and self cleaning of brake pads."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Waja",
                "engine": "Multi",
                "startYear": 2000,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 352,
        "sku": "HB.CS 50-795",
        "name": "Honda CR-X - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 280,
        "shopee": "https://shopee.com.my/product/119505399/2804248376/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-X",
                "engine": "Multi",
                "startYear": 1988,
                "endYear": 1991
            }
        ]
    },
    {
        "id": 353,
        "sku": "HB.CS 45-197",
        "name": "Fiat Bravo/Brava - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/2803651953/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Fiat",
                "model": "Bravo/Brava",
                "engine": "Multi",
                "startYear": 1995,
                "endYear": 2003
            }
        ]
    },
    {
        "id": 354,
        "sku": "HB.CS 45-196",
        "name": "Fiat Punto/Punto GT - Front",
        "category": 2,
        "subCategory": 201,
        "price": 160,
        "shopee": "https://shopee.com.my/product/119505399/2802201584/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Fiat",
                "model": "Punto",
                "engine": "Multi",
                "startYear": 1993,
                "endYear": 1999
            },
            {
                "make": "Fiat",
                "model": "Punto GT",
                "engine": "Multi",
                "startYear": 1993,
                "endYear": 1999
            }
        ]
    },
    {
        "id": 355,
        "sku": "HB.CS 30-197",
        "name": "Renault Megane I - Front",
        "category": 2,
        "subCategory": 201,
        "price": 480,
        "shopee": "https://shopee.com.my/product/119505399/2802113827/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Renault",
                "model": "Megane",
                "engine": "Multi",
                "startYear": 1996,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 356,
        "sku": "HB.CS 10-201",
        "name": "Alfa Romeo GTV/Spider (Type 916) - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 480,
        "shopee": "https://shopee.com.my/product/119505399/2777037264/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Alfa Romeo",
                "model": "GTV",
                "engine": "Multi",
                "startYear": 1995,
                "endYear": 2005
            },
            {
                "make": "Alfa Romeo",
                "model": "Spider",
                "engine": "Multi",
                "startYear": 1995,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 357,
        "sku": "HB.CS 10-103",
        "name": "Alfa Romeo 164 - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 480,
        "shopee": "https://shopee.com.my/product/119505399/2777009199/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Alfa Romeo",
                "model": "164",
                "engine": "Multi",
                "startYear": 1987,
                "endYear": 1998
            }
        ]
    },
    {
        "id": 358,
        "sku": "",
        "name": "Nissan Sunny B14 - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/8479996053/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Sunny",
                "engine": "Multi",
                "startYear": 1995,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 359,
        "sku": "HB.CS 75-395",
        "name": "Mitsubishi Super Touring/Galant (E30) - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/2848755698/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Galant",
                "engine": "1.8",
                "startYear": 1988,
                "endYear": 1992
            },
            {
                "make": "Mitsubishi",
                "model": "Galant",
                "engine": "2.0",
                "startYear": 1988,
                "endYear": 1992
            }
        ]
    },
    {
        "id": 360,
        "sku": "HB.CS 55-195",
        "name": "Hyundai Scoupe - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/2848564760/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Scoupe",
                "engine": "1.5",
                "startYear": 1988,
                "endYear": 1995
            }
        ]
    },
    {
        "id": 361,
        "sku": "HB.CS 20-595",
        "name": "BMW E34 - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 650,
        "shopee": "https://shopee.com.my/product/119505399/2797383028/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "BMW",
                "model": "520i",
                "engine": "2.0",
                "startYear": 1988,
                "endYear": 1996
            },
            {
                "make": "BMW",
                "model": "525i",
                "engine": "2.5",
                "startYear": 1988,
                "endYear": 1996
            },
            {
                "make": "BMW",
                "model": "535i",
                "engine": "3.5",
                "startYear": 1987,
                "endYear": 1992
            }
        ]
    },
    {
        "id": 362,
        "sku": "HB.CS 90-895",
        "name": "Proton Satria 1.6/GTi/R3 - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/9614459085/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria",
                "engine": "Multi",
                "startYear": 1994,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 363,
        "sku": "HB.CS 75-295",
        "name": "Mitsubishi Colt CA - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 280,
        "shopee": "https://shopee.com.my/product/119505399/2848831295/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Colt",
                "engine": "1.3",
                "startYear": 1992,
                "endYear": 1995
            },
            {
                "make": "Mitsubishi",
                "model": "Colt",
                "engine": "1.6",
                "startYear": 1992,
                "endYear": 1995
            }
        ]
    },
    {
        "id": 364,
        "sku": "HB.CS 75-195",
        "name": "Mitsubishi Galant - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 350,
        "shopee": "https://shopee.com.my/product/119505399/2848806846/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Galant",
                "engine": "1.8",
                "startYear": 1993,
                "endYear": 1996
            },
            {
                "make": "Mitsubishi",
                "model": "Galant",
                "engine": "2.0",
                "startYear": 1993,
                "endYear": 1996
            }
        ]
    },
    {
        "id": 365,
        "sku": "HB.CS 50-895",
        "name": "Honda CR-X - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 280,
        "shopee": "https://shopee.com.my/product/119505399/2804232735/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-X",
                "engine": "Multi",
                "startYear": 1992,
                "endYear": 1998
            }
        ]
    },
    {
        "id": 366,
        "sku": "HB.CS 50-295",
        "name": "Honda Civic AF/AS - Rear",
        "category": 2,
        "subCategory": 202,
        "price": 120,
        "shopee": "https://shopee.com.my/product/119505399/2804098027/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "Multi",
                "startYear": 1984,
                "endYear": 1987
            }
        ]
    },
    {
        "id": 367,
        "sku": "HB.CS 20-595",
        "name": "Subaru WRX Turbo - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 480,
        "shopee": "https://shopee.com.my/product/119505399/2797687743/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Subaru",
                "model": "Impreza WRX",
                "engine": "2.2T",
                "startYear": 1992,
                "endYear": 2000
            },
            {
                "make": "Subaru",
                "model": "Impreza WRX",
                "engine": "2.0T",
                "startYear": 1992,
                "endYear": 2000
            }
        ]
    },
    {
        "id": 368,
        "sku": "HB.CS 85-101",
        "name": "Perodua Kenari/Kelisa - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/2848899094/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Kenari",
                "engine": "1.0",
                "startYear": 2000,
                "endYear": 2009
            },
            {
                "make": "Perodua",
                "model": "Kelisa",
                "engine": "Multi",
                "startYear": 2001,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 369,
        "sku": "AXT 9008P",
        "name": "Proton Waja 1.8 Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 40,
        "shopee": "https://shopee.com.my/product/119505399/2763167124/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Waja",
                "engine": "1.8",
                "startYear": 2000,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 370,
        "sku": "AXT 9010P",
        "name": "Proton Waja 1.6/1.8 Rear Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 40,
        "shopee": "https://seller.shopee.com.my/portal/product/list/all",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Waja",
                "engine": "Multi",
                "startYear": 2000,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 371,
        "sku": "AXT 9018P",
        "name": "Proton GEN-2 Rear Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 40,
        "shopee": "https://shopee.com.my/product/119505399/2763127206/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "GEN-2",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 372,
        "sku": "AXT 8503P",
        "name": "Perodua Kenari Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 40,
        "shopee": "https://shopee.com.my/product/119505399/2763394359/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Kenari",
                "engine": "1.0",
                "startYear": 2000,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 373,
        "sku": "AXT 9200P",
        "name": "Suzuki Swift 1.5 Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 58,
        "shopee": "https://shopee.com.my/product/119505399/2763381800/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Swift",
                "engine": "1.5",
                "startYear": 2004,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 374,
        "sku": "AXT 8502P",
        "name": "Perodua Kembara Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 45,
        "shopee": "https://shopee.com.my/product/119505399/2763334469/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Kembara",
                "engine": "1.3",
                "startYear": 1998,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 375,
        "sku": "AXT 8504P",
        "name": "Perodua Myvi I Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 35,
        "shopee": "https://shopee.com.my/product/119505399/2763321790/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Myvi",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 376,
        "sku": "AXT 9002P",
        "name": "Proton Wira Rear Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 40,
        "shopee": "https://shopee.com.my/product/119505399/2763305356/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Wira",
                "engine": "1.6",
                "startYear": 1993,
                "endYear": 2009
            },
            {
                "make": "Proton",
                "model": "Wira",
                "engine": "1.8",
                "startYear": 1993,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 377,
        "sku": "AXT 9000P",
        "name": "Proton Iswara Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 40,
        "shopee": "https://shopee.com.my/product/119505399/2763278261/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Iswara",
                "engine": "1.3",
                "startYear": 1992,
                "endYear": 2003
            },
            {
                "make": "Proton",
                "model": "Iswara",
                "engine": "1.5",
                "startYear": 1992,
                "endYear": 2003
            }
        ]
    },
    {
        "id": 378,
        "sku": "",
        "name": "Nissan Sunny B11 - Front & Rear",
        "category": 2,
        "subCategory": 203,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/10687221026/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Sunny",
                "engine": "Multi",
                "startYear": 1982,
                "endYear": 1996
            }
        ]
    },
    {
        "id": 379,
        "sku": "AXT 9012P",
        "name": "Mitsubishi Pajero Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 110,
        "shopee": "https://shopee.com.my/product/119505399/4333294051/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Pajero",
                "engine": "2.8",
                "startYear": 1992,
                "endYear": 1999
            },
            {
                "make": "Mitsubishi",
                "model": "Pajero",
                "engine": "3.0",
                "startYear": 1992,
                "endYear": 1999
            },
            {
                "make": "Mitsubishi",
                "model": "Pajero",
                "engine": "3.5",
                "startYear": 1992,
                "endYear": 1999
            }
        ]
    },
    {
        "id": 380,
        "sku": "",
        "name": "Hyundai Getz Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 75,
        "shopee": "https://shopee.com.my/product/119505399/6333292832/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Getz",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 381,
        "sku": "AXT 9014P",
        "name": "Mitsubishi Evolution V to VIII Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 110,
        "shopee": "https://shopee.com.my/product/119505399/4833271990/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Lancer Evolution",
                "engine": "2.0T",
                "startYear": 1998,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 382,
        "sku": "AXT 9506P",
        "name": "Toyota Land Cruiser 80 Rear Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 120,
        "shopee": "https://shopee.com.my/product/119505399/5433272585/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser 80",
                "engine": "4.5",
                "startYear": 1990,
                "endYear": 1997
            }
        ]
    },
    {
        "id": 383,
        "sku": "AXT 8501P",
        "name": "Perodua Kancil Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 40,
        "shopee": "https://shopee.com.my/product/119505399/5633266748/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Kancil",
                "engine": "Multi",
                "startYear": 1994,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 384,
        "sku": "AXT 9015P",
        "name": "Mitsubishi Evolution VII/VIII Rear Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 110,
        "shopee": "https://shopee.com.my/product/119505399/7433270201/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Lancer Evolution",
                "engine": "2.0T",
                "startYear": 2001,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 385,
        "sku": "AXT 9017P",
        "name": "Proton GEN-2 Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 42,
        "shopee": "https://shopee.com.my/product/119505399/2763185824/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "GEN-2",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 386,
        "sku": "AXT 9007P",
        "name": "Proton Waja 1.6 Front Brake Pads",
        "category": 1,
        "subCategory": 101,
        "price": 42,
        "shopee": "https://shopee.com.my/product/119505399/3540391937/",
        "desc": [
            "High performance, asbestos-free brake pads, with special formulated compound.",
            "Effective braking with long life, minimum fading and minimum dust."
        ],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Waja",
                "engine": "1.6",
                "startYear": 2000,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 387,
        "sku": "OCK-004-12",
        "name": "Universal Auto Transmission Oil Cooler Kit",
        "category": 99,
        "subCategory": 0,
        "price": 280,
        "shopee": "https://shopee.com.my/product/119505399/2759398996/",
        "desc": [
            "12 Rows of Cooling Coil.",
            "Universal Fit.",
            "Suit all auto & manual gearboxes.",
            "Increase gearbox cooling to prevent overheating and cause engine check light.",
            "Easy to fit."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 388,
        "sku": "",
        "name": "Nissan X-Trail",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2901802195/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "X-Trail",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 389,
        "sku": "",
        "name": "Honda CR-V",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4064359269/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "Multi",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 390,
        "sku": "",
        "name": "Proton Persona",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4632947086/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Persona",
                "engine": "1.6",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 391,
        "sku": "",
        "name": "Proton Exora",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6032945035/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Exora",
                "engine": "1.6",
                "startYear": 2009,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 392,
        "sku": "SKU 711",
        "name": "Honda HR-V",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7232069483/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "HR-V",
                "engine": "1.8",
                "startYear": 2015,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 393,
        "sku": "",
        "name": "Mercedes Benz C-Class (W204/205)",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7804953293/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "C180",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2019
            },
            {
                "make": "Mercedes Benz",
                "model": "C200",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2019
            },
            {
                "make": "Mercedes Benz",
                "model": "C230",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2010
            },
            {
                "make": "Mercedes Benz",
                "model": "C250",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 394,
        "sku": "SKU 708",
        "name": "Honda Civic FD",
        "category": 10,
        "subCategory": 0,
        "price": 389,
        "shopee": "https://shopee.com.my/product/119505399/5087419743/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 395,
        "sku": "",
        "name": "Proton Wira Front Brake Discs",
        "category": 1,
        "subCategory": 102,
        "price": 130,
        "shopee": "https://shopee.com.my/product/119505399/17717051299/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Wira",
                "engine": "1.5",
                "startYear": 1993,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 396,
        "sku": "",
        "name": "Honda CR-Z Open Pod Kit",
        "category": 8,
        "subCategory": 0,
        "price": 1200,
        "shopee": "https://shopee.com.my/product/119505399/16012922128/",
        "desc": [
            "Open pod kit includes heat shield."
        ],
        "imageCount": 3,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-Z",
                "engine": "1.5",
                "startYear": 2010,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 397,
        "sku": "88-12062",
        "name": "Original K&N Fashion Cap",
        "category": 9,
        "subCategory": 0,
        "price": 35,
        "shopee": "https://shopee.com.my/product/119505399/2759872243/",
        "desc": [],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 398,
        "sku": "33-2877",
        "name": "33-2877",
        "category": 8,
        "subCategory": 0,
        "price": 460,
        "shopee": "https://shopee.com.my/product/119505399/5013383489/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volvo",
                "model": "S40",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2007
            },
            {
                "make": "Volvo",
                "model": "V50",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2007
            },
            {
                "make": "Volvo",
                "model": "C30",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 399,
        "sku": "",
        "name": "Suzuki Swift Open Pod Kit",
        "category": 8,
        "subCategory": 0,
        "price": 1100,
        "shopee": "https://shopee.com.my/product/119505399/12458822928/",
        "desc": [
            "Open pod kit includes heat shield."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Swift",
                "engine": "1.5",
                "startYear": 2004,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 400,
        "sku": "33-2004",
        "name": "33-2004",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/16210586308/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Porsche",
                "model": "911",
                "engine": "3",
                "startYear": 1974,
                "endYear": 1983
            },
            {
                "make": "Porsche",
                "model": "911",
                "engine": "2.7",
                "startYear": 1972,
                "endYear": 1979
            }
        ]
    },
    {
        "id": 401,
        "sku": "33-2705",
        "name": "33-2705",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/6438124923/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "E280",
                "engine": "2.8",
                "startYear": 1992,
                "endYear": 1993
            },
            {
                "make": "Mercedes Benz",
                "model": "E320",
                "engine": "3.2",
                "startYear": 1992,
                "endYear": 1993
            }
        ]
    },
    {
        "id": 402,
        "sku": "33-2372",
        "name": "33-2372",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/5238571016/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "Z4",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2009
            },
            {
                "make": "BMW",
                "model": "X3",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 403,
        "sku": "33-2402",
        "name": "33-2402",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/7439779520/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "2.4",
                "startYear": 2008,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 404,
        "sku": "RC-9270",
        "name": "RC-9270 Universal (Flange ID: 3.125″ Height: 3.938″)",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/17507174407/",
        "desc": [
            "Round tapered shape air filter.",
            "Flange internal diameter: 3.125 inches (79mm).",
            "Flange length: 0.75 inch (19mm)",
            "Top external diameter: 3.5 inches (89mm).",
            "Bottom external diameter: 4.938 inches (125mm).",
            "Height: 3.938 inches (100mm)."
        ],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 405,
        "sku": "33-2129",
        "name": "33-2129",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/16205882460/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Chevrolet",
                "model": "Suburban",
                "engine": "5.3",
                "startYear": 2015,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 406,
        "sku": "33-2844",
        "name": "33-2844",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/16605877952/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Citreon",
                "model": "C-Elysee",
                "engine": "1.6",
                "startYear": 2012,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 407,
        "sku": "33-2787",
        "name": "33-2787",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/16405876135/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Opel",
                "model": "Zafira",
                "engine": "Multi",
                "startYear": 1999,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 408,
        "sku": "33-2255",
        "name": "33-2255",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/3738660262/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "X5",
                "engine": "3.0",
                "startYear": 2000,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 409,
        "sku": "",
        "name": "Toyota Vios",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2732758564/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Vios",
                "engine": "1.5",
                "startYear": 2006,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 410,
        "sku": "SKU 830",
        "name": "Toyota Fortuner",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7632070459/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Fortuner",
                "engine": "Multi",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 411,
        "sku": "SKU 830",
        "name": "Lexus IS220/IS250/IS350",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7232072427/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Lexus",
                "model": "IS220d",
                "engine": "2.2TD",
                "startYear": 2006,
                "endYear": 2009
            },
            {
                "make": "Lexus",
                "model": "IS250",
                "engine": "2.5",
                "startYear": 2006,
                "endYear": 2023
            },
            {
                "make": "Lexus",
                "model": "IS350",
                "engine": "3.5",
                "startYear": 2006,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 412,
        "sku": "SKU 830",
        "name": "Toyota Innova",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6832070637/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Innova",
                "engine": "2.0",
                "startYear": 2016,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 413,
        "sku": "SKU 830",
        "name": "Toyota Mark X",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6332070235/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Mark X",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 414,
        "sku": "SKU 830",
        "name": "Perodua Bezza",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4711135487/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Bezza",
                "engine": "Multi",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 415,
        "sku": "SKU 830",
        "name": "Mazda 2",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/5632072270/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "2",
                "engine": "1.5",
                "startYear": 2006,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 416,
        "sku": "SKU 830",
        "name": "Perodua Aruz",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7043060044/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Aruz",
                "engine": "1.5",
                "startYear": 2019,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 417,
        "sku": "SKU 830",
        "name": "Toyota Hilux Revo",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6932070584/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Hilux Revo",
                "engine": "2.5TD",
                "startYear": 2016,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "Hilux Revo",
                "engine": "2.8TD",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 418,
        "sku": "SKU 830",
        "name": "Toyota C-HR",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/3932167575/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "C-HR",
                "engine": "1.8",
                "startYear": 2012,
                "endYear": 2017
            },
            {
                "make": "Toyota",
                "model": "C-HR",
                "engine": "1.2",
                "startYear": 2012,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 419,
        "sku": "33-5016",
        "name": "33-5016",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/6613380052/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "X-Trail",
                "engine": "Multi",
                "startYear": 2014,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 421,
        "sku": "33-5032",
        "name": "33-5032",
        "category": 8,
        "subCategory": 0,
        "price": 399,
        "shopee": "https://shopee.com.my/product/119505399/3508152717/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "C400",
                "engine": "3.0",
                "startYear": 2015,
                "endYear": 2022
            },
            {
                "make": "Mercedes Benz",
                "model": "C43",
                "engine": "3.0",
                "startYear": 2016,
                "endYear": 2022
            },
            {
                "make": "Mercedes Benz",
                "model": "GLE450",
                "engine": "3.0",
                "startYear": 2015,
                "endYear": 2018
            },
            {
                "make": "Mercedes Benz",
                "model": "GLE43",
                "engine": "3.0",
                "startYear": 2016,
                "endYear": 2019
            },
            {
                "make": "Mercedes Benz",
                "model": "S500",
                "engine": "3.0",
                "startYear": 2014,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 422,
        "sku": "33-2375",
        "name": "33-2375",
        "category": 8,
        "subCategory": 0,
        "price": 329,
        "shopee": "https://shopee.com.my/product/119505399/6613379609/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Sylphy",
                "engine": "2.0",
                "startYear": 2008,
                "endYear": 2016
            },
            {
                "make": "Nissan",
                "model": "Sylphy",
                "engine": "1.8",
                "startYear": 2014,
                "endYear": 2016
            },
            {
                "make": "Nissan",
                "model": "Latio",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2014
            },
            {
                "make": "Nissan",
                "model": "Grand Livinia",
                "engine": "Multi",
                "startYear": 2008,
                "endYear": 2019
            },
            {
                "make": "Nissan",
                "model": "Almera",
                "engine": "1.5",
                "startYear": 2012,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 423,
        "sku": "",
        "name": "Mercedes Benz CLA-Class (W117)",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7659213251/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "CLA45",
                "engine": "2.0",
                "startYear": 2013,
                "endYear": 2018
            },
            {
                "make": "Mercedes Benz",
                "model": "CLA180",
                "engine": "1.6",
                "startYear": 2013,
                "endYear": 2018
            },
            {
                "make": "Mercedes Benz",
                "model": "CLA200",
                "engine": "1.6",
                "startYear": 2014,
                "endYear": 2018
            },
            {
                "make": "Mercedes Benz",
                "model": "CLA220",
                "engine": "2.0",
                "startYear": 2018,
                "endYear": 2018
            },
            {
                "make": "Mercedes Benz",
                "model": "CLA250",
                "engine": "2.0",
                "startYear": 2018,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 424,
        "sku": "33-2970",
        "name": "33-2970",
        "category": 8,
        "subCategory": 0,
        "price": 389,
        "shopee": "https://shopee.com.my/product/119505399/2763083573/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "528i",
                "engine": "3.0",
                "startYear": 2010,
                "endYear": 2011
            },
            {
                "make": "BMW",
                "model": "530i",
                "engine": "3.0",
                "startYear": 2011,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 425,
        "sku": "",
        "name": "Mitsubishi Lancer/Proton Inspira",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2732693591/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Lancer",
                "engine": "Multi",
                "startYear": 2008,
                "endYear": 2017
            },
            {
                "make": "Proton",
                "model": "Inspira",
                "engine": "Multi",
                "startYear": 2010,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 426,
        "sku": "33-2703",
        "name": "33-2703",
        "category": 8,
        "subCategory": 0,
        "price": 400,
        "shopee": "https://shopee.com.my/product/119505399/4055019385/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "C180",
                "engine": "1.8",
                "startYear": 1993,
                "endYear": 2000
            },
            {
                "make": "Mercedes Benz",
                "model": "C200",
                "engine": "2.0",
                "startYear": 1994,
                "endYear": 2001
            },
            {
                "make": "Mercedes Benz",
                "model": "C220",
                "engine": "2.2",
                "startYear": 1993,
                "endYear": 2000
            },
            {
                "make": "Mercedes Benz",
                "model": "C280",
                "engine": "2.8",
                "startYear": 1993,
                "endYear": 2001
            }
        ]
    },
    {
        "id": 427,
        "sku": "33-2332",
        "name": "33-2332",
        "category": 8,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/7138572978/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "325i",
                "engine": "3.0",
                "startYear": 2006,
                "endYear": 2012
            },
            {
                "make": "BMW",
                "model": "328i",
                "engine": "3.0",
                "startYear": 2007,
                "endYear": 2013
            },
            {
                "make": "BMW",
                "model": "330i",
                "engine": "3.0",
                "startYear": 2006,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 428,
        "sku": "33-2433",
        "name": "33-2433",
        "category": 8,
        "subCategory": 0,
        "price": 339,
        "shopee": "https://shopee.com.my/product/119505399/2266406205/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5 Hybrid",
                "startYear": 2017,
                "endYear": 2019
            },
            {
                "make": "Honda",
                "model": "Insight",
                "engine": "1.3 Hybrid",
                "startYear": 2011,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 429,
        "sku": "33-2961",
        "name": "33-2961",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/2266385950/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "2.0",
                "startYear": 2007,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 430,
        "sku": "33-3094",
        "name": "33-3094",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/11154188456/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "2.0",
                "startYear": 2013,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 431,
        "sku": "HB.M975.SSP",
        "name": "S.Steel Bullet Resonator: 3″ x 9″",
        "category": 4,
        "subCategory": 0,
        "price": 170,
        "shopee": "https://shopee.com.my/product/119505399/1910218880/",
        "desc": [
            "Total length is 9 inches",
            "Inner diameter is 3 inches",
            "Pipe length from bullet resonator is 1.5 inches",
            "Resonator length is 6 inches",
            "Middle bullet sports resonators - 100 % 304 stainless steel; perforated with stainless and glass wool.",
            "Straight through direct Internal Design.",
            "Maximum gas flow, no restrictions at all."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 432,
        "sku": "33-2059",
        "name": "33-2059",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/4933822913/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "850Ci",
                "engine": "5.4",
                "startYear": 1995,
                "endYear": 1999
            },
            {
                "make": "BMW",
                "model": "318i",
                "engine": "1.8",
                "startYear": 1987,
                "endYear": 1995
            },
            {
                "make": "BMW",
                "model": "316i",
                "engine": "1.6",
                "startYear": 1987,
                "endYear": 1995
            },
            {
                "make": "BMW",
                "model": "518i",
                "engine": "1.8",
                "startYear": 1989,
                "endYear": 1995
            },
            {
                "make": "BMW",
                "model": "750i",
                "engine": "5.0",
                "startYear": 1987,
                "endYear": 1994
            }
        ]
    },
    {
        "id": 433,
        "sku": "",
        "name": "Toyota Estima",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/3708657450/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Estima",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 434,
        "sku": "E-2011",
        "name": "E-2011",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/5417311508/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "C180 Kompressor",
                "engine": "1.8",
                "startYear": 2002,
                "endYear": 2009
            },
            {
                "make": "Mercedes Benz",
                "model": "C180 Kompressor",
                "engine": "1.6",
                "startYear": 2009,
                "endYear": 2011
            },
            {
                "make": "Mercedes Benz",
                "model": "C200 Kompressor",
                "engine": "1.8",
                "startYear": 2002,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 435,
        "sku": "SKU 830",
        "name": "Lexus CT200h",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7032071863/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Lexus",
                "model": "CT200h",
                "engine": "1.8",
                "startYear": 2011,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 436,
        "sku": "33-2477",
        "name": "33-2477",
        "category": 8,
        "subCategory": 0,
        "price": 384,
        "shopee": "https://shopee.com.my/product/119505399/2266348243/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "2.4",
                "startYear": 2013,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 437,
        "sku": "SKU 988",
        "name": "BMW 2, 3, 4, 5, 6, 7 Series",
        "category": 10,
        "subCategory": 0,
        "price": 419,
        "shopee": "https://shopee.com.my/product/119505399/5958505291/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "BMW",
                "model": "2 Series",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "3 Series",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "4 Series",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "5 Series",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "6 Series",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2023
            },
            {
                "make": "BMW",
                "model": "7 Series",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 438,
        "sku": "33-2393",
        "name": "33-2393",
        "category": 8,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/14922950516/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Mondeo",
                "engine": "2.3",
                "startYear": 2007,
                "endYear": 2014
            },
            {
                "make": "Ford",
                "model": "Mondeo",
                "engine": "2.0",
                "startYear": 2007,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 439,
        "sku": "",
        "name": "Lexus IS250/Toyota Mark X Open Pod Kit",
        "category": 8,
        "subCategory": 0,
        "price": 1200,
        "shopee": "https://shopee.com.my/product/119505399/6458149566/",
        "desc": [
            "Open pod kit includes heat shield."
        ],
        "imageCount": 2,
        "vehicles": [
            {
                "make": "Lexus",
                "model": "IS250",
                "engine": "2.5",
                "startYear": 2006,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "Mark X",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 440,
        "sku": "33-2472",
        "name": "33-2472",
        "category": 8,
        "subCategory": 0,
        "price": 369,
        "shopee": "https://shopee.com.my/product/119505399/2921970448/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Veloster",
                "engine": "1.6",
                "startYear": 2011,
                "endYear": 2017
            },
            {
                "make": "Kia",
                "model": "Rio",
                "engine": "1.4",
                "startYear": 2011,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 441,
        "sku": "33-2955",
        "name": "33-2955",
        "category": 8,
        "subCategory": 0,
        "price": 330,
        "shopee": "https://shopee.com.my/product/119505399/3236526157/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Fiesta",
                "engine": "1.6",
                "startYear": 2009,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 442,
        "sku": "33-2359",
        "name": "33-2359",
        "category": 8,
        "subCategory": 0,
        "price": 350,
        "shopee": "https://shopee.com.my/product/119505399/3588106442/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5 i-VTEC",
                "startYear": 2009,
                "endYear": 2013
            },
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.5",
                "startYear": 2009,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 443,
        "sku": "33-2160",
        "name": "33-2160",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/3333917205/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volvo",
                "model": "S60",
                "engine": "2.3",
                "startYear": 2001,
                "endYear": 2004
            }
        ]
    },
    {
        "id": 444,
        "sku": "33-2184",
        "name": "33-2184",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/7355016641/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "E430",
                "engine": "4.3",
                "startYear": 1999,
                "endYear": 2003
            },
            {
                "make": "Mercedes Benz",
                "model": "E500",
                "engine": "5.0",
                "startYear": 2003,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 445,
        "sku": "33-2128",
        "name": "33-2128",
        "category": 8,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/4233829270/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Beetle",
                "engine": "Multi",
                "startYear": 1998,
                "endYear": 2012
            },
            {
                "make": "Audi",
                "model": "TT",
                "engine": "1.8",
                "startYear": 1998,
                "endYear": 2007
            },
            {
                "make": "Audi",
                "model": "TT Quattro",
                "engine": "3.2",
                "startYear": 2003,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 446,
        "sku": "33-2343",
        "name": "33-2343",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/2266433904/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 447,
        "sku": "33-2676",
        "name": "33-2676",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/12611671444/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "Miata",
                "engine": "1.8",
                "startYear": 1998,
                "endYear": 2005
            },
            {
                "make": "Mazda",
                "model": "626",
                "engine": "Multi",
                "startYear": 1991,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 448,
        "sku": "33-2009",
        "name": "33-2009",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/6833827048/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "4Runner",
                "engine": "2.4",
                "startYear": 1985,
                "endYear": 1989
            },
            {
                "make": "Toyota",
                "model": "Cressida",
                "engine": "2",
                "startYear": 1982,
                "endYear": 1984
            },
            {
                "make": "Toyota",
                "model": "Cressida",
                "engine": "2.8",
                "startYear": 1981,
                "endYear": 1984
            },
            {
                "make": "Mazda",
                "model": "626",
                "engine": "Multi",
                "startYear": 1991,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 449,
        "sku": "33-2446",
        "name": "33-2446",
        "category": 8,
        "subCategory": 0,
        "price": 480,
        "shopee": "https://shopee.com.my/product/119505399/13711597955/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Land Rover",
                "model": "Discovery",
                "engine": "3.0",
                "startYear": 2013,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 450,
        "sku": "33-2449",
        "name": "33-2449",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/12511373012/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "X6",
                "engine": "4.4",
                "startYear": 2009,
                "endYear": 2014
            },
            {
                "make": "BMW",
                "model": "X5",
                "engine": "4.4",
                "startYear": 2009,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 451,
        "sku": "33-2395",
        "name": "33-2395",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/10701783697/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "CX-9",
                "engine": "3.7",
                "startYear": 2008,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 452,
        "sku": "33-2747",
        "name": "33-2747",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/6055020774/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "E200",
                "engine": "2.0",
                "startYear": 1995,
                "endYear": 1999
            },
            {
                "make": "Mercedes Benz",
                "model": "E200d",
                "engine": "2.0D",
                "startYear": 1996,
                "endYear": 2002
            },
            {
                "make": "Mercedes Benz",
                "model": "E220d",
                "engine": "2.0D",
                "startYear": 1995,
                "endYear": 2001
            },
            {
                "make": "Mercedes Benz",
                "model": "E230",
                "engine": "2.3",
                "startYear": 1995,
                "endYear": 1997
            },
            {
                "make": "Mercedes Benz",
                "model": "E240",
                "engine": "2.4",
                "startYear": 1997,
                "endYear": 1999
            },
            {
                "make": "Mercedes Benz",
                "model": "E280",
                "engine": "2.8",
                "startYear": 1996,
                "endYear": 1999
            },
            {
                "make": "Mercedes Benz",
                "model": "E300d",
                "engine": "3.0D",
                "startYear": 1995,
                "endYear": 1999
            },
            {
                "make": "Mercedes Benz",
                "model": "E320",
                "engine": "3.2",
                "startYear": 1995,
                "endYear": 1999
            }
        ]
    },
    {
        "id": 453,
        "sku": "33-2078",
        "name": "33-2078",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/8073152472/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "520i",
                "engine": "2.0",
                "startYear": 1989,
                "endYear": 1996
            },
            {
                "make": "BMW",
                "model": "525i",
                "engine": "2.5",
                "startYear": 1989,
                "endYear": 1996
            },
            {
                "make": "BMW",
                "model": "M5",
                "engine": "3.8",
                "startYear": 1992,
                "endYear": 1995
            },
            {
                "make": "BMW",
                "model": "M5",
                "engine": "3.5",
                "startYear": 1988,
                "endYear": 1992
            }
        ]
    },
    {
        "id": 454,
        "sku": "33-2276",
        "name": "33-2276",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/4593271148/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "2.0",
                "startYear": 2003,
                "endYear": 2008
            },
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "2.4",
                "startYear": 2003,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 455,
        "sku": "33-2231",
        "name": "33-2231",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/4238540281/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "318i",
                "engine": "1.9",
                "startYear": 1996,
                "endYear": 2001
            },
            {
                "make": "BMW",
                "model": "323i",
                "engine": "2.5",
                "startYear": 1998,
                "endYear": 2000
            },
            {
                "make": "BMW",
                "model": "328i",
                "engine": "2.8",
                "startYear": 1998,
                "endYear": 2000
            },
            {
                "make": "BMW",
                "model": "330i",
                "engine": "3.0",
                "startYear": 2000,
                "endYear": 2004
            }
        ]
    },
    {
        "id": 456,
        "sku": "33-2836",
        "name": "33-2836",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/2263331306/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Kenari",
                "engine": "1.0",
                "startYear": 2000,
                "endYear": 2009
            },
            {
                "make": "Perodua",
                "model": "Kelisa",
                "engine": "Multi",
                "startYear": 2001,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 457,
        "sku": "33-2149",
        "name": "33-2149",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/6338561332/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "X5",
                "engine": "4.4",
                "startYear": 2000,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 458,
        "sku": "33-2997",
        "name": "33-2997",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/7238582264/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "435i",
                "engine": "3.0",
                "startYear": 2013,
                "endYear": 2016
            },
            {
                "make": "BMW",
                "model": "M2",
                "engine": "3.0",
                "startYear": 2016,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 459,
        "sku": "33-2142",
        "name": "33-2142",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/4738539290/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "M5",
                "engine": "5.0",
                "startYear": 1998,
                "endYear": 2004
            },
            {
                "make": "BMW",
                "model": "535i",
                "engine": "3.5",
                "startYear": 1996,
                "endYear": 2003
            },
            {
                "make": "BMW",
                "model": "540i",
                "engine": "4.4",
                "startYear": 1996,
                "endYear": 2004
            }
        ]
    },
    {
        "id": 460,
        "sku": "33-2530",
        "name": "33-2530",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/5938571219/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "323i",
                "engine": "2.3",
                "startYear": 1978,
                "endYear": 1982
            },
            {
                "make": "BMW",
                "model": "320/6",
                "engine": "2.0",
                "startYear": 1977,
                "endYear": 1979
            },
            {
                "make": "BMW",
                "model": "320CSi",
                "engine": "3.0",
                "startYear": 1971,
                "endYear": 1977
            },
            {
                "make": "BMW",
                "model": "2500",
                "engine": "3.0",
                "startYear": 1971,
                "endYear": 1977
            },
            {
                "make": "BMW",
                "model": "520i",
                "engine": "2.0",
                "startYear": 1972,
                "endYear": 1977
            },
            {
                "make": "Porsche",
                "model": "911",
                "engine": "3.3",
                "startYear": 1977,
                "endYear": 1993
            },
            {
                "make": "Porsche",
                "model": "930",
                "engine": "3.0",
                "startYear": 1976,
                "endYear": 1989
            }
        ]
    },
    {
        "id": 461,
        "sku": "33-2956",
        "name": "33-2956",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/6389385848/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Chevrolet",
                "model": "Captiva",
                "engine": "2.4",
                "startYear": 2006,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 462,
        "sku": "33-2077",
        "name": "33-2077",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/11400828029/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Porsche",
                "model": "911",
                "engine": "3.6",
                "startYear": 1988,
                "endYear": 1994
            }
        ]
    },
    {
        "id": 463,
        "sku": "33-2013",
        "name": "33-2013",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/11400230771/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Isuzu",
                "model": "Alterra",
                "engine": "3.0D",
                "startYear": 2007,
                "endYear": 2013
            },
            {
                "make": "Isuzu",
                "model": "D-Max",
                "engine": "2.5D",
                "startYear": 2007,
                "endYear": 2011
            },
            {
                "make": "Isuzu",
                "model": "D-Max",
                "engine": "3.0D",
                "startYear": 2007,
                "endYear": 2011
            },
            {
                "make": "Opel",
                "model": "Frontera",
                "engine": "Multi",
                "startYear": 1992,
                "endYear": 1998
            },
            {
                "make": "Opel",
                "model": "Omega",
                "engine": "Multi",
                "startYear": 1986,
                "endYear": 1994
            }
        ]
    },
    {
        "id": 464,
        "sku": "33-2064",
        "name": "33-2064",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/11000721267/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Isuzu",
                "model": "Trooper",
                "engine": "3.5",
                "startYear": 1998,
                "endYear": 2002
            },
            {
                "make": "Isuzu",
                "model": "Trooper",
                "engine": "3.2",
                "startYear": 1991,
                "endYear": 1998
            }
        ]
    },
    {
        "id": 465,
        "sku": "33-2049",
        "name": "33-2049",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1835966100/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "MX-6",
                "engine": "Multi",
                "startYear": 1987,
                "endYear": 1997
            }
        ]
    },
    {
        "id": 466,
        "sku": "33-2072",
        "name": "33-2072",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/4940199458/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Trajet",
                "engine": "2.0",
                "startYear": 1999,
                "endYear": 2008
            },
            {
                "make": "Hyundai",
                "model": "Sonata",
                "engine": "2.0",
                "startYear": 1998,
                "endYear": 2004
            },
            {
                "make": "Proton",
                "model": "Perdana",
                "engine": "2.0",
                "startYear": 1995,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 467,
        "sku": "33-2887",
        "name": "33-2887",
        "category": 8,
        "subCategory": 0,
        "price": 350,
        "shopee": "https://shopee.com.my/product/119505399/9819314940/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Sonata",
                "engine": "2.4",
                "startYear": 2006,
                "endYear": 2010
            },
            {
                "make": "Hyundai",
                "model": "Sonata",
                "engine": "2.0",
                "startYear": 2005,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 468,
        "sku": "33-2209",
        "name": "33-2209",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/6734467286/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Audi",
                "model": "A4 Quattro Cabriolet",
                "engine": "3.2",
                "startYear": 2005,
                "endYear": 2009
            },
            {
                "make": "Audi",
                "model": "A4 Quattro Cabriolet",
                "engine": "2.0",
                "startYear": 2009,
                "endYear": 2010
            },
            {
                "make": "Audi",
                "model": "A4",
                "engine": "Multi",
                "startYear": 2000,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 469,
        "sku": "33-2201",
        "name": "33-2201",
        "category": 8,
        "subCategory": 0,
        "price": 350,
        "shopee": "https://shopee.com.my/product/119505399/2949159374/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Kia",
                "model": "Cerato",
                "engine": "1.6",
                "startYear": 2004,
                "endYear": 2008
            },
            {
                "make": "Hyundai",
                "model": "Elantra",
                "engine": "1.6",
                "startYear": 2000,
                "endYear": 2008
            },
            {
                "make": "Hyundai",
                "model": "Elantra",
                "engine": "1.8",
                "startYear": 2000,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 470,
        "sku": "33-2352",
        "name": "33-2352",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/9451357354/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "M5",
                "engine": "5.0",
                "startYear": 2005,
                "endYear": 2010
            },
            {
                "make": "BMW",
                "model": "M6",
                "engine": "5.0",
                "startYear": 2005,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 472,
        "sku": "33-2461",
        "name": "33-2461",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/7440072282/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "2.4",
                "startYear": 2011,
                "endYear": 2018
            },
            {
                "make": "Honda",
                "model": "Odyssey",
                "engine": "3.5",
                "startYear": 2011,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 473,
        "sku": "33-2011",
        "name": "33-2011",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/9048914551/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Jaguar",
                "model": "XJS",
                "engine": "6.0",
                "startYear": 1993,
                "endYear": 1996
            },
            {
                "make": "Jaguar",
                "model": "XJS",
                "engine": "5.3",
                "startYear": 1975,
                "endYear": 1993
            },
            {
                "make": "Jaguar",
                "model": "XJ12",
                "engine": "5.3",
                "startYear": 1973,
                "endYear": 1993
            }
        ]
    },
    {
        "id": 474,
        "sku": "33-2545",
        "name": "33-2545",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/8519130785/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Alfa Romeo",
                "model": "GTV-6",
                "engine": "2.5",
                "startYear": 1980,
                "endYear": 1987
            }
        ]
    },
    {
        "id": 476,
        "sku": "33-2658",
        "name": "33-2656",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/8355077975/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Ferrari",
                "model": "348",
                "engine": "3.4",
                "startYear": 1989,
                "endYear": 1995
            }
        ]
    },
    {
        "id": 477,
        "sku": "33-2380",
        "name": "33-2380",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/7980364605/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Elantra",
                "engine": "2.0",
                "startYear": 2008,
                "endYear": 2012
            },
            {
                "make": "Kia",
                "model": "Forte",
                "engine": "2.0",
                "startYear": 2009,
                "endYear": 2013
            },
            {
                "make": "Kia",
                "model": "Forte",
                "engine": "1.6",
                "startYear": 2009,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 478,
        "sku": "33-2025",
        "name": "33-2025",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/8248957696/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic II",
                "engine": "1.6",
                "startYear": 1987,
                "endYear": 1995
            },
            {
                "make": "Honda",
                "model": "Civic III",
                "engine": "Multi",
                "startYear": 1987,
                "endYear": 1989
            },
            {
                "make": "Honda",
                "model": "CR-X",
                "engine": "Multi",
                "startYear": 1988,
                "endYear": 1991
            }
        ]
    },
    {
        "id": 479,
        "sku": "SKU 201",
        "name": "Volkswagen Beetle",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7959353007/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Beetle",
                "engine": "Multi",
                "startYear": 2012,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 480,
        "sku": "33-2189",
        "name": "33-2189",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/7941212878/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Porsche",
                "model": "911",
                "engine": "3.6T",
                "startYear": 1999,
                "endYear": 2005
            },
            {
                "make": "Porsche",
                "model": "GT2",
                "engine": "3.6T",
                "startYear": 2001,
                "endYear": 2003
            },
            {
                "make": "Porsche",
                "model": "GT3",
                "engine": "3.6",
                "startYear": 2001,
                "endYear": 2003
            }
        ]
    },
    {
        "id": 481,
        "sku": "SKU 515",
        "name": "Proton Saga III",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7932068020/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Saga",
                "engine": "1.3",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 482,
        "sku": "SKU 688",
        "name": "Hyundai Sonata VI",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7858901136/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Sonata",
                "engine": "Multi",
                "startYear": 2010,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 483,
        "sku": "SKU 688",
        "name": "Kia Sorento II",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7832068866/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Kia",
                "model": "Sorento",
                "engine": "2.4",
                "startYear": 2011,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 484,
        "sku": "",
        "name": "Honda Jazz",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7813167910/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5",
                "startYear": 2015,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 485,
        "sku": "33-2911",
        "name": "33-2911",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/7762306162/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Peugeot",
                "model": "407",
                "engine": "1.8",
                "startYear": 2004,
                "endYear": 2009
            },
            {
                "make": "Peugeot",
                "model": "407",
                "engine": "2.0",
                "startYear": 2004,
                "endYear": 2009
            },
            {
                "make": "Peugeot",
                "model": "508",
                "engine": "Multi",
                "startYear": 2011,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 486,
        "sku": "",
        "name": "Ford Mondeo",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7758884896/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Mondeo",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 487,
        "sku": "SKU 515",
        "name": "Proton X70",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7757656415/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "X70",
                "engine": "Multi",
                "startYear": 2018,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 488,
        "sku": "33-2155",
        "name": "33-2155",
        "category": 8,
        "subCategory": 0,
        "price": 340,
        "shopee": "https://shopee.com.my/product/119505399/7740181053/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Grand Vitara",
                "engine": "2.0",
                "startYear": 1996,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 490,
        "sku": "33-5042",
        "name": "33-5042",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/7613376496/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "CX-3",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 491,
        "sku": "SKU 201",
        "name": "Audi S3",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7558875472/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Audi",
                "model": "S3",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 493,
        "sku": "33-2154",
        "name": "33-2154",
        "category": 8,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/7533822984/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Subaru",
                "model": "Impreza",
                "engine": "1.6",
                "startYear": 2000,
                "endYear": 2008
            },
            {
                "make": "Subaru",
                "model": "Impreza",
                "engine": "2.0",
                "startYear": 2000,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 494,
        "sku": "33-2763",
        "name": "33-2763",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/7480368969/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Kia",
                "model": "Spectra",
                "engine": "1.6",
                "startYear": 1997,
                "endYear": 2004
            }
        ]
    },
    {
        "id": 495,
        "sku": "33-2198",
        "name": "33-2198",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/7480327763/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Santa Fe",
                "engine": "2.0D",
                "startYear": 2001,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 496,
        "sku": "SKU 201",
        "name": "Ford Focus",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7458887756/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Focus",
                "engine": "2.0",
                "startYear": 2011,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 498,
        "sku": "SKU 515",
        "name": "Proton Preve",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/7332067242/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Preve",
                "engine": "1.6",
                "startYear": 2012,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 499,
        "sku": "SKU 201",
        "name": "Ford Kuga",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6858895093/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Kuga",
                "engine": "Multi",
                "startYear": 2013,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 500,
        "sku": "33-2766",
        "name": "33-2766",
        "category": 8,
        "subCategory": 0,
        "price": 410,
        "shopee": "https://shopee.com.my/product/119505399/6855028437/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "Vito",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 501,
        "sku": "33-3028",
        "name": "33-3028",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/6854148325/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "BMW",
                "model": "X6",
                "engine": "3.0D",
                "startYear": 2012,
                "endYear": 2020
            },
            {
                "make": "BMW",
                "model": "X5",
                "engine": "3.0D",
                "startYear": 2012,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 502,
        "sku": "E-2040",
        "name": "E-2040",
        "category": 8,
        "subCategory": 0,
        "price": 320,
        "shopee": "https://shopee.com.my/product/119505399/6840183338/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Jimny",
                "engine": "1.3",
                "startYear": 1981,
                "endYear": 1998
            }
        ]
    },
    {
        "id": 503,
        "sku": "SKU 201",
        "name": "Volkswagen Scirocco",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6759419119/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Scirocco",
                "engine": "Multi",
                "startYear": 2008,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 504,
        "sku": "SKU 567",
        "name": "Toyota Innova",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6758494048/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Innova",
                "engine": "2.0",
                "startYear": 2006,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 505,
        "sku": "SKU 830",
        "name": "Toyota Alphard/Vellfire",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6757662672/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Vellfire",
                "engine": "Multi",
                "startYear": 2008,
                "endYear": 2023
            },
            {
                "make": "Toyota",
                "model": "Alphard",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 506,
        "sku": "SKU 358",
        "name": "Subaru Forester",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6745262107/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Subaru",
                "model": "Forester",
                "engine": "2.0",
                "startYear": 2006,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 507,
        "sku": "33-2670",
        "name": "33-2670",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/6656130304/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volvo",
                "model": "V70",
                "engine": "Multi",
                "startYear": 1994,
                "endYear": 2001
            }
        ]
    },
    {
        "id": 508,
        "sku": "33-2826",
        "name": "33-2826",
        "category": 8,
        "subCategory": 0,
        "price": 400,
        "shopee": "https://shopee.com.my/product/119505399/6640161541/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Suzuki",
                "model": "Swift",
                "engine": "1.5",
                "startYear": 2005,
                "endYear": 2010
            }
        ]
    },
    {
        "id": 509,
        "sku": "SKU 201",
        "name": "Audi TT",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6558786994/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Audi",
                "model": "TT",
                "engine": "2.0",
                "startYear": 2006,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 510,
        "sku": "33-2765",
        "name": "33-2765",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/6555028146/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "SL 320",
                "engine": "3.2",
                "startYear": 1994,
                "endYear": 2001
            }
        ]
    },
    {
        "id": 511,
        "sku": "SKU 201",
        "name": "Audi A5",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6458879265/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Audi",
                "model": "A5",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 512,
        "sku": "33-2914",
        "name": "33-2914",
        "category": 8,
        "subCategory": 0,
        "price": 400,
        "shopee": "https://shopee.com.my/product/119505399/6455028894/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "A150",
                "engine": "1.5",
                "startYear": 2004,
                "endYear": 2009
            },
            {
                "make": "Mercedes Benz",
                "model": "A170",
                "engine": "1.7",
                "startYear": 2004,
                "endYear": 2009
            },
            {
                "make": "Mercedes Benz",
                "model": "A200",
                "engine": "2.0",
                "startYear": 2004,
                "endYear": 2011
            },
            {
                "make": "Mercedes Benz",
                "model": "B180",
                "engine": "1.7",
                "startYear": 2009,
                "endYear": 2011
            },
            {
                "make": "Mercedes Benz",
                "model": "B200",
                "engine": "2.0",
                "startYear": 2005,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 513,
        "sku": "E-2730",
        "name": "E-2730",
        "category": 8,
        "subCategory": 0,
        "price": 320,
        "shopee": "https://shopee.com.my/product/119505399/6440069980/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.5",
                "startYear": 1984,
                "endYear": 1987
            }
        ]
    },
    {
        "id": 514,
        "sku": "33-5038",
        "name": "33-5038",
        "category": 8,
        "subCategory": 0,
        "price": 319,
        "shopee": "https://shopee.com.my/product/119505399/6413378647/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "2 (Skyactiv)",
                "engine": "1.5",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Mazda",
                "model": "2 (Skyactiv)",
                "engine": "1.3",
                "startYear": 2015,
                "endYear": 2019
            }
        ]
    },
    {
        "id": 515,
        "sku": "SKU 201",
        "name": "Audi Q5",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6358700659/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Audi",
                "model": "Q5",
                "engine": "2.0",
                "startYear": 2009,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 516,
        "sku": "SKU 201",
        "name": "Volkswagen Tiguan",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/6259416668/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Tiguan",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 517,
        "sku": "33-2424",
        "name": "33-2424",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/6188031634/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "6",
                "engine": "2.5",
                "startYear": 2011,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 518,
        "sku": "33-2678",
        "name": "33-2678",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/6055016802/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "E350",
                "engine": "3.5",
                "startYear": 2005,
                "endYear": 2011
            },
            {
                "make": "Mercedes Benz",
                "model": "S500",
                "engine": "5.0",
                "startYear": 1993,
                "endYear": 1999
            }
        ]
    },
    {
        "id": 519,
        "sku": "SKU 201",
        "name": "Volkswagen Passat",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/5959408049/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Passat",
                "engine": "Multi",
                "startYear": 2007,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 520,
        "sku": "SKU 201",
        "name": "Volkswagen Jetta",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/5959408049/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Jetta",
                "engine": "1.4",
                "startYear": 2011,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 521,
        "sku": "33-2194",
        "name": "33-2194",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/5940192636/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Waja",
                "engine": "1.8",
                "startYear": 2000,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 522,
        "sku": "33-5006",
        "name": "33-5006",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/5940065920/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord (Hybrid)",
                "engine": "2.0",
                "startYear": 2014,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 523,
        "sku": "33-3127",
        "name": "33-3127",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/5857553461/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volvo",
                "model": "XC40",
                "engine": "1.5",
                "startYear": 2018,
                "endYear": 2023
            },
            {
                "make": "Volvo",
                "engine": "2.0",
                "startYear": 2018,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 524,
        "sku": "33-2158",
        "name": "33-2158",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/5854947217/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "E270",
                "engine": "2.7D",
                "startYear": 1999,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 525,
        "sku": "E-0647",
        "name": "E-0647",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/5757653093/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Audi",
                "model": "A4",
                "engine": "2.0",
                "startYear": 2016,
                "endYear": 2023
            },
            {
                "make": "Audi",
                "model": "A5",
                "engine": "2.0",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 526,
        "sku": "33-2193",
        "name": "33-2193",
        "category": 8,
        "subCategory": 0,
        "price": 400,
        "shopee": "https://shopee.com.my/product/119505399/5754946042/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "C200 (Kompressor)",
                "engine": "Multi",
                "startYear": 2000,
                "endYear": 2007
            },
            {
                "make": "Mercedes Benz",
                "model": "C230 (Kompressor)",
                "engine": "1.8",
                "startYear": 2000,
                "endYear": 2007
            },
            {
                "make": "Mercedes Benz",
                "model": "C240",
                "engine": "2.6",
                "startYear": 2000,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 527,
        "sku": "33-2034",
        "name": "33-2034",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/5656903186/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "MX-5 ",
                "engine": "1.6",
                "startYear": 1990,
                "endYear": 1998
            },
            {
                "make": "Mazda",
                "model": "Miata",
                "engine": "1.8",
                "startYear": 1990,
                "endYear": 1997
            },
            {
                "make": "Mazda",
                "model": "323",
                "engine": "Multi",
                "startYear": 1989,
                "endYear": 1994
            },
            {
                "make": "Mazda",
                "model": "Familia",
                "engine": "1.8",
                "startYear": 1989,
                "endYear": 1994
            },
            {
                "make": "Ford",
                "model": "Laser",
                "engine": "1.8",
                "startYear": 1990,
                "endYear": 1994
            },
            {
                "make": "Ford",
                "model": "Escort",
                "engine": "1.8",
                "startYear": 1992,
                "endYear": 1996
            }
        ]
    },
    {
        "id": 528,
        "sku": "33-2975",
        "name": "33-2975",
        "category": 8,
        "subCategory": 0,
        "price": 340,
        "shopee": "https://shopee.com.my/product/119505399/5634466068/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Peugeot",
                "model": "207",
                "engine": "1.6TD",
                "startYear": 2009,
                "endYear": 2015
            },
            {
                "make": "Peugeot",
                "model": "208",
                "engine": "1.6TD",
                "startYear": 2012,
                "endYear": 2015
            },
            {
                "make": "Peugeot",
                "model": "308",
                "engine": "1.6TD",
                "startYear": 2009,
                "endYear": 2015
            },
            {
                "make": "Peugeot",
                "model": "508",
                "engine": "1.6TD",
                "startYear": 2010,
                "endYear": 2015
            },
            {
                "make": "Peugeot",
                "model": "3008",
                "engine": "1.6TD",
                "startYear": 2009,
                "endYear": 2015
            },
            {
                "make": "Peugeot",
                "model": "4008",
                "engine": "1.6TD",
                "startYear": 2013,
                "endYear": 2017
            },
            {
                "make": "Peugeot",
                "model": "5008",
                "engine": "1.6TD",
                "startYear": 2010,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 529,
        "sku": "SKU 823",
        "name": "Hyundai Elantra",
        "category": 10,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/5633963972/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Elantra",
                "engine": "1.6",
                "startYear": 2011,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 530,
        "sku": "33-2030",
        "name": "33-2030",
        "category": 8,
        "subCategory": 0,
        "price": 349,
        "shopee": "https://shopee.com.my/product/119505399/5633821842/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Caldina",
                "engine": "1.8",
                "startYear": 1996,
                "endYear": 2002
            },
            {
                "make": "Toyota",
                "model": "Caldina",
                "engine": "2.0",
                "startYear": 1992,
                "endYear": 2001
            },
            {
                "make": "Toyota",
                "model": "RAV4 I",
                "engine": "2.0",
                "startYear": 1994,
                "endYear": 2000
            },
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "1.8",
                "startYear": 1990,
                "endYear": 1997
            },
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "2.0D",
                "startYear": 1990,
                "endYear": 1998
            },
            {
                "make": "Toyota",
                "model": "Camry",
                "engine": "2.5",
                "startYear": 1988,
                "endYear": 1994
            },
            {
                "make": "Toyota",
                "model": "Celica",
                "engine": "2.0",
                "startYear": 1985,
                "endYear": 1994
            }
        ]
    },
    {
        "id": 532,
        "sku": "HB.TP SS45",
        "name": "S.Steel Car Exhaust Tips Tailpipe - 230mm(L), 111mm(ID), 127mm(OD)",
        "category": 6,
        "subCategory": 0,
        "price": 180,
        "shopee": "https://shopee.com.my/product/119505399/5433649143/",
        "desc": [
            "To weld onto actual exhaust to become part of the full exhaust system.",
            "All 304 polished stainless steel.",
            "Anti-corrosive and more durable.",
            "Length 210 mm.",
            "Inlet Diameter 111 mm.",
            "Outlet Diameter 127 mm."
        ],
        "imageCount": 4,
        "vehicles": []
    },
    {
        "id": 533,
        "sku": "33-2917",
        "name": "33-2917",
        "category": 8,
        "subCategory": 0,
        "price": 349,
        "shopee": "https://shopee.com.my/product/119505399/5340059239/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "Multi",
                "startYear": 2003,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 534,
        "sku": "33-2071",
        "name": "33-2071",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/5339777879/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "2.0",
                "startYear": 1993,
                "endYear": 1998
            },
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "2.2",
                "startYear": 1994,
                "endYear": 1995
            },
            {
                "make": "Honda",
                "model": "Odyssey",
                "engine": "2.3",
                "startYear": 1998,
                "endYear": 1998
            },
            {
                "make": "Honda",
                "model": "Odyssey",
                "engine": "2.2",
                "startYear": 1995,
                "endYear": 1997
            }
        ]
    },
    {
        "id": 535,
        "sku": "SKU 201",
        "name": "Audi A6/A7",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/5258520035/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Audi",
                "model": "A6",
                "engine": "Multi",
                "startYear": 2011,
                "endYear": 2018
            },
            {
                "make": "Audi",
                "model": "A7",
                "engine": "Multi",
                "startYear": 2010,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 536,
        "sku": "HB.TP SS44",
        "name": "S.Steel Car Exhaust Tips Tailpipe - 220mm(L), 86mm(ID), 130mm(OD)",
        "category": 6,
        "subCategory": 0,
        "price": 180,
        "shopee": "https://shopee.com.my/product/119505399/5233650004/",
        "desc": [
            "To weld onto actual exhaust to become part of the full exhaust system.",
            "All 304 polished stainless steel.",
            "Anti-corrosive and more durable.",
            "Length 220 mm.",
            "Inlet Diameter 86 mm.",
            "Outlet Diameter 120 mm."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 537,
        "sku": "33-2080",
        "name": "33-2080",
        "category": 8,
        "subCategory": 0,
        "price": 340,
        "shopee": "https://shopee.com.my/product/119505399/5171820594/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Navara",
                "engine": "2.5TD",
                "startYear": 2005,
                "endYear": 2015
            },
            {
                "make": "Nissan",
                "model": "Navara",
                "engine": "3.0TD",
                "startYear": 2012,
                "endYear": 2014
            },
            {
                "make": "Nissan",
                "model": "Pathfinder",
                "engine": "2.5TD",
                "startYear": 2005,
                "endYear": 2014
            },
            {
                "make": "Nissan",
                "model": "Pathfinder",
                "engine": "3.0TD",
                "startYear": 2010,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 538,
        "sku": "SKU 999",
        "name": "Mercedes Benz CLK-Class (A209/C209)",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/5159340188/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "CLK200 Kompressor",
                "engine": "1.8",
                "startYear": 2002,
                "endYear": 2010
            },
            {
                "make": "Mercedes Benz",
                "model": "CLK240",
                "engine": "2.6",
                "startYear": 2002,
                "endYear": 2005
            },
            {
                "make": "Mercedes Benz",
                "model": "CLK270 CDI",
                "engine": "2.7D",
                "startYear": 2002,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 539,
        "sku": "33-2924",
        "name": "33-2924",
        "category": 8,
        "subCategory": 0,
        "price": 330,
        "shopee": "https://shopee.com.my/product/119505399/4869374673/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "2",
                "engine": "1.3",
                "startYear": 2007,
                "endYear": 2015
            },
            {
                "make": "Mazda",
                "model": "2",
                "engine": "1.5",
                "startYear": 2007,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 540,
        "sku": "SKU 806",
        "name": "Mazda RX-8",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4833074665/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "RX-8",
                "engine": "1.3",
                "startYear": 2003,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 541,
        "sku": "RM-9025",
        "name": "RM-9025",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/4732947986/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Persona Campro",
                "engine": "1.6",
                "startYear": 2007,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 542,
        "sku": "SKU 515",
        "name": "Proton Iriz",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4632069994/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Iriz",
                "engine": "Multi",
                "startYear": 2014,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 543,
        "sku": "SKU 201",
        "name": "Audi A8",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4458877042/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Audi",
                "model": "A8",
                "engine": "Multi",
                "startYear": 2010,
                "endYear": 2017
            }
        ]
    },
    {
        "id": 544,
        "sku": "SKU 567",
        "name": "Toyota Avanza",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4458493667/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Avanza",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 545,
        "sku": "33-2192",
        "name": "33-2192",
        "category": 8,
        "subCategory": 0,
        "price": 350,
        "shopee": "https://shopee.com.my/product/119505399/4439777394/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.7",
                "startYear": 2001,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 546,
        "sku": "SKU 999",
        "name": "Mercedes Benz SLK-Class (R172)",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4359348269/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "SLK200",
                "engine": "1.8",
                "startYear": 2011,
                "endYear": 2015
            },
            {
                "make": "Mercedes Benz",
                "model": "SLK250",
                "engine": "1.8",
                "startYear": 2011,
                "endYear": 2015
            },
            {
                "make": "Mercedes Benz",
                "model": "SLC200",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Mercedes Benz",
                "model": "SLC300",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2020
            },
            {
                "make": "Mercedes Benz",
                "model": "SLC43",
                "engine": "3.0",
                "startYear": 2015,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 547,
        "sku": "SKU 567",
        "name": "Toyota Land Cruiser Prado",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4358489679/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser Prado",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 548,
        "sku": "SKU 830",
        "name": "Toyota Prius",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4357665285/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Prius",
                "engine": "1.8",
                "startYear": 2010,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 549,
        "sku": "SKU 201",
        "name": "Ford Fiesta",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4258884736/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Ford",
                "model": "Fiesta",
                "engine": "Multi",
                "startYear": 2017,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 550,
        "sku": "SKU 201",
        "name": "Audi A4/S4",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4258876784/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Audi",
                "model": "A4",
                "engine": "Multi",
                "startYear": 2008,
                "endYear": 2016
            },
            {
                "make": "Audi",
                "model": "S4",
                "engine": "3.0",
                "startYear": 2009,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 551,
        "sku": "SKU 830",
        "name": "Toyota Land Cruiser Prado",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/4257663898/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Land Cruiser Prado",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 552,
        "sku": "SKU 830",
        "name": "Lexus NX200t",
        "category": 10,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/4132073654/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Lexus",
                "model": "NX200t",
                "engine": "2.0T",
                "startYear": 2014,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 553,
        "sku": "33-3003",
        "name": "33-3003",
        "category": 8,
        "subCategory": 0,
        "price": 400,
        "shopee": "https://shopee.com.my/product/119505399/4040070003/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Accord",
                "engine": "2.0",
                "startYear": 2008,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 554,
        "sku": "33-2232",
        "name": "33-2232",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/3952013382/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Subaru",
                "model": "Impreza",
                "engine": "2.0",
                "startYear": 1994,
                "endYear": 2006
            },
            {
                "make": "Subaru",
                "model": "Impreza",
                "engine": "2.5",
                "startYear": 2005,
                "endYear": 2007
            },
            {
                "make": "Subaru",
                "model": "Impreza WRX",
                "engine": "2.0",
                "startYear": 2000,
                "endYear": 2006
            },
            {
                "make": "Subaru",
                "model": "Forester",
                "engine": "2.0",
                "startYear": 1997,
                "endYear": 2002
            }
        ]
    },
    {
        "id": 555,
        "sku": "33-3133",
        "name": "33-3133",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/3908150307/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "A250",
                "engine": "2.0T",
                "startYear": 2018,
                "endYear": 2022
            },
            {
                "make": "Mercedes Benz",
                "model": "A35",
                "engine": "2.0T",
                "startYear": 2018,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 556,
        "sku": "SKU 999",
        "name": "Mercedes Benz S-Class (C217)",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/3859306529/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "S500L",
                "engine": "4.7T",
                "startYear": 2015,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "S63",
                "engine": "5.5T",
                "startYear": 2015,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "S65",
                "engine": "6.0T",
                "startYear": 2015,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 557,
        "sku": "33-2244",
        "name": "33-2244",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/3855134908/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "C180",
                "engine": "2.0",
                "startYear": 2000,
                "endYear": 2002
            },
            {
                "make": "Mercedes Benz",
                "model": "C200",
                "engine": "1.8",
                "startYear": 2003,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 558,
        "sku": "SKU 358",
        "name": "Subaru Impreza",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/3745360741/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Subaru",
                "model": "Impreza",
                "engine": "2.0",
                "startYear": 2000,
                "endYear": 2007
            }
        ]
    },
    {
        "id": 559,
        "sku": "33-2076",
        "name": "33-2076",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/3741311334/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Porsche",
                "model": "911",
                "engine": "3.2",
                "startYear": 1983,
                "endYear": 1989
            }
        ]
    },
    {
        "id": 561,
        "sku": "33-2181",
        "name": "33-2181",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/3655034128/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "C240",
                "engine": "2.6",
                "startYear": 2000,
                "endYear": 2005
            },
            {
                "make": "Mercedes Benz",
                "model": "C320",
                "engine": "3.2",
                "startYear": 2000,
                "endYear": 2005
            },
            {
                "make": "Mercedes Benz",
                "model": "E240",
                "engine": "2.6",
                "startYear": 2002,
                "endYear": 2006
            },
            {
                "make": "Mercedes Benz",
                "model": "E320",
                "engine": "3.2",
                "startYear": 2002,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 562,
        "sku": "33-2348",
        "name": "33-2348",
        "category": 8,
        "subCategory": 0,
        "price": 350,
        "shopee": "https://shopee.com.my/product/119505399/3639886368/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.5 Hybrid",
                "startYear": 2012,
                "endYear": 2015
            },
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.3 Hybrid",
                "startYear": 2006,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 563,
        "sku": "33-2183",
        "name": "33-2183",
        "category": 8,
        "subCategory": 0,
        "price": 450,
        "shopee": "https://shopee.com.my/product/119505399/3638224483/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "E55",
                "engine": "5.4",
                "startYear": 2003,
                "endYear": 2006
            }
        ]
    },
    {
        "id": 564,
        "sku": "SKU 999",
        "name": "Mercedes Benz C-Class (W205) / A-Class (W176) / S-Class (W222)",
        "category": 10,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/3544083741/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "C180",
                "engine": "1.6T",
                "startYear": 2015,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "C200",
                "engine": "2.0T",
                "startYear": 2015,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "C250",
                "engine": "2.0T",
                "startYear": 2015,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "C300",
                "engine": "2.0T",
                "startYear": 2015,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "C43",
                "engine": "3.0T",
                "startYear": 2015,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "C63",
                "engine": "4.0T",
                "startYear": 2015,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "A160",
                "engine": "1.6T",
                "startYear": 2012,
                "endYear": 2018
            },
            {
                "make": "Mercedes Benz",
                "model": "A180",
                "engine": "1.6T",
                "startYear": 2012,
                "endYear": 2018
            },
            {
                "make": "Mercedes Benz",
                "model": "A200",
                "engine": "1.6T",
                "startYear": 2012,
                "endYear": 2018
            },
            {
                "make": "Mercedes Benz",
                "model": "A250",
                "engine": "2.0T",
                "startYear": 2012,
                "endYear": 2018
            },
            {
                "make": "Mercedes Benz",
                "model": "A45",
                "engine": "2.0T",
                "startYear": 2012,
                "endYear": 2018
            },
            {
                "make": "Mercedes Benz",
                "model": "S400L",
                "engine": "3.0T",
                "startYear": 2014,
                "endYear": 2020
            },
            {
                "make": "Mercedes Benz",
                "model": "S500L",
                "engine": "4.7T",
                "startYear": 2014,
                "endYear": 2020
            },
            {
                "make": "Mercedes Benz",
                "model": "S63",
                "engine": "5.5T",
                "startYear": 2015,
                "endYear": 2021
            },
            {
                "make": "Mercedes Benz",
                "model": "S65",
                "engine": "6.0T",
                "startYear": 2015,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 565,
        "sku": "SKU 999",
        "name": "Mercedes Benz GLA-Class",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/3459312501/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "GLA180",
                "engine": "1.6T",
                "startYear": 2014,
                "endYear": 2020
            },
            {
                "make": "Mercedes Benz",
                "model": "GLA200",
                "engine": "1.6T",
                "startYear": 2014,
                "endYear": 2020
            },
            {
                "make": "Mercedes Benz",
                "model": "GLA220",
                "engine": "2.0T",
                "startYear": 2014,
                "endYear": 2020
            },
            {
                "make": "Mercedes Benz",
                "model": "GLA250",
                "engine": "2.0T",
                "startYear": 2014,
                "endYear": 2020
            },
            {
                "make": "Mercedes Benz",
                "model": "GLA45",
                "engine": "2.0T",
                "startYear": 2014,
                "endYear": 2020
            }
        ]
    },
    {
        "id": 566,
        "sku": "SKU 567",
        "name": "Toyota FJ Cruiser",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/3458599127/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "FJ Cruiser",
                "engine": "4.0",
                "startYear": 2005,
                "endYear": 2014
            }
        ]
    },
    {
        "id": 567,
        "sku": "SKU 830",
        "name": "Toyota RAV4",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/3457761560/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "RAV4",
                "engine": "Multi",
                "startYear": 2005,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 568,
        "sku": "33-2872",
        "name": "33-2872",
        "category": 8,
        "subCategory": 0,
        "price": 349,
        "shopee": "https://shopee.com.my/product/119505399/3416152533/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "City",
                "engine": "1.5 i-DSl",
                "startYear": 2002,
                "endYear": 2008
            },
            {
                "make": "Honda",
                "model": "Jazz",
                "engine": "1.5 i-DSl",
                "startYear": 2001,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 569,
        "sku": "E-0646",
        "name": "E-0646",
        "category": 8,
        "subCategory": 0,
        "price": 420,
        "shopee": "https://shopee.com.my/product/119505399/3357746425/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Audi",
                "model": "A4",
                "engine": "2.0",
                "startYear": 2015,
                "endYear": 2023
            },
            {
                "make": "Audi",
                "model": "A5",
                "engine": "2.0",
                "startYear": 2016,
                "endYear": 2023
            },
            {
                "make": "Audi",
                "model": "S4",
                "engine": "3.0",
                "startYear": 2016,
                "endYear": 2018
            },
            {
                "make": "Audi",
                "model": "S5",
                "engine": "3.0",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 570,
        "sku": "SKU 201",
        "name": "Volkswagen Golf",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/3259517275/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Volkswagen",
                "model": "Golf",
                "engine": "Multi",
                "startYear": 2004,
                "endYear": 2009
            }
        ]
    },
    {
        "id": 571,
        "sku": "SKU 999",
        "name": "Mercedes Benz CLS-Class (C218)",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/3168578162/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "CLS250",
                "engine": "2.1TD",
                "startYear": 2011,
                "endYear": 2017
            },
            {
                "make": "Mercedes Benz",
                "model": "CLS350",
                "engine": "3.5T",
                "startYear": 2011,
                "endYear": 2014
            },
            {
                "make": "Mercedes Benz",
                "model": "CLS400",
                "engine": "3.5T",
                "startYear": 2014,
                "endYear": 2017
            },
            {
                "make": "Mercedes Benz",
                "model": "CLS63",
                "engine": "5.5T",
                "startYear": 2011,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 572,
        "sku": "33-2977",
        "name": "33-2977",
        "category": 8,
        "subCategory": 0,
        "price": 350,
        "shopee": "https://shopee.com.my/product/119505399/3008209738/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "i10",
                "engine": "Multi",
                "startYear": 2008,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 573,
        "sku": "SKU 719",
        "name": "Nissan 370Z",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2901785736/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "370Z",
                "engine": "3.7",
                "startYear": 2008,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 574,
        "sku": "SKU 719",
        "name": "Nissan 350Z",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2901747825/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "350Z",
                "engine": "3.5",
                "startYear": 2002,
                "endYear": 2008
            }
        ]
    },
    {
        "id": 575,
        "sku": "SKU 719",
        "name": "Nissan Sunny",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2901725454/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Sunny",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 576,
        "sku": "",
        "name": "Mitsubishi Outlander",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2901694407/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Outlander",
                "engine": "Multi",
                "startYear": 2013,
                "endYear": 2021
            }
        ]
    },
    {
        "id": 577,
        "sku": "SKU 622",
        "name": "Mazda 6 III",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2861241000/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "6",
                "engine": "2.5",
                "startYear": 2013,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 578,
        "sku": "SKU 715",
        "name": "Mazda 6 II",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2861203266/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "6",
                "engine": "2.5",
                "startYear": 2008,
                "endYear": 2012
            }
        ]
    },
    {
        "id": 579,
        "sku": "",
        "name": "Honda Civic ES Adjustable & Serviceable Sports Shocks",
        "category": 12,
        "subCategory": 0,
        "price": 2800,
        "shopee": "https://shopee.com.my/product/119505399/2861101037/",
        "desc": [
            "Adjustable height shock absorbers.",
            "Standard replacement - fit with OE springs or Hot Bits sports lowered springs.",
            "Sports & Heavy Duty damping settings."
        ],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "1.7",
                "startYear": 2001,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 580,
        "sku": "SKU823",
        "name": "Kia Cerato",
        "category": 10,
        "subCategory": 0,
        "price": 358,
        "shopee": "https://shopee.com.my/product/119505399/2860694766/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Kia",
                "model": "Cerato",
                "engine": "Multi",
                "startYear": 2013,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 581,
        "sku": "85-2437",
        "name": "K&N Air-Fuel Monitor",
        "category": 9,
        "subCategory": 0,
        "price": 350,
        "shopee": "https://shopee.com.my/product/119505399/2760045049/",
        "desc": [
            "Precision instrument to help with jetting.",
            "Works on all engines and all fuels except nitro-methane.",
            "Measures approximate air/fuel ratio.",
            "L.E.D. read out.",
            "Mounts on any flat surface."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 582,
        "sku": "81-0500",
        "name": "K&N Billet Aluminium Fuel Filter",
        "category": 9,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/2759989019/",
        "desc": [
            "Filters particles as small as 10 microns.",
            "Pleated Stainless Steel filter disc to prolong service intervals.",
            "Able to withstand up to 6000 PSI of pressure.",
            "Bypass option to prevent total fuel starvation."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 583,
        "sku": "SKU 801",
        "name": "Nissan X-Trail",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2732732758/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "X-Trail",
                "engine": "Multi",
                "startYear": 2003,
                "endYear": 2013
            }
        ]
    },
    {
        "id": 584,
        "sku": "SKU 719",
        "name": "Nissan Frontier",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2732716003/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Nissan",
                "model": "Frontier",
                "engine": "Multi",
                "startYear": 2002,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 585,
        "sku": "SKU 999",
        "name": "Mercedes Benz E200",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2732680297/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mercedes Benz",
                "model": "E200",
                "engine": "2.0",
                "startYear": 2012,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 586,
        "sku": "SKU 622",
        "name": "Mazda CX-5",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2732666661/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Mazda",
                "model": "CX-5",
                "engine": "Multi",
                "startYear": 2012,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 587,
        "sku": "SKU 515",
        "name": "Satria Neo",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2721825938/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Satria Neo",
                "engine": "Multi",
                "startYear": 2006,
                "endYear": 2015
            }
        ]
    },
    {
        "id": 588,
        "sku": "SKU 711",
        "name": "Honda Civic FC/FE",
        "category": 10,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2662898628/",
        "desc": [],
        "imageCount": 0,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "Multi",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 589,
        "sku": "HA-4504",
        "name": "HA-4504",
        "category": 8,
        "subCategory": 0,
        "price": 240,
        "shopee": "https://shopee.com.my/product/119505399/2392021798/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "TRX450R",
                "engine": "450",
                "startYear": 2004,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 590,
        "sku": "33-2485",
        "name": "33-2485",
        "category": 8,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2333501850/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Prius C",
                "engine": "1.5",
                "startYear": 2012,
                "endYear": 2019
            },
            {
                "make": "Toyota",
                "model": "Corolla",
                "engine": "1.8 Hybrid",
                "startYear": 2020,
                "endYear": 2022
            }
        ]
    },
    {
        "id": 591,
        "sku": "33-2525",
        "name": "33-2525",
        "category": 8,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/2333469058/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Estima",
                "engine": "2.5",
                "startYear": 1993,
                "endYear": 1999
            }
        ]
    },
    {
        "id": 592,
        "sku": "33-2671",
        "name": "33-2671",
        "category": 8,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2333454903/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Corolla",
                "engine": "1.3",
                "startYear": 1993,
                "endYear": 1997
            }
        ]
    },
    {
        "id": 593,
        "sku": "33-2989",
        "name": "33-2989",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/2333185942/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Toyota",
                "model": "Avanza",
                "engine": "1.5",
                "startYear": 2004,
                "endYear": 2011
            },
            {
                "make": "Toyota",
                "model": "Rush",
                "engine": "1.5",
                "startYear": 2006,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 594,
        "sku": "33-2671",
        "name": "33-2671",
        "category": 8,
        "subCategory": 0,
        "price": 359,
        "shopee": "https://shopee.com.my/product/119505399/2329528434/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Perodua",
                "model": "Kembara",
                "engine": "1.3",
                "startYear": 1998,
                "endYear": 2005
            }
        ]
    },
    {
        "id": 595,
        "sku": "33-2948",
        "name": "33-2948",
        "category": 8,
        "subCategory": 0,
        "price": 369,
        "shopee": "https://shopee.com.my/product/119505399/1834866405/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "Civic",
                "engine": "2.0",
                "startYear": 2007,
                "endYear": 2011
            },
            {
                "make": "Honda",
                "model": "Civic Type R",
                "engine": "2.0",
                "startYear": 2007,
                "endYear": 2011
            }
        ]
    },
    {
        "id": 596,
        "sku": "E-2873",
        "name": "E-2873",
        "category": 8,
        "subCategory": 0,
        "price": 379,
        "shopee": "https://shopee.com.my/product/119505399/1828135775/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Mitsubishi",
                "model": "Pajero",
                "engine": "Multi",
                "startYear": 1983,
                "endYear": 1986
            },
            {
                "make": "Mitsubishi",
                "model": "Starion",
                "engine": "Multi",
                "startYear": 1982,
                "endYear": 1991
            }
        ]
    },
    {
        "id": 597,
        "sku": "RC-5000",
        "name": "RC-5000 Universal (Flange ID: 4.75″ x 2.938″, Height: 5.625″)",
        "category": 8,
        "subCategory": 0,
        "price": 360,
        "shopee": "https://shopee.com.my/product/119505399/1828100615/",
        "desc": [
            "Oval straight shape air filter.",
            "Flange internal dimensions: 4.75 inches x 2.938 inches (121mm x 75mm).",
            "Flange length: 0.875 inch (22mm).",
            "Filter height: 5.625 inches (143mm).",
            "Top external dimensions: 4.5 inches x 3.25 inches (114mm x 83mm).",
            "Bottom external dimensions: 6.5 inches x 4.75 inches (165mm x 121mm)."
        ],
        "imageCount": 2,
        "vehicles": []
    },
    {
        "id": 598,
        "sku": "33-3065",
        "name": "33-3065",
        "category": 8,
        "subCategory": 0,
        "price": 380,
        "shopee": "https://shopee.com.my/product/119505399/1827544367/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Volvo",
                "model": "XC90",
                "engine": "2.0T",
                "startYear": 2016,
                "endYear": 2023
            }
        ]
    },
    {
        "id": 599,
        "sku": "33-5031",
        "name": "33-5031",
        "category": 8,
        "subCategory": 0,
        "price": 365,
        "shopee": "https://shopee.com.my/product/119505399/1827478449/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CR-V",
                "engine": "2.4",
                "startYear": 2015,
                "endYear": 2016
            }
        ]
    },
    {
        "id": 600,
        "sku": "33-3050",
        "name": "33-3050",
        "category": 8,
        "subCategory": 0,
        "price": 321,
        "shopee": "https://shopee.com.my/product/119505399/1835998550/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Proton",
                "model": "Preve",
                "engine": "1.6",
                "startYear": 2012,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 601,
        "sku": "33-2969",
        "name": "33-2969",
        "category": 8,
        "subCategory": 0,
        "price": 390,
        "shopee": "https://shopee.com.my/product/119505399/3380431073/",
        "desc": [],
        "imageCount": 1,
        "vehicles": [
            {
                "make": "Hyundai",
                "model": "Santa Fe",
                "engine": "2.2TD",
                "startYear": 2011,
                "endYear": 2018
            }
        ]
    },
    {
        "id": 602,
        "sku": "",
        "name": "Aluminium Intercooler (450 x 160 x 50 mm x 2″)",
        "category": 7,
        "subCategory": 0,
        "price": 650,
        "shopee": "https://shopee.com.my/product/119505399/2770320836/",
        "desc": [],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 603,
        "sku": "",
        "name": "Aluminium Intercooler (310 x 160 x 50 mm x 2″)",
        "category": 7,
        "subCategory": 0,
        "price": 500,
        "shopee": "https://shopee.com.my/product/119505399/2770240039/",
        "desc": [],
        "imageCount": 1,
        "vehicles": []
    },
    {
        "id": 604,
        "sku": "",
        "name": "Honda CRZ Turbo Kit",
        "category": 13,
        "subCategory": 0,
        "price": 16000,
        "shopee": "",
        "desc": [
            "Speedworks CRZ Turbo Kit. + 30.5 BHP (+ 24%) , 88 NM (+60%)",
            "Transforms the Sporty CRZ power to what it deserves.",
            "Full Turbo Kit fitted from Std Car drive-in to drive-out with a Turbocharged CRZ.",
            "The Turbo Kit consists of Garrett water-cooled Turbocharger, Alloy Intercooler, K&N Open Pod Kit, BOV, Greddy E-manage Ultimate ECU, 450cc Fuel Injector, Silicone hoses, Stainless Steel Pipes & Heat-shields, Turbo Exhaust System.",
            "Fitted and tuned for only RM16K."
        ],
        "imageCount": 5,
        "vehicles": [
            {
                "make": "Honda",
                "model": "CRZ",
                "engine": "1.5",
                "startYear": 2010,
                "endYear": 2016
            }
        ]
    }
];