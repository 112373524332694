import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../../theme";

export const CtgSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "8%",
  [theme.breakpoints.down("md")]: {
    padding: "80px 20%",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "60px 15%",
  },
}));

export const CtgContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  flex: "1",
  ":hover": {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    background: "rgba(0, 0, 0, 0.05)",
    boxShadow: "none",
  },
  borderRadius: "8px",
  boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.25)",
  background: "linear-gradient(190deg, #f8f8f8, #f0f0f0)",
}));

export const CtgImageContainer = styled(Box)(({ theme }) => ({
  padding: "10px 30px 0px 30px",
  // height: "140px",
  // [theme.breakpoints.down("lg")]: {
  //   height: "100px",
  // },
}));

export const CtgTextContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "0px 20px 20px 20px",
}));

export const CtgTextTitleContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const CtgIconContainer = styled(Box)(({ theme }) => ({
  paddingRight: "16px",
  width: "50px",
  [theme.breakpoints.down("md")]: {
    paddingRight: "8px",
    width: "40px",
  },
}));

export const CtgTitle = styled(Typography)(({ theme }) => ({
  color: Colors.black,
  width: "100%",
  flex: 1,
  padding: "20px 0px",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.3rem",
    lineHeight: 1.15,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
    padding: "16px 0px",
  },
}));

export const CtgDescription = styled(Typography)(({ theme }) => ({
  color: Colors.grey,
  width: "100%",
  padding: "10px 0px",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    lineHeight: 1.15,
  },
}));
