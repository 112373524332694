import React from 'react';
import {
  ContactContainer,
  ContactIconButton,
  ContactIconContainer,
  ContactSectionContainer,
  ContactText,
  CopyrightContainer,
  CopyrightText,
  FooterContainer,
  OthersContainer,
  SitemapContainer,
  SitemapPageButton,
  SitemapPageButtonText,
  SocialIconContainer,
  SocialsContainer,
} from "../../styles/Footer";
import { Link } from "react-router-dom";
import { sitemapLinks, socialMedias, contactMalaysia } from "../../data/site";
import Image from "mui-image";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import PlaceIcon from "@mui/icons-material/Place";
import { Icon } from "@mui/material";

export default function Footer() {
  const renderSitemapPageLinks = sitemapLinks.map((link) => (
    <SitemapPageButton key={link.name.toLowerCase()}>
      <Link
        to={link.route}
        key={link.name.toLowerCase()}
        style={{ textDecoration: "none" }}
      >
        <SitemapPageButtonText variant="body2">
          {link.name}
        </SitemapPageButtonText>
      </Link>
    </SitemapPageButton>
  ));

  const renderSocialMediaLinks = socialMedias.map((item) => (
    <SocialIconContainer key={item.id}>
      <a
        href={item.url}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noreferrer"
      >
        <Image src={item.image} fit="contain" duration={0} />
      </a>
    </SocialIconContainer>
  ));

  const renderContactLinks = contactMalaysia.details.map((detail) => (
    <ContactContainer key={detail.type}>
      <ContactIconContainer>
        {detail.links.map((link) => (
          <ContactIconButton key={link.icon}
            onClick={() => {
              window.open(link.url, "_blank");
            }}
          >
            {ContactIcon(link.icon)}
          </ContactIconButton>
        ))}
      </ContactIconContainer>
      <ContactText variant="body2">{detail.value}</ContactText>
    </ContactContainer>
  ));

  function ContactIcon(icon) {
    switch (icon) {
      case "Phone":
        return <PhoneIcon />;
      case "Whatsapp":
        return (
          <Icon>
            <Image
              src="/images/social_media/whatsapp_icon_white.png"
              fit="contain"
              duration={0}
            />
          </Icon>
        );
      case "Email":
        return <MailIcon />;
      case "Waze":
        return (
          <Icon>
            <Image
              src="/images/social_media/waze_icon.png"
              fit="contain"
              duration={0}
            />
          </Icon>
        );
      default:
        return <PlaceIcon />;
    }
  }

  return (
    <div>
      <FooterContainer>
        <SitemapContainer>{renderSitemapPageLinks}</SitemapContainer>
        <OthersContainer>
          <SocialsContainer>{renderSocialMediaLinks}</SocialsContainer>
          <ContactSectionContainer>
            {renderContactLinks}
          </ContactSectionContainer>
        </OthersContainer>
      </FooterContainer>
      <CopyrightContainer>
        <CopyrightText variant="subtitle2">
          © Copyright {new Date().getFullYear()} Speedworks Sdn. Bhd. All Rights
          Reserved.
        </CopyrightText>
      </CopyrightContainer>
    </div>
  );
}
