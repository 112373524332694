import React from "react";
import { PageContainer } from "../../styles/PageContainer";
import { ButtonContainer, DescText, RouteButton, TitleText } from "../../styles/ErrorPage";
import { Link, useNavigate } from "react-router-dom";

function Error() {
  const navigate = useNavigate();

  return (
    <PageContainer disableGutters maxWidth={false}>
      <TitleText variant="h1">Error 404</TitleText>
      <DescText>Page not found.</DescText>
      <DescText>Probably missing a bolt or a nut somewhere...</DescText>
      <ButtonContainer>
        <RouteButton onClick={() =>  navigate(-1)}>
            Back
        </RouteButton>
        <Link to="/" style={{ textDecoration: "none" }}>
        <RouteButton>
            Home
        </RouteButton>
        </Link>
      </ButtonContainer>
    </PageContainer>
  );
}

export default Error;
