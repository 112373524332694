import { createTheme } from "@mui/material/styles";

export const Colors = {
  primary: "#161616",
  secondary: "#eb1a1f",
  tertiary: "#f4898c",
  success: "#4CAF50",
  info: "#00a2ff",
  danger: "#FF5722",
  warning: "#FFC107",
  error: "#d32f2f",
  dark: "#0e1b20",
  light: "#aaa",
  muted: "#abafb3",
  border: "#DDDFE1",
  inverse: "#2F3D4A",
  shaft: "#333",
  ///////////////
  // Grays
  ///////////////
  dim_grey: "#696969",
  dove_gray: "#d5d5d5",
  body_bg: "#f3f6f9",
  light_gray: "rgb(230,230,230)",
  ///////////////
  // Solid Color
  ///////////////

  //MY COLORS
  white: "#fff",
  black: "#000",
  test: "#ffa630",
  black_grey: "#161616",
  dark_grey: "#434343",
  grey: "#5c5c5c",
  light_grey: "#E9E9E9",
  white_alpha_10: "rgba(255, 255, 255, 0.1)",
  black_alpha_2: "rgba(0, 0, 0, 0.02)",
  black_alpha_7: "rgba(0, 0, 0, 0.07)",
  black_alpha_10: "rgba(0, 0, 0, 0.1)",
  black_alpha_20: "rgba(0, 0, 0, 0.2)",
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "'Open Sans', 'Arial', 'Helvetica', 'sans-serif'",
    h1: {
      fontFamily: "'EB Garamond', 'Georgia', 'serif'",
      fontSize: "4rem",
      // lineHeight: 1.15,
    },
    h2: {
      fontFamily: "'EB Garamond', 'Georgia', 'serif'",
      fontSize: "3rem",
      // lineHeight: 1.15,
    },
    h3: {
      fontFamily: "'EB Garamond', 'Georgia', 'serif'",
      fontSize: "2.75rem",
      // fontWeight: 400
    },
    h4: {
      fontSize: "2rem",
    },
    h5: {
      fontSize: "1.5rem",
    },
    h6: {
      fontSize: "1.3rem",
    },
    subtitle1: {
      //1
    },
    subtitle2: {
      //0.875
      fontSize: "0.9rem",
    },
    body1: {
      fontSize: "1.4rem",
    },
    body2: {
      fontSize: "1.1rem",
    },
    button: {
      fontSize: "1rem",
    },
  },

  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 250,
        },
      },
    },
  },
});

export default theme;
