import React from "react";
import {
  BannerSection,
  BannerContent,
  BannerTitle,
  BannerDescription,
  BannerProductsButton,
  BannerImageContainer,
} from "../../../styles/HomePage/Banner";

import { Link } from "react-router-dom";

export default function Banner() {
  return (
    <BannerSection>
      <BannerImageContainer>
        <img
          alt="Landing Page Banner"
          src={`/images/banner-lg.jpg`}
          srcSet={`
                    images/banner-md.jpg 1000w,
                    images/banner-lg.jpg 2000w,
                  `}
          sizes="(max-width: 700px) 80vw, 36vw"
          height="100%"
          width="100%"
        />
      </BannerImageContainer>
      <BannerContent>
        <BannerTitle variant="h1">The Performance Specialist</BannerTitle>

        <BannerDescription variant="h6">
          A range of dyno-tested, bolt-on performance parts to increase overall
          efficiency, responsiveness and power.
        </BannerDescription>
        <Link to="/products" style={{ textDecoration: "none" }}>
          <BannerProductsButton>Explore All Products</BannerProductsButton>
        </Link>
      </BannerContent>
    </BannerSection>
  );
}
