import { Box, Typography, Button, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../theme";

export const FooterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  background: Colors.black_grey,
  padding: "80px 240px",
  [theme.breakpoints.down("lg")]: {
    padding: "70px 120px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "70px 120px",
  },  [theme.breakpoints.down("sm")]: {
    padding: "60px 40px",
  },
}));

export const SitemapContainer = styled(Box)(({ theme }) => ({
  flex: 2,
  alignItems: "center",
}));

export const SitemapPageButton = styled(Button)(({ page, theme }) => ({
  key: { page },
  display: "flex",
  justifyContent: "flex-start",
  margin: "4px 0px",
  ":hover": {
    background: Colors.white_alpha_10,
  },
}));

export const SitemapPageButtonText = styled(Typography)(({ theme }) => ({
  color: Colors.white,
  textTransform: "none",
  textAlign: "start",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
}));

export const OthersContainer = styled(Box)(({ theme }) => ({
  flex: 3,
  alignItems: "center",
}));

export const SocialsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const SocialIconContainer = styled(Box)(({ theme }) => ({
  height: "32px",
  width: "32px",
  padding: "8px",
  borderRadius: "12px",
  ":hover": {
    background: Colors.white_alpha_10,
  },
}));

export const ContactSectionContainer = styled(Box)(({ theme }) => ({
  flexDirection: "column",
  margin: "50px 0px 0px 50px",
  [theme.breakpoints.down("lg")]: {
    margin: "50px 0px 0px 30px",
  },
  [theme.breakpoints.down("md")]: {
    margin: "40px 10px 0px 10px",
  },
}));

export const ContactContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: "12px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    margin: "16px",
  },
}));

export const ContactIconContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  justifyContent: "flex-end",
  display: "flex",
  paddingRight: "12px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center"
  },
}));

export const ContactIconButton = styled(IconButton)(({ theme }) => ({
  color: Colors.white,
  height: "40px",
  width: "40px",
  ":hover": {
    background: Colors.white_alpha_10,
  },
  margin: "0px 4px 0px 4px",
}));

export const ContactText = styled(Typography)(({ theme }) => ({
  color: Colors.white,
  flex: 3,
  whiteSpace: "pre-wrap",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    textAlign: "center"
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
}));

export const CopyrightContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  background: Colors.black_grey,
  justifyContent: "center",
  padding: "10px 60px",
  textAlign: "center",
}));

export const CopyrightText = styled(Typography)(({ theme }) => ({
  color: Colors.light_grey,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem",
  },
}));
