import {
  List,
  ListItem,
  Box,
  ListItemButton,
  Container,
  Drawer,
} from "@mui/material";
import { PageLink, PageLinkDivider, PageText } from "../../styles/AppDrawer";
import * as React from "react";
import { useUIContext } from "../../context/ui";
import { Colors } from "../../styles/theme";

const navItems = ["Home", "About", "Products", "Contact"];

export default function AppDrawer() {
  const { drawerOpen, setDrawerOpen } = useUIContext();
  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const drawerItems = (
    <Box onClick={handleDrawerToggle} >
      <List>
        {navItems.map((item) => (
          <Container key={item.toLowerCase()}>
            <ListItem disablePadding>
              <PageLink
                to={item === "Home" ? "/" : item.toLowerCase()}
                style={{ textDecoration: "none" }}
              >
                <ListItemButton sx={{ textAlign: "center" }}>
                  <PageText variant="body1">{item}</PageText>
                </ListItemButton>
              </PageLink>
            </ListItem>
            <PageLinkDivider />
          </Container>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      open={drawerOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{ sx: {backgroundColor: Colors.light_grey} }}
    >
      {drawerItems}
    </Drawer>
  );
}
