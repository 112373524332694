import React from "react";
import Banner from "./Banner";
import Category from "./Category";
import Features from "./Features";
import Subscribe from "./Subscribe";
import { PageContainer } from "../../styles/HomePage/PageContainer";

function Home() {

  return (
    <PageContainer disableGutters maxWidth={false}>
      <Banner />
      <Category />
      <Features />
      <Subscribe />
    </PageContainer>
  );
}

export default Home;
