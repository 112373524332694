import { Typography, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../theme";
import { Link } from "react-router-dom";

export const PageText = styled(Typography)(({ theme }) => ({
  width: "100%",
  fontSize: "1.3rem",
  padding: "8px 0px",
  textAlign: "start",
  color: Colors.primary,
}));

export const PageLink = styled(Link)(() => ({
  width: "100%",
  ".MuiTouchRipple-root": {
    color: "rgba(0, 0, 0, 0.3)",
  },
}));

export const PageLinkDivider = styled(Divider)(() => ({
  width: "80%",
  margin: "0px 0px",
  borderBottomWidth: "1.5px",
}));
