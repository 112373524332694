import { Box, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../../styles/theme";

export const TitleText = styled(Typography)(({ theme }) => ({
  paddingBottom: "20px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    fontSize: "2.75rem",
  },
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "10px",
    fontSize: "2.5rem",
    textAlign: "center",
  },
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "50px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const ContentText = styled(Typography)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.3rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
    padding: "10px 0px",
  },
}));

export const SubcontentText = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "start",
  color: Colors.black,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.0rem",
  },
}));

export const ContentImageContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "10px 0px 0px 0px",
  },
}));

export const SectionDivider = styled(Divider)(({ theme }) => ({
  margin: "80px 20%",
  borderBottomWidth: "3px",
  [theme.breakpoints.down("md")]: {
    margin: "60px 0px",
  },
}));

//PRODUCT SECTION

export const ProductItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  margin: "24px 24px",
  flex: "1",
  ":hover": {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    background: "rgba(0, 0, 0, 0.05)",
    boxShadow: "none",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  borderRadius: "8px",
  boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.25)",
  background: "linear-gradient(190deg, #f8f8f8, #f0f0f0)",
}));

export const ProductImageContainer = styled(Box)(({ theme }) => ({
  padding: "20px",
  flex: 1,
  height: "160px",
  [theme.breakpoints.down("lg")]: {
    height: "140px",
  },
  [theme.breakpoints.down("md")]: {
    width: "250px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 20px 10px 20px",
    width: "200px",
  },
}));

export const ProductTextContainer = styled(Box)(({ theme }) => ({
  flex: 5,
  padding: "20px 20px 20px 0px",
  [theme.breakpoints.down("sm")]: {
    padding: "0px 20px 20px 20px",
  },
}));

export const ProductTitleText = styled(Typography)(({ theme }) => ({
  width: "100%",
  paddingBottom: "6px",
  color: Colors.black,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.3rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
    padding: "10px 0px",
    textAlign: "center",
  },
}));

//PERFORMANCE SECTION
export const PerformanceImageSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const PerformanceImageContainer = styled(Box)(({ theme }) => ({
  padding: "0px 80px",
  [theme.breakpoints.down("lg")]: {
    padding: "0px 40px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0px 0px",
  },
}));

export const PerformanceItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "16px",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "start",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 0px",
  },
}));

export const PerformanceTitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: "1",
}));

export const PerformanceDescContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: "3",
}));

export const PerformanceIconContainer = styled(Box)(({ theme }) => ({
  padding: "0px 16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
